import React from 'react';
import './DigitalArt.css';
import { Row, Col } from 'react-bootstrap';

const DigitalArt = (props) => {
    
    const data = [
        { id: 1, title: 'Showcase Your Talent', text: "MarvmentNFT is for those who have a passion for music. Artists can create digital NFTs for their music in multifarious forms including audio and videoclips, images, album covers, and gifs." },
        { id: 2, title: 'Buying And Selling', text: "Buy or Sell your NFTS securely on our platform. MarvmentNFT gives music lovers a chance to buy, sell and trade from a wide range of digital tokens crafted by their favorite artists." },
        { id: 3, title: 'Discover Unique Musical NFTs', text: "MarvmentNFT provides unique one-of-a-kind music NFTs curated by renown worldwide artists of all genres. Fans get the chance to discover and collect tokenized musical artwork by musicians they idolize." }
    ];

    return(

        <section className={`py-5 create-nft-section ${props.textColor} w-100 app-flex-column padding-section`} 
            id="digital-art"
        >
            
            <h6 className={`font-36px mt-4 d-block text-center font-weight-bold font-clashRegular ${props.textColor}`}>
                What MarvmentNFT Marketplace Offers To You
            </h6>
            
            <Row className="p-0 mb-5 mt-2">
                {
                    data?.map((item, i) => (
                        <Col xs={12} sm={12} md={12} lg={4} xl={4} key={i} className={`px-5 position-relative ${i<2 ? 'digital-art-lines' : ''} ${props.textColor}`}>
                            <div className="w-100 app-flex-column position-relative mt-3" style={{ zIndex: 10 }}>
                                
                                { i === 0 ? 
                                <div className="art-layer-1">
                                    <div className="art-layer-2 font-clashRegular">
                                        <div className="art-layer-3 font-13px">0{ item.id }</div>
                                    </div>
                                </div> : 
                                <div  className="art-layer-1 border-none font-13px bg-lightPurple text-white"> 0{ item.id } </div> }
                                
                                <div className={i === 0 ? 'art-text' : ''} 
                                    style={{
                                        borderImage: `linear-gradient(to bottom, ${ props.theme === 'dark' ? 'rgba(255, 255, 255, .7)' : 'rgba(0, 0, 0, .15)' } 40%, var(--lightPurple)) 1 100%`
                                    }}
                                >

                                    <span className="d-block mb-3 font-23px font-clashRegular"> { item?.title } </span>
                                    <span className="d-block font-16px text-justify font-clashRegular"> { item?.text } </span>
                                </div>

                            </div>
                        </Col>
                    ))
                }
            </Row>

        </section>

    );

}
 
export default DigitalArt;