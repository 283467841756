import React from 'react';
import FlexRow from 'components/FlexRow/FlexRow';

export const ArtworkTabs = (props) => {

    const { arr, setActiveTab, activeTab, isDark, className } = props;

    return(

        <FlexRow className="w-100 mb-3 align-items-center justify-content-start" 
            style={{ borderBottom: `1px solid ${isDark ? 'white' : 'black'}`, marginTop: '-10px' }}>
            {
                arr.map((text, i) => (
                    <span key={i} className={`font-16px cursor-pointer-sort ${className} text-center pb-2`} 
                        style={{ borderBottom: activeTab === i ? '4px solid var(--lightPurple)' : 'none', 
                        width: `${100/arr.length}%`, transform: 'translateY(2px)' }}
                        onClick={() => setActiveTab(i)}
                    >
                        {text}
                    </span>
                ))
            }
        </FlexRow>

    );
}