import React from 'react';
import './input.css';

export const Input = (props) => {

    const { type, id, value, onChange, place, onKeyPress, inputref, className, step, min } = props;

    return <input type={type} id={id} value={value} 
            onChange={(e) => onChange(e)}
            placeholder={place} autoComplete="off"
            onKeyPress={(e) => e.key === 'Enter' && onKeyPress(e)}
            className={className}
            ref={inputref}
            step={step ? step : null} min={min ? min : null}
            onKeyDown={(e) => console.log()}
            onWheel={(e) => type === 'number' && e.target.blur()}
            {...props}
        />;

}