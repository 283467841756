import React from 'react';
import { TextInline } from './Inline';

export const TextListItem = ({ p }) => {

    return(

        <div>
            <p className={`d-block text-justify mb-3 ${(p?.showNumber || p?.head) ? 'font-weight-bold font-16px' : `font-15px ${p?.smallBold ? 'font-weight-bold' : ''}`}`}> 
                { p?.showNumber && <span className='mr-1'>{p?.showNumber}.</span> } 
                {p?.title} 
                { p?.underline && 
                    <span className="font-15px font-weight-normal ml-1 text-underline cursor-pointer-sort">{p?.underline}</span> }
            </p>

            { p?.subList?.length ? p?.subList?.map((p1, index) => (
                <p className='font-16px text-justify mb-3 ml-5' key={index} style={{ display: 'list-item' }}> 
                    { p1?.inline ? <TextInline key={index} item={p1} /> : <>    

                    <span className="font-weight-normal">{p1?.topTitle}</span>
                    <span className="font-weight-bold">{p1?.title}</span> 
                    <span className="font-15px font-weight-normal">{p1?.text}</span>
                    { p1?.underline && 
                    <span className="font-15px font-weight-normal text-underline ml-1 cursor-pointer-sort">{p1?.underline}</span> }

                    { p1?.subList?.length ? p1?.subList?.map((p2, index) => (
                        <p className='font-16px text-justify mb-2 ml-4' key={index} style={{ display: 'list-item' }}>
                            { p2 }
                        </p>
                    )) : null }

                    </> }
                </p>
            )) : null }

        </div>

    );

}