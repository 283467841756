import React, { useState, useEffect, useRef } from 'react';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import { Row, Col } from 'react-bootstrap';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import { Div } from 'components/Div/Div';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FaTimes } from 'react-icons/all';
import { Avatar } from 'components/Avatar/Avatar';
import { updateUser } from 'services';
import { SpinnerLoader } from 'components/Spinner/Spinner';
import { isUpdateUserProfile } from 'redux/actions/action';
import AlertPopup from 'components/AlertPopup/AlertPopup';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router';
import { Routes } from 'Routes/Routes';
import CustomModal from 'components/CustomModal/CustomModal';
import { Heading } from 'components/Heading/Heading';

const EditProfile = (props) => {
    
    const { user, close } = props;
    const fieldRef = useRef(null);

    const dispatch = useDispatch();
    const history = useHistory();

    const [ username, setUserName ] = useState('');
    const [ bio, setBio ] = useState('');
    const [image, setImage] = useState({ id: 0, src: null, name: '', file: null });
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState({ show: false, type: '', message: '', title: '' });

    useEffect(() => {
        
        if(fieldRef.current){
            fieldRef.current.focus();
        }

        setUserName(user?.userName);
        setBio(user?.bio ? user?.bio : '');
        setImage({ id: 0, src: user?.profilePic, name: '', file: user?.profilePic });

        const alertElement = document.getElementById('error-message');

        if(alertElement){
            alertElement.style.visibility = 'hidden';
            alertElement.style.zIndex = -1;
        }

        return () => {}

    }, [user]);

    const dragOver = (e) => {
		e.preventDefault();
	};

	const dragEnter = (e) => {
		e.preventDefault();
	};

	const dragLeave = (e) => {
		e.preventDefault();

		const files = e.dataTransfer.files[0];

		setFile(files);
	};

	const fileDrop = (e) => {
		e.preventDefault();

		const files = e.dataTransfer.files[0];

		setFile(files);
	};

    const chooseImage = (e) => {
        let file = e.target.files[0];
        if (file === undefined) {
            return null;
        } else {
            setFile(file);
        }
    };

    const setFile = (file) => {
        let name = file.name;
        let reader = new FileReader();
        reader.onloadend = () => setImage({ id: Math.random(), src: reader.result, name: name, file: file });
        reader.readAsDataURL(file, file.name);
    }

    const editProfile = () => {
        const values = { bio: bio ? bio : ' ', profilePic: image.file, userName: username };

        if(values?.profilePic){
            setLoading(true);

            updateUser(user?._id, values).then((res) => {
                setLoading(false);
                dispatch(isUpdateUserProfile({ data: res?.data }));
                history.replace(`/${Routes.profile}`);
                window.location.reload();
            }).catch((err) => {
                setLoading(false);
                setErr({ show: true, type: 'error', title: 'Profile Not Updated', message: `` });
            });            
        } else {
            setErr({ show: true, type: 'error', title: 'Image should not be empty', message: `` });
        }
    };

    if(user){

        return (

            <CustomModal 
                show={true} size={2}
                handleClose={() => history.push(`/${Routes.profile}`)}
            >

                <FaTimes className="text-black cursor-pointer-sort font-20px position-absolute mt-3 mr-3 font-normal"
                    style={{ right: 0, top: 0, zIndex: 5 }} 
                    onClick={() => history.push(`/${Routes.profile}`)} 
                />
            
                <Heading className="d-block text-center font-30px pt-5">
                    Edit your profile
                </Heading>

                <FlexColumn className="w-90 mx-auto py-5 px-1" 
                    dragOver={dragOver}
                    dragEnter={dragEnter}
                    dragLeave={dragLeave}
                    fileDrop={fileDrop}
                >

                    <FormControl fullWidth>
                        <Input
                            id="standard-adornment-amount"
                            className="pb-4 font-14px profile-popup-fields"
                            placeholder="Name" type="file"
                            onChange={(e) => chooseImage(e)}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Avatar src={image.src} medium className="rounded-circle" showDummy />
                                </InputAdornment>
                            }
                            endAdornment={null}
                        />
                    </FormControl>

                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <FormControl fullWidth>
                                <Input
                                    id="standard-adornment-amount" inputRef={fieldRef}
                                    value={username} className="pb-2 font-14px profile-popup-fields"
                                    placeholder="Username*" readOnly={false}
                                    onChange={(e) => setUserName(e.target.value)}
                                />
                            </FormControl>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <FormControl fullWidth>
                                <Input
                                    id="standard-adornment-amount"
                                    value={bio} className="pb-2 font-14px profile-popup-fields"
                                    placeholder="Status*"
                                    onChange={(e) => setBio(e.target.value)}
                                />
                            </FormControl>
                        </Col>
                    </Row>

                    <Div className="text-center d-block">
                        <button onClick={editProfile}
                            className="text-white gradient border-0 outline-none font-16px rounded px-5 py-2" 
                        >
                            { loading ? <SpinnerLoader size="sm" /> : 'Save' }
                        </button>
                    </Div>

                    <AlertPopup 
                        data={{ show: err?.show, title: err.title, message: err?.message, type: err?.type }}
                        handleClose={() => {
                            setErr({ ...err, show: false });
                        }}
                    />

                </FlexColumn>
        
            </CustomModal>

        );
    }

    else return <Redirect to={`/${Routes.profile}`} exact />
}
 
export default EditProfile;