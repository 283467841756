// import { Images } from 'Assets/Images';
import React from 'react';
import './FooterArt.css';

const FooterArt = (props) => {
    
    return null;
    // return(

    //     <div className="footer-art app-flex-column align-items-center justify-content-center text-white" 
    //         style={{ background: `url(${Images.digital})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
    //     >

    //         <span className="m-0 p-0 font-clashRegular">
    //             Are you Willing to Tokenize Your Music?
    //         </span>

    //         <p className="text-center pt-2 pb-4 font-clashRegular">
    //             Learn how Marvment NFT marketplace can help you protect and sell your unique musical talent and join our rapidly growing community of thousands of artists around the globe.
    //         </p>

    //         <button className="bg-button border-white text-white bg-transparent font-clashRegular rounded mouse-hover" 
    //         onClick={() => props.getStarted()}>
    //             Get Started
    //         </button>

    //     </div>

    // )

}
 
export default FooterArt;