import React from 'react';
import { Routes } from 'Routes/Routes';

export const TextInline = ({ item }) => {

    const anchor = <p className="font-14px font-weight-normal text-underline d-inline" dangerouslySetInnerHTML={{ __html: item?.anchor }}></p>

    return(
        <div className="mb-3 text-justify">
            
            { item?.title && <p className="mr-1 font-weight-bold font-15px d-inline">{item?.title}</p> }

            <p className="font-14px font-weight-normal d-inline"> {item?.anchor ? item?.text?.split(item?.split)[item?.index] : item?.text } </p>
            
            { item?.anchor && anchor }
            
            { item?.anchor && item?.text?.split(item?.split)?.map((paras, i) => (
                i !== item?.index && <p className="font-14px font-weight-normal d-inline" key={i}> { paras } </p>
            )) }
            
        </div>
    );
}