import React, { useEffect } from 'react';


import DocumentTitle from 'components/DocumentTitle/DocumentTitle';
import RenderPage from 'components/RenderPage/RenderPage';
import { goToSection } from 'utilities/CommonMethods';
import { Row, Col } from 'react-bootstrap';
import { Images } from 'Assets/Images';

const About = (props) => {
    
    const { currentTheme: { theme } } = props;

    useEffect(() => {        
        startTop();
    }, []);

    const startTop = () => {
        setTimeout(() => goToSection('privacypolicypage'), 0);
    }

    const textColor = theme === 'dark' ? 'text-white' : 'text-black';

    const textInline = [
        { id: 1, title: 'Connect', text: `Sign up and connect your Metamask Wallet to the Marvment NFT marketplace.` },
        { id: 2, title: 'Create', text: `Create and design your Portfolio that includes NFTs of your songs, albums, images, GIFs and more to make up your collection.` },
        { id: 3, title: 'Mint', text: `Set up a minting price for your NFTs and start selling.` },
        { id: 4, title: 'Earn', text: `Once an NFT is sold, the proceeds shall be automatically transferred to your Metamask Wallet.` }
    ];

    return (

        <DocumentTitle title="About">

            <RenderPage title="About Us" className={theme === 'dark' ? 'bg-darkBlack text-white' : 'bg-white text-black'} src={null} id="privacypolicypage">
                
                <div className={`padding-section py-4 ${textColor}`}>

                    <Row className="w-90 mx-auto">
                        <Col xs={12} sm={12} md={6} lg={7} xl={7}>
                            <p className="d-block text-justify mb-3 font-23px font-weight-bold"> 
                                MarvmentNFT was created to empower artists to exhibit and sell their musical works as non-fungible token or NFTs. 
                            </p>

                            <p className={`d-block text-justify mb-3 font-16px`}> 
                                MarvmentNFT is the offspring of our music distribution platform - Marvment (the platform for independent musicians to distribute, sell and manage their content worldwide). With the advent of NFTS and the opportunities it presents as an additional source of revenue for musicians it was only inevitable that we create a platform/marketplace with the objective of empowering artists and connecting them to their fanbase through the power of Blockchain.
                            </p>

                        </Col>
                        <Col xs={12} sm={12} md={6} lg={{ span: 4, offset: 1 }} xl={{ span: 4, offset: 1 }}>
                            <img alt="" width="100%" className="mx-auto" src={Images.about[0]} />
                        </Col>
                        <p className="w-auto my-5 font-23px font-weight-bold text-center mx-auto"> 
                            Our process is simple;
                        </p>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Row>
                               { Images.about.slice(1).map((img, i) => (
                                   <Col key={i} xs={12} sm={12} md={6} lg={3} xl={3}>
                                       <img alt="" width="100%" src={img} className="mb-4 mouse-hover cursor-pointer-sort" />
                                   </Col>
                               )) } 
                            </Row>
                        </Col>
                    </Row>

                </div>
                
            </RenderPage>

        </DocumentTitle>

    );

}
 
export default About;