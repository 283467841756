import React, { useState } from 'react';
import { Images } from 'Assets/Images';

const ArtworkType = ({ type, srcUrl, height, width, name, className, withAspectRatio, curveBorders, isDark, autoPlay, controls, musicOriginalNFT, thumbNail_url }) => {
    
    const [audioMuted, setAudioMuted] = useState(true);

    const shadow = isDark ? 'inset 0px 0px 10px 1px rgba(255, 255, 255, .3)' : 'inset 0px 0px 10px 1px rgba(0, 0, 0, .3)';

    const renderImage = withAspectRatio ? <img alt={name} src={srcUrl} width="100%" loading="lazy"
                                            className={`${className} overflow-hidden`} 
                                            style={{ objectFit: 'fill', aspectRatio: 1 / 1, borderRadius: curveBorders ? '12px 12px 0px 0px' : '6px' }}
                                        /> :
                                        <img alt={name} src={srcUrl} width={ width ? width : "100%"} loading="lazy"
                                            height={height} className={`${className} overflow-hidden artwork-height`} 
                                            style={{ objectFit: 'contain', boxShadow: shadow, borderRadius: curveBorders ? '12px 12px 0px 0px' : '6px' }}
                                        />;                                        

    if(type?.toLowerCase() === "image" || type?.toLowerCase() === "gif"){
        return renderImage;
    }

    if(type?.toLowerCase() === "video"){
        const renderVideo = withAspectRatio ?
                            <div style={{ objectFit: 'contain', aspectRatio: 8 / 6, boxShadow: shadow,
                            borderRadius: curveBorders ? '12px 12px 0px 0px' : '6px' }} 
                            className='w-100 overflow-hidden'>
                                <video alt={name} autoPlay={autoPlay} controls={controls} muted loop className={`${className}`} 
                                    style={{ width: width ? width : "100%" }} controlsList="nodownload" preload="metadata" 
                                    onMouseOver={(e) => e.target.play()} playsInline webkit-playsinline="true" onMouseOut={(e) => e.target.pause()}
                                >
                                    <source src={`${srcUrl}#t=0.1`} type="video/mp4" />
                                    <source src={`${srcUrl}#t=0.1`} type="video/ogg" />
                                </video>
                            </div> :
                            <div className="overflow-hidden" style={{ objectFit: 'contain', boxShadow: shadow, borderRadius: curveBorders ? '12px 12px 0px 0px' : '6px' }}>
                                <video alt={name} autoPlay={autoPlay} controls={controls} muted loop className={`${className} artwork-height`} 
                                    style={{ height: height, width: width ? width : "100%" }} controlsList="nodownload" preload="metadata" 
                                    onMouseOver={(e) => e.target.play()} playsInline webkit-playsinline="true" onMouseOut={(e) => e.target.pause()}
                                >
                                    <source src={`${srcUrl}#t=0.1`} type="video/mp4" />
                                    <source src={`${srcUrl}#t=0.1`} type="video/ogg" />
                                </video>
                            </div>;

        return renderVideo;
    }

    if((type?.toLowerCase() === "music" || type?.toLowerCase() === "audio") && musicOriginalNFT){
        return (
            <div className={`${className} justify-content-end app-flex-column artwork-height`} 
                style={{ height: height, backgroundImage: `url(${thumbNail_url ? thumbNail_url : Images.musicBg})`, boxShadow: shadow,
                backgroundSize: 'contain', backgroundRepeat: 'no-repeat', width: width ? width : "100%", aspectRatio: 1 / 1,
                borderRadius: curveBorders ? '12px 12px 0px 0px' : '6px', backgroundPosition: 'center' }}
            >
                <audio 
                    loop autoPlay={false} controls muted={audioMuted} src={srcUrl} className="w-100" controlsList="nodownload" poster={thumbNail_url}
                    onPlayingCapture={() => setAudioMuted(false)} onPauseCapture={() => setAudioMuted(true)}
                >
                    <source type="audio/*" />
                </audio>
            </div>
        );
    }

    if((type?.toLowerCase() === "music" || type?.toLowerCase() === "audio") && !musicOriginalNFT){
        return (
            <div className={`${className} justify-content-end app-flex-column artwork-height p-2`} 
                style={{ boxShadow: shadow, borderRadius: curveBorders ? '12px 12px 0px 0px' : '6px' }}
            >
                <img src={thumbNail_url ? thumbNail_url : Images.musicBg} width="100%" className='rounded' alt="music-nft" />
                <audio loop autoPlay={false} controls muted={audioMuted} src={srcUrl} style={{ left: 0, right: 0 }}
                    className="w-100 position-absolute" controlsList="nodownload" poster={thumbNail_url}
                    onPlayingCapture={() => setAudioMuted(false)} onPauseCapture={() => setAudioMuted(true)}
                >
                    <source type="audio/*" />
                </audio>
            </div>
        );
    }

    return null;

}
 
export default ArtworkType;