import React, { useState, useEffect } from "react";

import Wrapper from "hoc/Layout/Wrapper/Wrapper";

import { Row, Col } from "react-bootstrap";
import CombineListItem from "components/CombineListItem/CombineListItem";
import ListItem from "components/ListItem/ListItem";
import ProductLayout from "./ProductLayout";
import FlexRow from "components/FlexRow/FlexRow";
import BorderButton from "components/BorderButton/BorderButton";
import { LargeModal } from "components/LargeModal/LargeModal";
import BidProduct from "screens/Modals/BidProduct";
import { getLatestArtworks, auctionBids } from "services/artService";
import LoadData from "components/LoadData/LoadData";
import { convertToEther } from "utilities/dateValid";
import CounterTimer from "components/CounterTimer/CounterTimer";
import { ArtworkTabs } from "components/ArtworkTabs/ArtworkTabs";
import { ArtworkPrice } from "components/ArtworkPrice/ArtworkPrice";
import { getHistoryDate } from "utilities/CommonMethods";
import ClaimNft from "screens/Modals/ClaimNft";
import { Routes } from "Routes/Routes";
import { cancelNftAuction } from "services/contract.service";
import AlertPopup from "components/AlertPopup/AlertPopup";
import { SpinnerLoader } from "components/Spinner/Spinner";
import { ProductLinks } from "./ProductLinks";
import ProcessWaiting from "components/ProcessWaiting/ProcessWaiting";
import { useSelector } from "react-redux";
const ArtworkOnAuction = (props) => {
  const { activeWallet } = useSelector((state) => state);
  const {
    state,
    history,
    currentArtwork,
    usd,
    historyArr,
    isDark,
    textColor,
    colors,
    urlId,
    refreshState,
    random,
  } = props;

  const userId = state?.user?.info?._id
    ? state?.user?.info?._id
    : state?.user?.info?.id;

  const ownerId = currentArtwork?.owner?.id ? currentArtwork?.owner?.id : currentArtwork?.owner?._id;
  const createrId = currentArtwork?.creater?.id ? currentArtwork?.creater?.id : currentArtwork?.creater?._id;

  const [openModal, setOpenModal] = useState({ flag: false, title: null });

  const [err, setErr] = useState({
    show: false,
    type: "",
    message: "",
    title: "",
  });

  const [biggerBid, setBiggerBid] = useState(undefined);
  const [auctions, setAuctions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingAuction, setLoadingAuction] = useState(false);
  const [disableBidBtn, setDisableBidBtn] = useState(false);
  const [page, setPage] = useState({ page: 0, limit: 10 });
  const [activeTab, setActiveTab] = useState(0);
  const [allBids, setAllBids] = useState([]);

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    loadAuctions(page);
    loadArtworkData();
  }, [random]);

  useEffect(() => {
    if (disableBidBtn) {
      refreshState();
    }
  }, [disableBidBtn]);

  const loadAuctions = (page) => {
    getLatestArtworks()
      .then((res) => {
        setAuctions(res?.data?.filter((item) => item?.id !== urlId));
        setLoading(false);
      })
      .catch((err) => {
        setAuctions([]);
        setLoading(false);
      });
  };

  const loadArtworkData = () => {
    if (currentArtwork?.auction && currentArtwork?.auction?._id) {
      auctionBids(currentArtwork?.auction?._id, (res) => {
        const amount = Math.max.apply(
          null,
          res?.data?.data?.map((bid) => bid?.bid_amount)
        );

        setOpenModal({ flag: false, title: null });
        setAllBids(res?.data?.data);
        setBiggerBid(
          res?.data?.data?.find(
            (bid) => bid?.bid_amount > amount || bid?.bid_amount === amount
          )
        );
      });
    }
  };

  const openClickModal = (str) => {
    setOpenModal({ flag: true, title: str });
  };

  const cancelAuction = (contractAucId) => {
    if (contractAucId) {
      setLoadingAuction(true);
      Promise.all([
        cancelNftAuction({
          auctionId: contractAucId,
          isMeta: currentArtwork?.isMeta,
          activeWallet,
        }),
      ])
        .then((res) => {
          if (res?.status || res[0]?.status) {
            setLoadingAuction(false);
            setErr({
              show: true,
              type: "success",
              title: "NFT auction cancellation may take a few minutes",
              message: ``,
            });
            setTimeout(() => {
              refreshState();
            }, 1500);
          }
        })
        .catch((err) => {
          setErr({
            show: true,
            type: "error",
            title: "There was an error in removing NFT from auction",
            message: ``,
          });
          setLoadingAuction(false);
        });
    }
  };

  return (
    <Wrapper colors={colors} className="art-detail-wrapper" setUsd={() => console.log()}>
      <ProductLayout
        className={textColor}
        isDark={isDark}
        artType={currentArtwork?.artwork_type}
        src={currentArtwork?.artwork_url}
        connection={state?.connection}
        userId={userId}
        connectWallet={() => console.log()}
        showPurchaseBtn={false}
        usd={usd}
        showPara={true}
        views={currentArtwork?.views}
        name={currentArtwork?.name}
        desc={currentArtwork?.description}
        artworkId={currentArtwork?._id}
        showCancelSaleBtn={false}
        currentArtwork={currentArtwork}
        activeUser={state?.user?.info}
        history={history}
        refreshState={refreshState}
        numberOfLikes={currentArtwork?.numberOfLikes}
        creater={currentArtwork?.creater}
      >
        <div className="app-flex-column">
          <ProductLinks
            geners={currentArtwork?.genre} isGenre={true}            
          />

          <ArtworkTabs
            activeTab={activeTab}
            isDark={isDark}
            arr={["Info", "History"]}
            className={textColor}
            setActiveTab={(i) => setActiveTab(i)}
          />

          {activeTab === 0 ? (
            <>
              <ListItem
                title="Creator"
                className="mb-2 cursor-pointer-sort"
                text={`@${currentArtwork?.creater?.userName}`}
                type="image"
                colorHeading="text-lightPurple"
                adjustment
                showAvatar={true} clickState={true} onClick={(e) => history.push(`/${Routes.profile}/${createrId}`)}
                showAvatarSrc={currentArtwork?.creater?.profilePic}
              />
              <ListItem
                title="Owner"
                className="mb-2 cursor-pointer-sort"
                type="image"
                text={`@${
                  currentArtwork?.owner?.userName
                    ? currentArtwork?.owner?.userName
                    : " ------ "
                }`}
                colorHeading="text-lightPurple"
                adjustment
                showAvatar={true} clickState={true} onClick={(e) => history.push(`/${Routes.profile}/${ownerId}`)}
                showAvatarSrc={currentArtwork?.owner?.profilePic}
              />
            </>
          ) : activeTab === 1 ? (
            <div style={{ maxHeight: "140px" }} className="overflow-auto">
              {historyArr?.length === 0 ? (
                <span>No History received.</span>
              ) : (
                historyArr?.map((his, i) => (
                  <span
                    className={`mb-1 d-block ${
                      isDark ? "text-white" : "text-black"
                    } font-14px`}
                    key={i}
                  >
                    <span className="font-weight-bold">
                      {getHistoryDate(his?.createdAt)}
                    </span>{" "}
                    - @{his?.message}
                  </span>
                ))
              )}
            </div>
          ) : activeTab === 2 ? (
            allBids?.length === 0 ? (
              <span className={isDark ? "text-white" : "text-black"}>
                No Bids added.
              </span>
            ) : (
              allBids?.map((bid, i) => (
                <ListItem
                  key={i}
                  title={`Bidder`}
                  type="image"
                  showAvatar={true}
                  showAvatarSrc={bid?.bidder?.profilePic}
                  className="mb-2"
                  text={`@${bid?.bidder?.userName} has been created the bid`}
                  colorHeading={isDark ? "text-white" : "text-black"}
                  adjustment
                />
              ))
            )
          ) : null}

          <ArtworkPrice
            item={currentArtwork}
            usd={usd}
            isDark={isDark}
            edition={true}
            fontSize="font-23px"
            className={textColor}
            bidderName={biggerBid?.bidder?.userName}
            highBidAmount={biggerBid && convertToEther(biggerBid?.bid_amount)}
            goToBidderProfile={() =>
              history.push(`/${Routes.profile}/${biggerBid?.bidder?._id}`)
            }
            isMeta={currentArtwork?.isMeta}
          />

          <div
            className="app-flex-column gradient text-white py-4 mt-2 mb-4 rounded container-shadow align-items-center justify-content-center"
            style={{ maxWidth: "290px" }}
          >
            <span className="font-15px font-weight-bold mb-2">
              Auction ending in
            </span>
            <CounterTimer
              endTime={currentArtwork?.auction?.endTime}
              smallTimer={false}
              closeBid={() => setDisableBidBtn(true)}
            />
          </div>

          <ProductLinks
            isGenre={false}
            links={{ youtube: currentArtwork?.youtubeLink, spotify: currentArtwork?.spotifyLink, tiktok: currentArtwork?.tiktokLink,
              wifiHash: currentArtwork?.websiteLink, itunes: currentArtwork?.ituneLink, instagram: currentArtwork?.instagramLink
            }}
          />

          {state?.user?.info && (
            <FlexRow className="w-100 align-items-center justify-content-start header-section-btns">
              {userId !== currentArtwork?.owner?._id && (
                <>
                  {" "}
                  {disableBidBtn ||
                  (allBids?.length &&
                    allBids
                      ?.map((b) => b?.bidder?._id)
                      ?.includes(userId)) ? null : (
                    <BorderButton
                      title={disableBidBtn ? "Auction Closed" : "Place a bid"}
                      className="mr-1 w-100 text-white bg-lightPurple border-0 mb-2"
                      onClick={() => openClickModal("bid")}
                      disabled={disableBidBtn}
                    />
                  )}
                </>
              )}

              {userId !== currentArtwork?.owner?._id &&
              disableBidBtn &&
              biggerBid &&
              !currentArtwork?.auction?.nftClaim &&
              biggerBid?.bidder?._id === userId ? (
                <BorderButton
                  title={"Claim NFT"}
                  className="mr-1 w-100 text-white bg-lightPurple border-0 mb-2"
                  onClick={() => openClickModal("claim")}
                  disabled={false}
                />
              ) : null}

              {userId &&
              userId === currentArtwork?.owner?._id &&
              disableBidBtn &&
              currentArtwork?.auction?.bids?.length ? (
                <BorderButton
                  title={ currentArtwork?.isMeta ? "Claim ETH" : "Claim MATIC" }
                  className="mr-1 w-100 text-white bg-lightPurple border-0 mb-2"
                  onClick={() => openClickModal("claim")}
                  disabled={false}
                />
              ) : null}
            </FlexRow>
          )}

          {(userId &&
            userId === currentArtwork?.owner?._id &&
            !disableBidBtn) ||
          (userId &&
            userId === currentArtwork?.owner?._id &&
            disableBidBtn &&
            !currentArtwork?.auction?.bids?.length) ? (
            <FlexRow className="w-100 align-items-center justify-content-start header-section-btns">
              <BorderButton
                title={
                  loadingAuction ? (
                    <SpinnerLoader size="sm" />
                  ) : (
                    "Cancel Auction"
                  )
                }
                className="w-100 text-white bg-lightPurple rounded border-0 mb-2"
                disabled={loadingAuction}
                onClick={() =>
                  cancelAuction(currentArtwork?.auction?.contractAucId)
                }
              />
            </FlexRow>
          ) : null}
        </div>
      </ProductLayout>

      <section
        className={`py-5 w-100 app-flex-column padding-section ${textColor}`}
      >
        <h6 className="font-36px mb-5 d-block text-center font-weight-bold font-clashRegular">
          More Work By{" "}
          <span className="text-lightPurple">Amazing Musicians</span>
        </h6>

        <Row className="m-0 py-0 product-detail-padding">
          <LoadData loading={loading} arr={auctions} msg={`No auctions found.`}>
            {auctions?.slice(0, 3)?.map((item, i) => (
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={3}
                key={i}
                className="mb-4"
              >
                <CombineListItem
                  artwork_url={item?.artwork_url}
                  artwork_type={item?.artwork_type}
                  artworkName={item?.name}
                  openForSale={item?.openForSale}
                  isAuctionOpen={item?.isAuctionOpen}
                  artName={item?.name}
                  artPrice={item?.price}
                  endTime={item?.auction?.endTime}
                  numberOfLikes={item?.numberOfLikes}
                  owner={item?.owner}
                  creater={item?.creater}
                  item={item}
                  usd={usd}
                  history={history}
                  artId={item?.id}
                  isDark={isDark}
                />
              </Col>
            ))}
          </LoadData>
        </Row>
      </section>

      {openModal.flag && (
        <LargeModal
          condition={openModal.flag}
          size={3}
          title={
            openModal.title === "bid"
              ? `Place your bid on ${currentArtwork?.name}`
              : null
          }
          handleClose={() => setOpenModal({ flag: false, title: null })}
        >
          {openModal.title === "bid" && (
            <BidProduct
              src={currentArtwork?.artwork_url}
              contractAucId={currentArtwork?.auction?.contractAucId}
              name={currentArtwork?.name}
              history={history}
              type={currentArtwork?.artwork_type}
              userId={userId}
              price={currentArtwork?.price}
              usd={usd}
              userAddress={state?.user?.info?.address}
              artworkId={currentArtwork?._id}
              initialPrice={
                biggerBid
                  ? biggerBid?.bid_amount
                  : currentArtwork?.auction
                  ? currentArtwork?.auction?.initialPrice
                  : currentArtwork?.basePrice
              }
              bidObj={{
                artwork: currentArtwork?.auction?.artwork,
                owner: currentArtwork?.auction?.owner,
                bidder: userId,
                auction: currentArtwork?.auction?._id,
              }}
              isMeta={currentArtwork?.isMeta}
              refreshState={() => {
                refreshState();
                setOpenModal({ flag: false, title: null });
              }}
              auctionId={currentArtwork?.auction?._id}
              thumbNail_url={currentArtwork?.thumbNail_url}
            />
          )}

          {openModal.title === "claim" && (
            <ClaimNft
              src={currentArtwork?.artwork_url}
              type={currentArtwork?.artwork_type}
              claim={true} activeWallet={activeWallet}
              history={history}
              auctionId={currentArtwork?.auction?.contractAucId}
              multiple={currentArtwork?.multipleNFT}
              price={biggerBid ? biggerBid?.bid_amount : currentArtwork?.price}
              userId={userId}
              usd={usd}
              userBalance={state?.user?.userBalance}
              artworkId={currentArtwork?._id}
              isMeta={currentArtwork?.isMeta}
              refreshState={() => {
                refreshState();
                setOpenModal({ flag: false, title: null });
              }}
              thumbNail_url={currentArtwork?.thumbNail_url}
            />
          )}
        </LargeModal>
      )}

      <AlertPopup
        data={{
          show: err?.show,
          title: err.title,
          message: err?.message,
          type: err?.type,
        }}
        handleClose={() => setErr({ ...err, show: false })}
      />

      <ProcessWaiting
        show={loadingAuction}
        message="Please wait while we cancel your NFT on Auction"
      />
    </Wrapper>
  );
};

export default ArtworkOnAuction;
