import React, { useState, useEffect, useCallback } from 'react';
import './CombineListItem.css';

import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import { Routes } from 'Routes/Routes';
import ArtworkType from 'components/ArtworkType/ArtworkType';
import FlexRow from 'components/FlexRow/FlexRow';
import { Images } from 'Assets/Images';
import CounterTimer from 'components/CounterTimer/CounterTimer';
import { convertToEther } from "utilities/dateValid";
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import BorderButton from 'components/BorderButton/BorderButton';
import { LargeModal } from 'components/LargeModal/LargeModal';
import AlertPopup from 'components/AlertPopup/AlertPopup';
import BidProduct from 'screens/Modals/BidProduct';
import PurchaseProduct from 'screens/Modals/PurchaseProduct';
import AlbumListItem from './AlbumListItem';
import { commaSeparatedPrice } from 'utilities/CommonMethods';

const CombineListItem = (props) => {

    const { url } = useRouteMatch();

    const { user } = useSelector(state => state);

    const { history, isDark, item, showEditAlbum, reRender } = props;

    const { artwork_url } = props;

    if(item?.isInAlbum){
        return null;
    }

    if(item?.isAlbum){
        return <AlbumListItem url={url} user={user} showEdit={showEditAlbum} isDark={isDark} owner={item?.creater}
            albumArtworks={item?.albumDetails ? item?.albumDetails?.artworks : []}
            album={{ id: item?.albumDetails?._id || item?.albumDetails?.id, genre: item?.albumDetails?.genre, name: item?.albumDetails?.name, 
            tracks: item?.albumDetails?.tracks }} history={history} srcUrl={artwork_url} reRender={() => showEditAlbum ? reRender() : console.log() }
        />; 
    }    
    
    return <RenderSingleArtwork {...props} />;
}

const RenderSingleArtwork = (props) => {

    const { url } = useRouteMatch();

    const { user } = useSelector(state => state);

    const userId = user?.info?._id ? user?.info?._id : user?.info?.id;

    const { history, owner, artId, isDark, item, showRemove, clickRemove, usd } = props;

    const { artwork_url, artwork_type, artworkName, openForSale, isAuctionOpen, artName, endTime, numberOfLikes, basePrice } = props;

    const [ random, setRandom ] = useState(true);

    const [err, setErr] = useState({ show: false, type: '', message: '', title: '' });

    const [endAuction, setEndAuction] = useState(false);
    const [ openModal, setOpenModal ] = useState({ flag: false, title: null });

    const ownerId = owner?.id ? owner?.id : owner?._id;

    const getOwner = (name, src) => {
        return <FlexRow className="w-auto align-items-center justify-content-start flex-nowrap">
            <img alt="" src={src ? src : Images.uLogo} width="22px" height="22px" className="mr-2 rounded-circle border-0" />
            <span className="text-truncate font-13px text-darkGray50 text-capitalize"> { name } </span>
        </FlexRow>
    }

    const getPrice = () => {
        return basePrice ? (item?.sale ? convertToEther(item?.sale?.price) : convertToEther(item?.basePrice)) : 
        item?.sale ? convertToEther(item?.sale?.price) : item?.auction ? convertToEther(item?.auction?.initialPrice) : convertToEther(item?.price);
    };

    const withDollar = useCallback((price) => {
        if(usd?.matic === 0 || usd?.eth === 0){
            // return <SpinnerLoader size="sm"  className="ml-1" />;
            return ``;
        }
        
        const value = !item?.isMeta ? (price*usd?.matic).toFixed(2) : (price*usd?.eth).toFixed(2);

        return ` ($${commaSeparatedPrice(value)})`;
    }, [usd, item?.isMeta]);

    const goToRoute = () => {
        history?.push({ pathname: `/${Routes.products}/${artId}`, state: url });
    };

    return(

        <div className="app-flex-column cursor-pointer-sort position-relative pointer-event nft-hover">

            <div onClick={goToRoute}>
                <ArtworkType height="320px" srcUrl={artwork_url} controls={false} autoPlay={false} musicOriginalNFT
                type={artwork_type} isDark={isDark} name={artworkName} curveBorders thumbNail_url={item?.thumbNail_url} />
            </div>
            
            <div className={`px-3 hidescrollbar bg-grey-30 overflow-auto`} 
                style={{ height: '138px', borderRadius: '0px 0px 12px 12px', marginTop: artwork_type?.toLowerCase() === 'video' ? '-6.5px' : '0px' }}
            >
            
                <FlexRow 
                    className={`w-100 align-items-center justify-content-between font-roboto py-2 flex-nowrap text-truncate`} 
                    onClick={goToRoute}
                >
                    <div className="font-15px text-darkGray50 text-truncate pr-2 text-capitalize">{ artName }</div>
                    <div className="ml-auto font-14px">
                        <FlexRow className={`w-auto align-items-center justify-content-start text-darkGray50 flex-nowrap`}>
                            { numberOfLikes > 0 ? <AiFillHeart className="font-16px text-red cursor-pointer-sort mr-1" /> : <AiOutlineHeart className="font-16px cursor-pointer-sort mr-1" /> }
                            <span className="font-13px"> { numberOfLikes } </span>
                        </FlexRow>
                    </div>
                </FlexRow>

                <FlexRow 
                    onClick={goToRoute}
                    className="w-100 align-items-center justify-content-between font-roboto py-2 text-darkGray50 flex-nowrap" 
                    style={{ borderTop: '1px solid #999999', borderBottom: (isAuctionOpen || openForSale) ? '1px solid #999999' : 'none' }}
                >
                    
                    <div className="font-14px text-truncate" onClick={(e) => {
                        e.stopPropagation();
                        history.push(`/${Routes.profile}/${ownerId}`);
                    }}> 
                        { getOwner(owner?.userName, owner?.profilePic) } 
                    </div>

                    <FlexRow className="align-items-center justify-content-start text-truncate pl-1 ml-auto font-13px w-auto font-weight-bold text-black flex-nowrap">
                        
                        <img alt="eth" className='mr-2' src={!item?.isMeta ? Images.polygon : Images.ethLogo} 
                        width={ item?.isMeta ? "14px" : "17px" } />

                        <span className="text-truncate"> 
                            { getPrice() } { !item?.isMeta ? 'MATIC' : 'ETH' } { withDollar(getPrice()) }
                        </span>

                    </FlexRow>
                </FlexRow>
                
                { ((isAuctionOpen || openForSale) || showRemove) &&
                <FlexRow className={`w-100 align-items-center justify-content-between font-roboto pt-2 pb-1 text-darkGray50 flex-wrap`}>
                    <div className="font-14px">
                        { ( (userId && userId === ownerId) || (userId && item?.auction?.bidders?.includes(userId)) ) ? null : (
                            (!showRemove && random) &&
                            <BorderButton title={ isAuctionOpen ? "Place bid" : "Purchase" } 
                            className={`text-white gradient hover border-0 py-2 px-4 rounded font-13px ${endAuction ? 'd-none' : ''}`} 
                            onClick={(t, e) => {
                                e.stopPropagation();

                                if(!userId){
                                    setErr({ show: true, type: 'error', title: 'Please Sign In/Up To Your MarvmentNFT Account', message: `` });
                                }

                                if(isAuctionOpen && userId){
                                    // goToRoute();
                                    setOpenModal({ flag: true, title: 'bid' });
                                }
                                
                                if(openForSale && userId){
                                    // goToRoute();
                                    setOpenModal({ flag: true, title: 'purchase' });
                                }

                            }} />
                        ) }
                    </div>
                    
                    { isAuctionOpen &&
                    <div className="ml-auto font-13px font-weight-bold text-black">
                        <span className='d-block text-lightPurple'>{!endAuction && 'Ends in:'}</span>
                        <span>
                            <CounterTimer defaultLayout={true} endTime={endTime} closeBid={() => setEndAuction(true)} />
                        </span>
                    </div> }

                    {
                        (showRemove) &&
                        <BorderButton title="Remove" className="cursor-pointer-sort py-1 px-3 text-red border border-red rounded-pill font-10px" 
                            onClick={(t, e) => {
                                e.stopPropagation();
                                clickRemove(artId);
                            }}
                        />
                    }

                </FlexRow> }

            </div>
    
            {
                openModal.flag &&
                <LargeModal 
                    condition={openModal.flag} size={3}
                    title={openModal.title === 'bid' ? `Place your bid on ${item?.name}` : null}
                    handleClose={(num, flag, e) => {
                        e.stopPropagation();
                        setOpenModal({ flag: false, title: null });
                    }}
                >

                    { openModal.title === 'bid' && 
                        <BidProduct 
                            src={artwork_url} contractAucId={item?.auction?.contractAucId}
                            name={artworkName} history={history} type={artwork_type} withIn={true}
                            price={item?.price} usd={usd} userAddress={user?.info?.address}
                            initialPrice={item?.auction?.maxBid ? item?.auction?.maxBid : item?.auction ? item?.auction?.initialPrice : item?.basePrice}
                            bidObj={{ artwork: item?.auction?.artwork, owner: item?.auction?.owner, 
                                bidder: userId, auction: item?.auction?._id 
                            }}
                            auctionId={item?.auction?._id} userId={userId} artworkId={artId}
                            refreshState={() => {
                                setRandom(false);
                                setOpenModal({ flag: false, title: null });
                            }} isMeta={item?.isMeta} thumbNail_url={item?.thumbNail_url}
                        /> 
                    }

                    { openModal.title === 'purchase' &&
                        <PurchaseProduct 
                            src={artwork_url} type={artwork_type} item={item} currentArtwork={item}
                            name={artworkName} history={history} contractSaleId={item?.sale?.contractSaleId}
                            price={item?.price} usd={usd} userBalance={user?.userBalance} withIn={true}
                            userAddress={user?.info?.address} purchased={openModal.title === 'purchase'}
                            refreshState={() => {
                                setRandom(false);
                                setOpenModal({ flag: false, title: null });
                            }} isMeta={item?.isMeta} thumbNail_url={item?.thumbNail_url}
                        />
                    }
                    
                </LargeModal>
            }

            <AlertPopup 
                data={{ show: err?.show, title: err.title, message: err?.message, type: err?.type }}
                handleClose={() => {
                    setErr({ ...err, show: false });
                }}
            />

        </div>

    );
};

export default CombineListItem;