import React from 'react';
import { Images } from 'Assets/Images';
import ArtworkIcon from 'components/ArtworkIcon/ArtworkIcon';

const ListItem = (props) => {
    
    const { title, heading, text, showBorder, colorHeading, className, adjustment, showAvatar, 
    rightText, showAvatarSrc, onClick, clickState, type } = props;

    const clickListItem = (e) => {
        if(clickState){
            onClick(e)
        }
    };

    return(

        <div 
            className={`w-100 app-flex-row align-items-center justify-content-start font-roboto py-1 ${className}`} 
            style={{ borderBottom: showBorder ? '2px solid var(--grey)' : 'none' }}
            onClick={(e) => clickListItem(e)}
        >
            
            {
                (showAvatar && (type === 'image' || type === 'gif')) &&
                <img alt="" src={ (showAvatarSrc && showAvatarSrc !== 'undefined') ? showAvatarSrc : Images.uLogo} className="mr-2 rounded-circle d-flex" 
                width={ adjustment ? "50px" : '35px' } height={ adjustment ? "50px" : '35px' } />
            }

            {
                (showAvatar && type !== 'image' && type !== 'gif') &&
                <ArtworkIcon width={ adjustment ? "50px" : '35px' } 
                height={ adjustment ? "50px" : '35px' } className="rounded-circle mr-2" 
                type={type} name={title} />
            }

            <div className="app-flex-column flex-grow-1">
                <span className="font-16px font-clashRegular text-capitalize">
                    <span className={`font-weight-bold mr-1 font-clashRegular ${colorHeading}`}>{title}</span>
                    {heading}
                </span>
                { text && <span className="font-14px" style={{font: 'lato'}}>{text}</span> }
            </div>

            { rightText && <span className="font-13px ml-auto">{rightText}</span> }

        </div>

    );

}
 
export default ListItem;