import React, { useState, useEffect, useRef } from "react";
import "./Layout.css";

import TopBar from "components/TopBar/TopBar";
import { isLogoutAction } from "redux/actions/action";
import { checkAccounts } from "services/contract.service";
import { useDispatch } from 'react-redux';
import { SignInSmooth } from "screens/SignInUp/SignInSmooth";
import { coinbaseWallet } from "screens/SignInUp/connector";

const MainLayout = (props) => {

  const handleContainer = useRef();

  const [showModal, setShowModal] = useState({ index: null, flag: false });

  const { isLogin, children, state, currentTheme } = props;

  const dispatch = useDispatch();

  useEffect(() => {      
    if(window.ethereum){
        window.ethereum.on('accountsChanged', async () => {
            logout();
            await coinbaseWallet.deactivate();
        });
    }
  }, []);

  useEffect(() => {
    if(state?.connection){
      const interval = setInterval(async() => {
        const hasConnected = await checkAccounts();
          
          if(!hasConnected){
              logout();
          }

      }, 5000);

      return () => clearInterval(interval);
    }
  }, [state]);

  const setModalState = (index, flag) => {
    setShowModal({ index, flag });
  };
  
  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    dispatch(isLogoutAction());
    window.location.reload();
  }

  const alertElement = document.getElementById('error-message');

  if (isLogin) {
    return (
      <div className="w-100 app-flex-column" style={{ overflow: "hidden" }} ref={handleContainer}>
                
        <div className="d-flex flex-column align-items-center position-absolute pt-5" id="error-message" 
          style={{ zIndex: -1, left: 0, right: 0, bottom: 0, top: 0, background: 'rgba(0, 0, 0, .3)' }}
        >
          <div className="py-3 px-5 bg-grey-30 rounded-lg w-auto d-flex flex-column align-items-center justify-content-center">
            <span className="font-18px mb-3 d-block font-medium">
              Please connect with the correct blockchain
            </span>
            <button onClick={() => {
              alertElement.style.zIndex = -1;
              alertElement.style.visibility = 'hidden';
            }}
                className="text-white bg-pink border-0 outline-none font-15px rounded py-2 px-4" 
            >
              OK
            </button>
          </div>          
        </div>

        <TopBar
          clickLink={(index) => setModalState(index, true)}
        />

        <div className={ alertElement ? ( alertElement.style.zIndex === 9999 ? "pointer-none" : "" ) : "" }>
          {children}
        </div>        

        <SignInSmooth currentTheme={currentTheme} modalProps={showModal} defaultBehaviour={true} />

      </div>
    );
  } else return children;
};

export default MainLayout;
