import React from 'react';
import CustomModal from 'components/CustomModal/CustomModal';
import { Images } from 'Assets/Images';

const ProcessWaiting = ({ message, show }) => {

    return (

        <CustomModal size={3} show={show} handleClose={() => console.log()}>
            <div className="app-flex-column align-items-center justify-content-center p-4 flex-wrap">
                <img alt="logo" width="150px" className='mb-3' src={Images.logoLight} />
                <span className="font-15px d-block font-weight-bold"> "{ message}" </span>
            </div>
        </CustomModal>

    );

}
 
export default ProcessWaiting;