import React, { useEffect, useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import AlertPopup from 'components/AlertPopup/AlertPopup';
import { deleteArtService } from 'services/artService';
import { getAllArtworksForAdmin } from 'services/general.service';
import CombineListItem from 'components/CombineListItem/CombineListItem';
import LoadData from 'components/LoadData/LoadData';
import Pagination from 'components/Pagination/Pagination';
import { useHistory } from 'react-router-dom';
import FlexRow from 'components/FlexRow/FlexRow';

const AllArtworks = (props) => {
    
    const { theme, usd } = props;

    const history = useHistory();
    const [arts, setArts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [random, setRandom] = useState(0);
    const [count, setCount] = useState(false);
    const [page, setPage] = useState({ page: 0, limit: 8 });
    const [err, setErr] = useState({ show: false, type: '', message: '', title: '' });
    const [delErr, setDelErr] = useState({ show: false, type: '', message: '', title: '', loading: false, id: undefined });

    useEffect(() => {       
        setLoading(true);

        getAllArtworksForAdmin(page).then((res) => {
            setArts(res?.data?.data?.artwork);
            setCount(res?.data?.data?.count);
            setLoading(false);
        }).catch((err) => {
            setArts([]);
            setCount(0);
            setLoading(false);
        });       
    }, [page, random]);

    const deleteArtwork = (id) => {
        if(id){
            const obj = { "artworkId": id };

            deleteArtService(obj).then((res) => {
                setDelErr({ ...delErr, show: false, loading: false, id: undefined });
                setErr({ show: true, type: 'success', title: 'Artwork Successfully Deleted', message: '' });
                setRandom(Math.random());
            }).catch((err) => {
                setDelErr({ ...delErr, show: false, loading: false, id: undefined });
                setErr({ show: true, type: 'error', title: 'Artwork Not Deleted', message: '' });
            });
        }
    };

    return (

        <Row className="w-100 p-0 m-0">
            <LoadData loading={loading} arr={arts} msg="No arts available">
                { arts?.map((item, i) => (
                    <Col xs={12} sm={12} md={6} lg={4} xl={3} key={i} className='mb-4'>
                        
                        <FlexRow className="w-100 justify-content-end mr-1 mb-1">
                            <button className='font-13px text-pink bg-transparent border-0 font-weight-bold'
                            onClick={() => setDelErr({ 
                                show: true, type: 'error', message: 'Are you sure you want to remove this NFT?',
                                title: 'Remove NFT', loading: false, id: item?._id })}
                            >Remove</button>
                        </FlexRow>

                        <CombineListItem
                            artwork_url={item?.artwork_url} artwork_type={item?.artwork_type} artworkName={item?.name} 
                            openForSale={item?.openForSale} isAuctionOpen={item?.isAuctionOpen} artName={item?.name} 
                            artPrice={item?.price} endTime={item?.auction?.endTime} numberOfLikes={item?.numberOfLikes} 
                            owner={item?.owner} creater={item?.creater} item={{ ...item }} usd={usd}
                            history={history} artId={item?._id} isDark={theme === 'dark'} 
                        />
                    </Col>
                )) }
            </LoadData>

            {
                count > 9 &&
                <Pagination isDark={theme === 'dark'} 
                    nextClick={() => setPage({ ...page, page: page.page + 1 })} total={count}
                    backClick={() => setPage({ ...page, page: page.page - 1 })} perPage={9} 
                    textColor={theme === 'dark' ? 'text-white' : 'text-black'}
                    activeIndex={page.page} changePage={(i) => setPage({ ...page, page: i })} 
                />
            }

            <AlertPopup 
                data={{ show: delErr?.show, title: delErr.title, message: delErr?.message, type: delErr?.type }}
                handleClose={() => {
                    setDelErr({ ...delErr, loading: true });
                    setTimeout(() => deleteArtwork(delErr.id), 1000);
                }} loading={delErr.loading}
                showCancel={() => setDelErr({ ...delErr, show: false, id: undefined })} 
            />

            <AlertPopup 
                data={{ show: err?.show, title: err.title, message: err?.message, type: err?.type }}
                handleClose={() => {
                    setErr({ ...err, show: false });
                }}
            />

        </Row>

    );

}
 
export default AllArtworks;