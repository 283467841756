export const GENERES = [
    'African',
    'Alt Country/Americana',
    'Alternative',
    'Alternative Holiday',
    'Animation/Game',
    'Big Band/Swing',
    'Bluegrass/Folk',
    'Blues',
    'Brazilian/South American',
    'BrazilTestGenre',
    'Brit Rock',
    'Broadway',
    'Calypso',
    'Celtic',
    `Children's Music`,
    'Chinese',
    'Christian & Gospel',
    'Christian & Gospel Holiday',
    'Christian Hip-Hop',
    'Christian Rock',
    'Classic Blues',
    'Classic Country',
    'Classic Rock',
    'Classic Soul',
    'Classical',
    'Classical Holiday',
    'Classical Pop',
    'Club/Dance',
    'Comedy/Humor',
    'Compas',
    'Contemporary Holiday',
    'Country',
    'Country Gospel',
    'Country Holiday',
    'Country Pop',
    'Dance Pop',
    'Dancehall',
    'Dirty South',
    'Disco',
    'Dub',
    'East Coast',
    'Easy Listening',
    'Electro/Alternative',
    'Electronic/Dance',
    ' Electronica',
    ' Filipino',
    ' Film Scores',
    ' Flim/TV Music',
    ' French',
    ' French Canadian',
    ' Funk',
    ' Gangsta',
    ' German',
    ' Gospel',
    ' Goth/Industrial',
    ' Grunge',
    'Hard Rock',
    'Holiday',
    'Indian',
    'Indie',
    'Italian',
    'Jam Band',
    'Japanese',
    'Jazz',
    'Jazz Fusion',
    'Jazz Vocal',
    'Karaoke',
    'Korean',
    'Latin Holiday',
    'Latin Jazz',
    'Latin Pop',
    'Latino',
    'Metal',
    'Middle Eastern',
    'Modern Blues',
    'Modern Country',
    'Modern Jazz',
    'Motown',
    'Neo Soul',
    'New Age',
    'New Wave/Post-Punk',
    'Old School',
    `Oldies/Rock n' Roll`,
    'Orchestral',
    'Other',
    'Outlaw Country',
    'Pop',
    'Pop & Inspirational',
    'Pop Rock',
    'Prog Rock',
    'Punk/Emo/Hardcore',
    'Quiet Storm',
    'R&B/Soul',
    'Ragga-Soca',
    'Rap/Hip-Hop',
    'Reggae',
    'Reggaeton',
    'Regional Mexicano',
    'Rock',
    'Rock en Español',
    'Rock Holiday',
    'Roots Rock',
    'Salsa y Tropical',
    'Singer-Songwriter',
    'Ska',
    'Smooth Jazz',
    'Soca',
    'Soundtrack',
    'Southern Rock',
    'Spoken Word',
    'Teen Pop',
    'Traditional Holiday',
    'Traditional Jazz',
    'Trip-Hop',
    'Underground',
    'Urban Latino',
    'Vocal',
    'Vocal Pop',
    'West Coast',
    'World',
    'World Beat',
    'World Holiday',
    'World Pop'
];