import FlexRow from 'components/FlexRow/FlexRow';
import React from 'react';
import { MdCheckBoxOutlineBlank, IoCheckbox } from 'react-icons/all';

const CheckFilter = (props) => {
    
    const { arr, className, activeId, onClick, small, flag, children } = props;

    return (

        <FlexRow className={`${className} w-100 align-items-center`}>
            {
                arr.map((item, i) => (
                    <div key={i} onClick={() => onClick(item)} className="cursor-pointer-sort font-clashMedium">
                        {
                            ((item?.id === activeId) || flag) ? <IoCheckbox className={`${small ? 'font-20px' : 'font-25px'} text-lightPurple`} style={{ marginBottom: '4px' }} /> :
                            <MdCheckBoxOutlineBlank className={`${small ? 'font-20px' : 'font-25px'} text-lightPurple`} style={{ marginBottom: '4px' }} />
                        }
                        <span className={`${i < arr.length-1 ? 'ml-2 mr-4' : 'ml-2'}`}>
                            { item?.value }
                        </span>
                    </div>
                ))
            }
            <div className='ml-1'>
                { children }
            </div>
        </FlexRow>

    );

}
 
export default CheckFilter;