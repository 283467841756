import React from 'react';
import FlexRow from 'components/FlexRow/FlexRow';
import { Images } from 'Assets/Images';

export const ProductLinks = (props) => {

    const { geners, links, isGenre } = props;

    const icons = [
        { id: 1, icon: Images.socialLinkIcons[0], link: links?.youtube, name: 'Youtube', width: 40 },
        { id: 2, icon: Images.socialLinkIcons[1], link: links?.spotify, name: 'Spotify', width: 40 },
        { id: 3, icon: Images.socialLinkIcons[3], link: links?.itunes, name: 'iTunes', width: 40 },
        { id: 4, icon: Images.socialLinkIcons[4], link: links?.instagram, name: 'Instagram', width: 33 },
        { id: 5, icon: Images.socialLinkIcons[5], link: links?.tiktok, name: 'Tiktok', width: 31 },
        { id: 6, icon: Images.socialLinkIcons[2], link: links?.wifiHash, name: 'Globe', width: 40 }
    ];

    return(

        <div className="d-flex flex-column w-100 pr-2 pb-4">
            { isGenre ? <Geners geners={geners} /> : <Links icons={icons} /> }            
        </div>

    );

};

const Geners = ({ geners }) => {
    
    return (
        <React.Fragment>
            { geners && 
            <FlexRow className="flex-wrap align-items-center justify-content-start mb-3 w-100">
                <span className="font-15px mr-2"><b>Genre:</b></span>
                { Array.isArray(geners) ? geners?.map((g, i) => (
                    <span className="font-15px text-capitalize" key={i}>
                        { g } { (i < geners?.length - 1) && ', ' }
                    </span>
                )) : <span className="font-15px text-capitalize"> { geners } </span> }
            </FlexRow> }
        </React.Fragment>
    );

}

const Links = ({icons}) => {

    return (

        <React.Fragment>
            { icons?.filter(f => f?.link)?.length ? <span className="font-15px mr-2 mb-1 d-block"><b>Links:</b></span> : '' } 
            <FlexRow 
                className="flex-wrap align-items-center justify-content-start mb-1 cursor-pointer-sort"
            >
                { icons.map((item, i) => (
                    item?.link &&
                    <img 
                        alt={item.name} src={item.icon} className="mr-3" width={`${item?.width}px`} key={item.id} 
                        onClick={() => window.open(item?.link, '_blank')}
                    />
                )) }
            </FlexRow>

        </React.Fragment>

    );

};