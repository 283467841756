import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

const withProtected = (WrappedComponent, redirectRoute) => {

    const WithProtectedWrapper = () => {

        const state = useSelector(state => state);
        const dispatch = useDispatch();

        const history = useHistory();

        if(!state?.user?.isLogin || !state?.connection){
            return <Redirect exact={true} to={`/${redirectRoute}`} />
        }

        else {
            return <WrappedComponent state={state} dispatch={dispatch} history={history} />
        }

    }

    return WithProtectedWrapper;

}
 
export default withProtected;