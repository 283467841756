import React from 'react';
import { Images } from 'Assets/Images';

export const Box = ({ title, isDark }) => {

    return(

        <div 
            className="d-flex flex-column align-items-center justify-content-center artwork-height w-100 overflow-hidden" 
            style={{ height: '320px', border: `2px dashed ${isDark ? 'var(--white)' : 'var(--pink)'}`, borderRadius: '14px' }}
        >

            <h6 className={`font-23px text-uppercase px-3 text-center font-normal mb-4 ${isDark ? 'text-white' : 'text-black'}`}>
                { title }
            </h6>

            <img alt="" src={isDark ? Images.whitePlus : Images.pinkPlus} width="90px" />

        </div>

    );

}