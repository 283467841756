import React from "react";
import "./BorderButton.css";

import {
  ImSpinner3,
  VscArrowSmallDown,
  VscArrowSmallUp,
  ImPencil,
  RiSettings4Fill,
  BsBellFill,
  CgSoftwareUpload,
  FiArrowUpRight,
} from "react-icons/all";
import { Images } from "Assets/Images";

const BorderButton = (props) => {
  const {
    title,
    showLoading,
    className,
    showArrow,
    showArrowUp,
    showArrowDown,
    disabled,
    showArrowUpRight,
  } = props;

  const { showSetting, showEdit, showBell, showUpload, onClick } = props;

  return (
    <button
      className={`load-more-btn ${className}`}
      onClick={(e) => onClick(title, e)}
      disabled={disabled}
      style={props.style}
    >
      {showLoading && (
        <span className="mr-2">
          <ImSpinner3 style={{ marginBottom: "2px" }} />
        </span>
      )}

      {showArrowUp && (
        <span>
          <VscArrowSmallUp
            style={{ marginBottom: "2px" }}
            className="text-white font-20px"
          />
        </span>
      )}

      {showArrowDown && (
        <span>
          <VscArrowSmallDown
            style={{ marginBottom: "2px" }}
            className="text-black font-20px"
          />
        </span>
      )}

      {showSetting && (
        <span>
          <RiSettings4Fill
            style={{ marginBottom: "2px" }}
            className="text-lightPurple font-20px mr-3"
          />
        </span>
      )}

      {showEdit && (
        <span>
          <ImPencil
            style={{ marginBottom: "2px" }}
            className="text-lightPurple font-16px mr-3"
          />
        </span>
      )}

      {showBell && (
        <span>
          <BsBellFill
            style={{ marginBottom: "2px" }}
            className="text-lightPurple font-16px mr-3"
          />
        </span>
      )}

      {showUpload && (
        <span>
          <CgSoftwareUpload
            style={{ marginBottom: "2px" }}
            className="text-lightPurple font-20px mr-2"
          />
        </span>
      )}

      {title}

      {showArrowUpRight && (
        <span>
          <FiArrowUpRight
            style={{ marginBottom: "2px" }}
            className="font-20px ml-2"
          />
        </span>
      )}

      {showArrow && (
        <img alt="" className="ml-2" width="12px" src={Images.arrow} />
      )}
    </button>
  );
};

export default BorderButton;
