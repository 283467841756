import React from "react";
import "./Header.css";
import { Row, Col } from "react-bootstrap";
import FlexRow from "components/FlexRow/FlexRow";
import BorderButton from "components/BorderButton/BorderButton";
import { Routes } from "Routes/Routes";
import HeaderCounts from "./Counts";
import { Images } from "Assets/Images";

const Header = (props) => {

  return (
    <section
      className={`header-section padding-section position-relative ${props.textColor}`}
      id="home-section"
      // style={{ background: props.isDark ? `url(${Images.bgHeaderDark})` : `url(${Images.bgHeaderDark})`, 
      //   backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat' }}
      >
      <img
        alt=""
        width="385px"
        height="100%"
        src={Images.leftSideImage}
        style={{ zIndex: 10, left: 0 }}
        className="position-absolute d-sm-none d-md-none d-lg-block d-xl-block bgHeaderRight"
      />
      <img
        alt=""
        width={props.isLogin ? "340px" : "356px"}
        height="100%"
        src={Images.bgHeaderRight}
        style={{ zIndex: 1, right: 0, opacity: .7 }}
        className="position-absolute d-sm-none d-md-none d-lg-block d-xl-block bgHeaderRight"
      />
      <img
        alt="" width="100%" height="100%"
        src={Images.bgHeaderDark}
        style={{ zIndex: 3 }}
        className="position-absolute"
      />

      <Row className="p-0 w-100">
        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="my-auto px-1" style={{ zIndex: 20 }}>
          <div className="font-42px d-block mb-2 font-weight-bold font-clashRegular">
            The NFT Marketplace For Music Creators!
          </div>
          <div className="font-16px d-block font-medium font-clashRegular">
            Here artists get a chance to exhibit their musical works in the form of NFTs to build an unlimited fan base through the power of Blockchain.
          </div>

          <FlexRow className="align-items-center justify-content-start flex-wrap header-section-btns my-4 w-100">
            <BorderButton
              title="Discover"
              className="rounded text-white border-0 bg-pink font-clashRegular mb-4 header-btn font-weight-bold"
              onClick={() => props?.history?.push(`/${Routes.discover}`)}
            />
            <BorderButton
              title="Create NFT"
              className={`rounded bg-transparent font-clashRegular border header-buy-button mb-4 header-btn
                        ${
                          props.isDark
                            ? "text-white border-pink"
                            : "text-darkBlack border-pink"
                        } `}
              onClick={() => props.isLogin ? props?.history?.push(`/${Routes.profile}`) : props.getStarted()}
            />
            {/* <HeaderCounts
              counts={props.counts} history={props.history}
              className={
                props.isDark
                  ? "text-white headerCounts font-clashRegular"
                  : "text-darkBlack headerCounts font-clashRegular"
              }
            /> */}
          </FlexRow>
              
        </Col>

        <Col
          xs={12} sm={12} md={6} lg={6} xl={{ span: 4, offset: 1 }}
          className="align-items-center justify-content-center app-flex-column mb-4"
        >
          <div className="headerNft">
            <img alt="" src={props.isDark ? Images.imgDark : Images.imgLight} style={{ width: "100%" }} className="rounded" />
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default Header;
