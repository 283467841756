export const dataLists = [
    { id: 1, title: `Q1. Which Blockchain network does Marvment NFT run on?`, text: undefined, showNumber: undefined, subList: [], head: true },
    { id: 2, title: `Marvment NFTs are sold on the Marvment NFT’s marketplace and are minted and operated exclusively on the Ethereum Blockchain Network.`, text: undefined, showNumber: undefined, subList: [] },

    { id: 3, title: `Q2. How can I create and sell an NFT on this marketplace?`, text: undefined, showNumber: undefined, subList: [], head: true },
    { id: 4, title: `Once you create an account on our platform, and connect your Metamask Wallet, you can easily create your NFT collection in your portfolio based on songs, images, GIFs and albums of your choice. You can set your minting price and your NFTs are open for sale.`, text: undefined, showNumber: undefined, subList: [] },

    { id: 5, title: `Q3. How can I buy an NFT on Marvment NFT?`, text: undefined, showNumber: undefined, subList: [], head: true },
    { id: 6, title: `First you need to sign up and connect your Metamask Wallet to buy an NFT on Marvment NFT. Users can browse through our vast range of musical NFTs or explore the artists they idolize and purchase their NFTs. The amount for the NFT will be deducted from your Metamask Wallet and transferred to the artist’s Wallet.`, text: undefined, showNumber: undefined, subList: [] },

    { id: 7, title: `Q4. Where is your Marketplace available?`, text: undefined, showNumber: undefined, subList: [], head: true },
    { id: 8, title: `Our Marketplace is currently available on the Ethereum Network.`, text: undefined, showNumber: undefined, subList: [] },

    { id: 9, title: `Q5. What payment methods do you support?`, text: undefined, showNumber: undefined, subList: [], head: true },
    { id: 10, title: `We accept payments in Ethereum, this can be done easily via connecting your Metamask Wallet.`, text: undefined, showNumber: undefined, subList: [] },

    { id: 11, title: `Q6. Do I need to create a Marvment account to get started?`, text: undefined, showNumber: undefined, subList: [], head: true },
    { id: 12, title: `Yes, you need to create a Marvment NFT account to get started in this marketplace.`, text: undefined, showNumber: undefined, subList: [] },

    { id: 13, title: `Q7. What percentage of royalties can I set up for my NFTs?`, text: undefined, showNumber: undefined, subList: [], head: true },
    { id: 14, title: `You can set up royalties between a range of 1% to 10%., and not more than that bracket.`, text: undefined, showNumber: undefined, subList: [] },

    { id: 15, title: `Q8. What happens when I sell an NFT on Marvment NFT?`, text: undefined, showNumber: undefined, subList: [], head: true },
    { id: 16, title: `When one of your listed NFTs are sold, Marvment NFT automatically deposits the proceeds of your sale to your Metamask Wallet.`, text: undefined, showNumber: undefined, subList: [] },

    { id: 17, title: `Q9. Are there any limitations on the number of NFTs that I can create and list?`, text: undefined, showNumber: undefined, subList: [], head: true },
    { id: 18, title: `No, there are no limits to the number of NFTs you can list for sale on Marvment NFT. We are a platform without bounds.`, text: undefined, showNumber: undefined, subList: [] },
];

export const dataListsUpdated = [
    { id: 1, title: `Q1. Which Blockchain network does MarvmentNFT run on?`, 
    text: `Marvment NFTs are minted and operated exclusively on the Ethereum Blockchain Network.` },

    { id: 2, title: `Q2. How do I create and sell NFTs?`, text: undefined, answers: [
        '1.	Connect your Metamask wallet then click sign-in to create an account.',
        '2.	Select your MarvmentNFT profile then chose a desired collection (Art, Video, Audio, Gif).',
        '3.	Select “Upload NFT” to upload your asset.',
        '4.	Click on the NFT that you uploaded then select “Sell” or “Auction”: Enter your desired sale price or auction date.',
        '5.	Step 4 will open the Metamask wallet with the details of your purchase. Click “Confirm” to approve the transaction. Your NFT will then become available for sale.'
    ] },

    { id: 3, title: `Q3. How can I buy NFTs?`, text: undefined, answers: [
        '1.	Connect your Metamask wallet then click the sign-in button.',
        '2.	Select the NFT you wish to purchase then click on the “Buy” button.',
        '3.	Step 2 will open your Metamask wallet. Click confirm to complete the purchase.',
        '4.	The NFT that you purchased will then appear under the relevant collection.'
    ] },

    { id: 4, title: `Q4 What are the fees to complete a transaction on MarvmentNFT?`, 
    text: `MarvmentNFT will take a fixed 2.5% fee for all transactions. You will be required to pay a gas fee which varies. Gas fees are transaction fees on Ethereum. MarvmentNFT does not receive these fees and is not able to refund them.` },

    { id: 5, title: `Q5. Where is your Marketplace available?`, 
    text: `Our Marketplace is currently available on the Ethereum Network.` },

    { id: 6, title: `Q6. What payment methods do you support?`, 
    text: `We accept payments in Ethereum; this can be done easily via connecting your Metamask Wallet.` },

    { id: 7, title: `Q7. What percentage of royalties can I set for future sale of my NFTs?`, 
    text: `You can set royalties from 1% - 10% for your collections.` },

    { id: 8, title: `Q8. What happens when I sell an NFT?`, 
    text: `When one of your listed NFTs is sold, MarvmentNFT automatically deposits the proceeds of your sale to your Metamask Wallet.` },

    { id: 9, title: `Q9. Are there limits on the number of NFTs that I can create and list?`, 
    text: `No, there are no limits to the number of NFTs you can list for sale on MarvmentNFT.` },
];