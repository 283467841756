import React, { useState } from 'react';
import './CombineListItem.css';

import { Routes } from 'Routes/Routes';
import ArtworkType from 'components/ArtworkType/ArtworkType';
import FlexRow from 'components/FlexRow/FlexRow';
import { Images } from 'Assets/Images';
import BorderButton from 'components/BorderButton/BorderButton';
import { CreateAlbum } from 'screens/Album/CreateAlbum';
import { useDispatch } from 'react-redux';
import { isProfileAlbumOpen } from 'redux/actions/action';
import AlertPopup from 'components/AlertPopup/AlertPopup';
import { deleteSingleAlbum } from 'services/albumService';

const AlbumListItem = (props) => {

    const { owner, album, isDark, showEdit, history, url, user, srcUrl, reRender, albumArtworks } = props;

    const [ openModal, setOpenModal ] = useState({ flag: false, title: null });
    const [delErr, setDelErr] = useState({ show: false, type: '', message: '', title: '', loading: false, id: undefined });

    const dispatch = useDispatch();

    const getOwner = (name, src) => {
        return <FlexRow className="w-auto align-items-center justify-content-start flex-nowrap">
            <img alt="" src={src ? src : Images.uLogo} width="22px" height="22px" className="mr-2 rounded-circle border-0" />
            <span className="flex-grow-1 font-13px text-darkGray50 text-capitalize"> { name } </span>
        </FlexRow>
    }

    const goToRoute = () => {
        if(showEdit){
            dispatch(isProfileAlbumOpen(true));
        } else {
            dispatch(isProfileAlbumOpen(false));            
        }

        history?.push({ pathname: `/${Routes.album}/${album?.id}`, state: url });
    };

    const deleteAlbum = (id) => {
        if(id){
            deleteSingleAlbum(id).then((res) => {
                setDelErr({ ...delErr, show: false, id: undefined });
                reRender();
            }).catch((err) => {
                setDelErr({ ...delErr, show: false, id: undefined });
            });
        }
    };

    return(

        <div className="app-flex-column cursor-pointer-sort pointer-event nft-hover">

            <div onClick={goToRoute} className="position-relative">
                <ArtworkType 
                    height="320px" srcUrl={srcUrl} controls={false} autoPlay={false} musicOriginalNFT
                    type="image" isDark={isDark} name={album?.name} curveBorders 
                />
                <span className="rounded-pill py-1 px-4 bg-pink border-0 font-13px text-white m-0 position-absolute"
                    style={{ bottom: -15, left: '50%', transform: 'translateX(-50%)' }} 
                >
                    Album
                </span>
            </div>
            
            <div className={`px-3 hidescrollbar bg-grey-30 overflow-auto`} 
                style={{ height: '138px', borderRadius: '0px 0px 12px 12px', marginTop: '0px' }}
            >                 

                <FlexRow 
                    className={`w-100 align-items-center justify-content-between font-roboto py-2 flex-nowrap text-truncate`} 
                    onClick={goToRoute}
                >
                    <div className="font-15px text-darkGray50 text-truncate pr-2 text-capitalize">{ album?.name }</div>
                    <div className="ml-auto font-14px">
                        <FlexRow className={`w-auto align-items-center justify-content-start text-darkGray50 flex-nowrap`}>
                            <span className="font-14px"> Tracks: { album?.tracks } </span>
                        </FlexRow>
                    </div>
                </FlexRow>

                <FlexRow 
                    onClick={goToRoute}
                    className={`w-100 align-items-center justify-content-between text-truncate font-roboto py-2 text-darkGray50 flex-nowrap`} 
                    style={{ borderTop: '1px solid #999999' }}
                >
                    <div className="font-14px text-truncate"> { getOwner(owner?.userName, owner?.profilePic) } </div>
                    <FlexRow className="align-items-center justify-content-start text-truncate ml-auto font-14px w-auto text-black">
                        Genre: { album?.genre }
                    </FlexRow>
                </FlexRow>
                
                <FlexRow className={`w-100 align-items-center justify-content-end font-roboto pt-2 pb-1 text-darkGray50 flex-wrap`}>                    

                    {
                        (showEdit && albumArtworks?.length === 0) &&
                        <BorderButton title="Delete" className="cursor-pointer-sort py-1 mr-2 px-4 text-red border border-red rounded-pill font-10px" 
                            onClick={(t, e) => {
                                e.stopPropagation();
                                setDelErr({ show: true, type: 'error', message: 'Are you sure want to delete this album?',
                                                 title: 'Delete Album', loading: false, id: album?.id })
                            }}
                        />
                    }

                    {
                        (showEdit) &&
                        <BorderButton title="Edit" className="cursor-pointer-sort py-1 px-4 text-red border border-red rounded-pill font-10px" 
                            onClick={(t, e) => {
                                e.stopPropagation();
                                setOpenModal({ title: 'Update', flag: true });
                            }}
                        />
                    }

                </FlexRow>

            </div>

            <AlertPopup 
                data={{ show: delErr?.show, title: delErr.title, message: delErr?.message, type: delErr?.type }}
                handleClose={() => {
                    setDelErr({ ...delErr, loading: true });
                    setTimeout(() => deleteAlbum(delErr.id), 1000);
                }} loading={delErr.loading}
                showCancel={() => setDelErr({ ...delErr, show: false, id: undefined })} 
            />
    
            <CreateAlbum openModal={openModal} srcUrl={srcUrl} reRender={reRender} album={album} onClose={(obj) => setOpenModal(obj)} />

        </div>

    );
    
}

export default AlbumListItem;