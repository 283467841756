import React, { useState, useCallback, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import CustomDropdown from 'components/Dropdown/Dropdown';
import { customizeFileType, commaSeparatedPrice } from 'utilities/CommonMethods';
import { GENERES } from 'Assets/Genres';
import { SimpleDropdownMenu } from 'components/Dropdown/Dropdown';
import { networks } from 'Assets/Data';
import { BsQuestionCircle } from 'react-icons/bs';
import { PopOverToolTip } from 'components/Popover/Popover';
import { isMobile } from 'react-device-detect';

export const SubmitNFTForm = (props) => {

    const { theme, getData, file, usd, removeGenreField } = props;

    const [values, setValues] = useState({ name: '', desc: '', youtube: '', web: '', spotify: '', itune: '', instagram: '', tiktok: '',
    price: null, geners: undefined, category: '' });
    const [network, setNetwork] = useState(undefined);

    useEffect(() => {
        getData({ ...values, isMeta: network?.isMeta });
    }, [values, network]);

    const onChange = useCallback((e) => {
        const { name, value } = e.target;

        setValues({ ...values, [name]: value });
    }, [values]);

    const fields = [
        { id: 1, label: `NFT Title`, key: 'name', readOnly: false, type: 'text', mandatory: true, condition: true },
        { id: 2, label: `Description`, key: 'desc', readOnly: false, type: 'text', mandatory: true, condition: true },
        { id: 3, label: `Genre`, key: 'geners', dropdown: true, mandatory: true, condition: !removeGenreField },
        { id: 0, label: undefined, key: '', readOnly: false, type: '', condition: false },
        { id: 10, label: `Price`, key: 'price', readOnly: false, type: 'number', mandatory: true, condition: true }
    ];

    const linkFields = [
        { id: 4, label: 'Youtube', key: 'youtube', readOnly: false, type: 'text', placeholder: 'https://www.youtube.com/P47bKdSJK7g&list', condition: true },
        { id: 5, label: 'Website', key: 'web', readOnly: false, type: 'text', placeholder: 'https://www.artist.com', condition: true },
        { id: 6, label: 'Spotify', key: 'spotify', readOnly: false, type: 'text', placeholder: 'https://spotify.com/2c9uszMB35kILfbOW1Nv', condition: true },
        { id: 7, label: 'iTunes', key: 'itune', readOnly: false, type: 'text', placeholder: 'https://music.apple.com/1446019514dcvfvsa', condition: true },
        { id: 8, label: 'Instagram', key: 'instagram', readOnly: false, type: 'text', placeholder: 'https://www.instagram.com/?hl=enhsdbnjsde', condition: true },
        { id: 9, label: 'Tiktok', key: 'tiktok', readOnly: false, type: 'text', placeholder: 'https://www.tiktok.com/en/shdcnkjsdnkjndck', condition: true }
    ];

    const getLinkFields = () => {
        return <Row className="position-relative">
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center font-15px mb-4 mt-2 font-weight-bold">
                <span>Add relevant links (optional)</span>
            </Col>
            { linkFields?.map((field, i) => (
                <Col xs={12} sm={12} md={6} lg={6} xl={6} key={i}>
                    <div className="form-group w-100 position-relative">
                        <label className="d-block font-18px">{field?.label}</label>
                        <input onChange={(e) => onChange(e)} type={field.type} step=".001" min="0.001"
                            name={field.key} value={ field?.id === 4 ? (!values[field.key]?.includes('image') ? values[field.key] : customizeFileType(file?.fullType)) : values[field.key]} 
                            readOnly={field?.readOnly} placeholder={field?.placeholder}
                            onWheel={(e) => field.type === 'number' && e.target.blur()}
                            className={getFieldClassName(field)} 
                        />
                    </div>
                </Col>
            )) }
        </Row>;
    }

    const getFieldClassName = (field) => {
        return `submit-nft-fields w-100 
        ${field?.key === 'category' ? 'text-capitalize' : ''}
        ${theme === 'dark' ? 'bg-transparent text-white border-white' : 'text-black bg-transparent border border-black'}`;
    };

    const usdConvert = network ? ( network?.isMeta ? usd?.eth : usd?.matic ) : 0;

    return fields.map((field, i) => (
        i === 3 ? getLinkFields() :
        field?.condition &&
        <div className="form-group w-100 position-relative" key={i}>
            <label className="d-block font-18px">{field?.label} <span className="text-red">{ field?.mandatory ? '*' : '' }</span></label>
            {
                field?.dropdown ? 
                <CustomDropdown title={ values?.geners ? values?.geners?.value : 'Choose' } 
                    arr={GENERES.map((g, i) => { return { id: i, value: g } })} 
                    className={`${getFieldClassName(field)} ${ !values?.geners?.value && 'border-red' } `}
                    chooseOption={(obj) => setValues({ ...values, geners: obj })} 
                /> 
                : field?.id === 10 ?
                <Row className="position-relative">
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <input onChange={(e) => onChange(e)} type={field.type} step=".001" min="0.001"
                            name={field.key} value={values[field.key]} readOnly={field?.readOnly}
                            onWheel={(e) => field.type === 'number' && e.target.blur()} required
                            className={`${getFieldClassName(field)} font-weight-bold mb-1`} 
                            onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                        />
                        <span className="position-absolute font-clashRegular font-weight-bold d-flex flex-shrink-0 flex-grow-0 align-items-center" style={{ right: 20, top: 4 }}>
                            <img alt={network?.value} src={network?.logo} width={network?.width} />
                            <SimpleDropdownMenu chooseOption={(obj) => {
                                    setNetwork(obj);
                                }} 
                                menu={networks} title={ isMobile ? "Choose Blockchain" : "Choose Your Blockchain" }
                                selected={network} className={`font-15px`}                             
                            />
                            <span data-tip data-for="price-netwrok">
                                <BsQuestionCircle />
                                <PopOverToolTip placement={isMobile ? "left" : "top"} id="price-netwrok" />
                            </span>
                        </span> 
                    </Col>
                    <span className="position-absolute font-clashRegular font-weight-bold d-none d-md-block d-lg-block d-xl-block" 
                    style={{ left: '49.5%', top: 12 }}>
                        =
                    </span> 
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <span className={`${getFieldClassName(field)} ${ isMobile ? 'px-1' : '' }
                            border-0 font-weight-bold d-flex flex-column justify-content-center`}
                        > 
                        { isMobile && 'Conversion = ' }
                        {`$${ commaSeparatedPrice((values.price*usdConvert).toFixed(2)) }`} </span>
                    </Col>
                </Row> :
                field?.id === 2 ? 
                <textarea style={{ resize: 'none', height: '190px' }} type="text"
                    className={`${getFieldClassName(field)} ${ values[field.key] ? '' : 'border-red'  } py-3 px-3`} 
                    onChange={(e) => setValues({ ...values, [field.key]: e.target.value })} value={values[field.key]}>
                </textarea> :
                <input onChange={(e) => onChange(e)} type={field.type} step=".001" min="0.001"
                    name={field.key} value={ field?.id === 4 ? (!values[field.key]?.includes('image') ? values[field.key] : customizeFileType(file?.fullType)) : values[field.key]} 
                    readOnly={field?.readOnly} required
                    onWheel={(e) => field.type === 'number' && e.target.blur()}
                    className={getFieldClassName(field)} 
                />
            }
        </div>
    ));

};