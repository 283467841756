import React, { useEffect, useState } from 'react';
import { Images } from 'Assets/Images';
import ArtworkType from 'components/ArtworkType/ArtworkType';

const UploadFile = (props) => {
    
    const { getFile, isDark, selectedFile, smallIndicator } = props;

    const [attachment, setAttachment] = useState({ id: 0, src: null, name: '', file: null, type: '' });

    useEffect(() => {
        if(!selectedFile){
            setAttachment({ id: 0, src: null, name: '', file: null, type: '' });
        }
        if(selectedFile){
            setAttachment(selectedFile);
        }
    }, [selectedFile]);

    const selectAttachment = (e) => {        
        let file = e.target.files[0];
        if (file === undefined) {
            return null;
        } else {
            let name = e.target.files[0].name;
            let type = e.target.files[0].type.substr(0, 5);
            let reader = new FileReader();
            reader.onloadend = () => {
                const item = { id: Math.random(), src: reader.result, name: name, file: file, type: type, fullType: e.target.files[0].type };
                
                setAttachment(item);
                covertImage(item);
            };
            reader.readAsDataURL(e.target.files[0], e.target.files[0].name);
        }
    };

    const covertImage = (file) => {
        getFile(file);
    };

    return (

        <label className={`upload-file-nft mb-5 position-relative`} 
            style={{ border: `1px solid ${isDark ? 'white' : 'black'} `, height: smallIndicator ? '290px' : '450px' 
        }}>

            {
                (attachment?.src) && <ArtworkType height="100%" srcUrl={attachment?.src} width="100%"
                type={attachment?.type} name={attachment?.name} musicOriginalNFT={true} />
            }

            <span>
                { !attachment?.src && <img alt="" src={isDark ? Images.whitePlus : Images.pinkPlus} 
                width={ smallIndicator ? "60px" : "120px"} accept="" />}
            </span>

            <input type="file" onChange={(e) => {                
                selectAttachment(e);
            }} />

        </label>

    );

}
 
export default UploadFile;