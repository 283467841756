import React, { useState, useEffect, useRef } from 'react';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import { Row, Col } from 'react-bootstrap';
import Input from '@material-ui/core/Input';
import { InputLabel, FormControl, InputAdornment } from '@material-ui/core';
import { Div } from 'components/Div/Div';
import { SpinnerLoader } from 'components/Spinner/Spinner';
import AlertPopup from 'components/AlertPopup/AlertPopup';
import FlexRow from 'components/FlexRow/FlexRow';
import { getRoyalty, setRoyalty } from 'services/contract.service';
import { isFloat } from 'utilities/dateValid';
import { getUserRoyalty, setUserRoyalty } from 'services/general.service';
import { SimpleDropdownMenu } from 'components/Dropdown/Dropdown';
import { networks } from 'Assets/Data';
import { BsQuestionCircle } from 'react-icons/bs';
import { PopOverToolTip } from 'components/Popover/Popover';
import { isMobile } from 'react-device-detect';

const CreateCollection = (props) => {
    
    const { collection, close, userId, userAddress, activeWallet } = props;
    
    const fieldRef = useRef(null);

    const [roy, setRoy] = useState(1);
    const [network, setNetwork] = useState(undefined);
    const [response, setResonse] = useState(undefined);
    const [err, setErr] = useState({ show: false, type: '', message: '', title: '' });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(fieldRef.current){
            fieldRef.current.focus();
        }

        getUserRoyalty(userAddress).then((res) => {
            setResonse(res?.data);
        }).catch((err) => {
            setResonse(undefined);
        });
    }, []);

    useEffect(() => {
        if(fieldRef.current){
            fieldRef.current.focus();
        }
        const a = network?.isMeta ? response?.Eroyality : !network?.isMeta ? response?.Proyality : 1;
        setRoy(a);        
    }, [network, response]);

    const createNewOne = () => {

        if(!roy || !network){
            setErr({ show: true, type: 'error', title: 'Fields with (*) are required.', message: `` });
        } else {
            const checkFind = checkRoyalty(roy);

            if(checkFind === 0){
                
                setLoading(true);

                const params = { activeWallet: activeWallet, isMeta: network?.isMeta };
                
                Promise.all([setRoyalty(roy, params)]).then((res) => {
                    if(res?.status || res[0]?.status){
                        
                        const obj = network?.isMeta ? { Eroyality: roy } : { Proyality: roy };

                        setUserRoyalty(userAddress, obj).then((r) => console.log()).catch((e) => console.log());

                        setLoading(false);
                        setErr({ show: true, type: 'success', title: 'Your Royalty Updated Successfully.', message: `` });
                        setTimeout(() => {
                            close();
                        }, 3000);
                    }
                }).catch((err) => {
                    setLoading(false);
                    setErr({ show: true, type: 'error', title: 'Your Collection Not Updated.', message: `` });                    
                });

            } else if(checkFind === 2) {
                setErr({ show: true, type: 'error', title: 'Royalty should be between 0 - 10', message: `` });                         
            } else {
                setErr({ show: true, type: 'error', title: 'Please enter integer value.', message: `` });                         
            }
        }

    };

    const checkRoyalty = (r) => {
        if(isFloat(r)){
            return 1;
        }
        else if(r === 0 || r<= 10){
            return 0;
        } else {
            return 2;
        }
    };

    return (

        <FlexColumn className="w-100 pt-3">

            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="my-auto">
                        
                    <FlexRow className="align-items-center justify-content-center flex-shrink-0">
                        <FormControl fullWidth>
                            <InputLabel htmlFor="collection-royalty">Royalty*</InputLabel>
                            <Input
                                id="collection-royalty"
                                value={roy} className="pb-2 font-14px profile-popup-fields"
                                placeholder="Royalty*" readOnly={false} type="number" min={1} max={10}
                                onChange={(e) => setRoy(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="start">
                                        <div className="font-clashRegular font-weight-bold d-flex flex-shrink-0 flex-grow-0 align-items-center">
                                            <span className="font-15px mr-2">%</span>
                                            <img alt={network?.value} src={network?.logo} width={network?.width} />
                                            <SimpleDropdownMenu chooseOption={(obj) => {
                                                    setNetwork(obj);
                                                }} 
                                                menu={networks} title={ isMobile ? "Choose Blockchain" : "Choose Your Blockchain"}
                                                selected={network} className={`font-15px`}                             
                                            />
                                            <span data-tip data-for="price-netwrok">
                                                <BsQuestionCircle />
                                                <PopOverToolTip placement={isMobile ? "left" : "top"} id="price-netwrok" />
                                            </span>
                                        </div>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </FlexRow>
                    
                </Col>
            </Row>

            <Div className="text-center d-block">
                <button onClick={createNewOne} style={{ width: '160px', height: '46px' }} disabled={loading}
                    className="text-white gradient border-0 outline-none font-16px rounded" 
                >
                    { loading ? <SpinnerLoader size="sm" /> : collection ? 'Update' : 'Set' }
                </button>
            </Div>

            <AlertPopup 
                data={{ show: err?.show, title: err.title, message: err?.message, type: err?.type }}
                handleClose={() => {
                    setErr({ ...err, show: false });
                    if(err.type === "success"){
                        close();
                    }
                }}
            />

        </FlexColumn>

    );

}
 
export default CreateCollection;