import React, { useState, useEffect } from 'react';
import './Discover.css';

import { Row, Col } from 'react-bootstrap';
import DocumentTitle from 'components/DocumentTitle/DocumentTitle';
import { getAllArtService } from "services/artService";
import LoadData from "components/LoadData/LoadData";
import { filterProfile } from 'Assets/Data';
import FlexRow from 'components/FlexRow/FlexRow';
import CheckFilter from 'components/CheckFilter/CheckFilter';
import Pagination from 'components/Pagination/Pagination';
import RenderPage from 'components/RenderPage/RenderPage';
import CombineListItem from 'components/CombineListItem/CombineListItem';
import { useHistory } from 'react-router';
import { goToSection } from 'utilities/CommonMethods';
import { SimpleDropdownMenu } from 'components/Dropdown/Dropdown';
import { GENERES } from 'Assets/Genres';
import { getArtworkByGenre } from 'services/general.service';

const Discover = (props) => {
    
    const { currentTheme: { colors, theme } } = props;

    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState({ page: 0, limit: 8 });
    const [arts, setArts] = useState([]);
    const [count, setCount] = useState(0);
    const [usd, setUsd] = useState({ bitcoin: 0, eth: 0, matic: 0  });
    const [activeFilterId, setActiveFilterId] = useState(3);
    const [price, setPrice] = useState({ min: null, max: null });
    const [genre, setGenre] = useState(undefined);

    useEffect(() => {        
        goToSection('discoverpage');
        artworkFilter(activeFilterId);
        setGenre(undefined);
    }, [page, activeFilterId]);

    useEffect(() => {
        setCount(0);
        setPage({ page: 0, limit: 8 });
    }, [activeFilterId]);

    const getArts = (page, filter) => {
        setLoading(true);
        goToSection('discoverpage');
        
        getAllArtService({ ...page }).then((res) => {
            setCount(res?.data?.count);

            if(filter){
                setArts(res?.data?.data?.filter((item) => item?.price >= filter.min && item?.price <= filter.max ));
            } else {
                setArts(res?.data?.data);
            }

            setLoading(false);
        }).catch((err) => {
            setArts([]);
            setLoading(false);
        });
    };

    const artworkFilter = (id) => {
        setLoading(true);

        const filter = { ...page };

        if(id === 1){
            filter['isAuctionOpen'] = true;
        }

        if(id === 2){
            filter['openForSale'] = true;
        }

        getAllArtService(filter).then((res) => {
            setCount(res?.data?.count);
            setArts(res?.data?.data);
            setLoading(false);
        }).catch((err) => {
            setArts([]);
            setLoading(false);
        });
    };

    const getArtworksByGenre = (value) => {
        setLoading(true);

        setActiveFilterId(0);

        getArtworkByGenre({ genre: value, page: page.page, perPage: page.perPage }).then((res) => {
            console.log(res);
            
            setArts(res.data?.artworks);
            setCount(res?.count);
            setLoading(false);
        }).catch((err) => {
            setArts([]);
            setLoading(false);
        });
    };

    const textColor = theme === 'dark' ? 'text-white' : 'text-black';

    return (

        <DocumentTitle title="Discovers">

            <RenderPage setUsd={(usd) => setUsd(usd)}
                title="Discover" className={theme === 'dark' ? 'bg-darkBlack text-white' : 'bg-white text-black'} id="discoverpage"
            >

                <Row className="padding-section pt-4">

                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-4">

                        <Row>
                            <Col xs={12} sm={12} md={6} lg={5} xl={6}>
                                <FlexRow className="align-items-center justify-content-start flex-wrap">
                                    <CheckFilter 
                                        arr={[{ id: 3, value: 'All' }].concat(filterProfile?.slice(0, 2))} onClick={(item) => setActiveFilterId(item?.id)} 
                                        className="adjustment-start text-uppercase mr-1 w-auto" activeId={activeFilterId}
                                    />                                
                                    <SimpleDropdownMenu chooseOption={(obj) => {
                                        setGenre(obj);
                                        getArtworksByGenre(obj?.value);
                                    }}
                                    menu={GENERES?.map((g, i) => { return { id: i, value: g } })} title="Choose Genre"
                                    selected={genre} className={`font-23px mx-1 ${textColor}`} minWidth />
                                </FlexRow>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={7} xl={6}>
                                <Row className="w-100">
                                    <div className='w-auto discover-price-filter'>
                                        <span className={`font-16px w-100 font-clashRegular mb-1 d-block ${textColor}`}>Filter By:</span>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="p-0 m-0"> 
                                            <FlexRow className="flex-nowrap">
                                                <span className="mr-4">Price:</span>
                                                <input type="number" value={price.min} placeholder="Min price" onChange={(e) => setPrice({ ...price, min: e.target.value })} 
                                                    className={`${textColor} bg-transparent font-clashRegular font-14px px-1 price-input-fields mr-4`}
                                                    style={{ borderBottom: theme === 'dark' ? '1px solid var(--white)' : '1px solid var(--black)' }}
                                                />
                                                <input type="number" value={price.max} placeholder="Max price" onChange={(e) => setPrice({ ...price, max: e.target.value })} 
                                                    className={`${textColor} bg-transparent font-clashRegular font-14px px-1 price-input-fields`}
                                                    style={{ borderBottom: theme === 'dark' ? '1px solid var(--white)' : '1px solid var(--black)' }}
                                                    onKeyPress={(e) => e.key === 'Enter' && getArts(page, price)}
                                                />
                                            </FlexRow>
                                        </Col>
                                    </div>
                                </Row>
                            </Col>
                        </Row>

                    </Col>

                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Row className="margin-adjustment px-3">
                            <LoadData loading={loading} arr={arts} msg={`No Discovers found.`} textColor={textColor}>
                                {
                                    arts.map((item, i) => (
                                        <Col xs={12} sm={6} md={6} lg={4} xl={3} className="mb-4" key={i}>
                                            <CombineListItem
                                                artwork_url={item?.artwork_url} artwork_type={item?.artwork_type} artworkName={item?.name} 
                                                openForSale={item?.openForSale} isAuctionOpen={item?.isAuctionOpen} artName={item?.name} 
                                                artPrice={item?.price} endTime={item?.auction?.endTime} numberOfLikes={item?.numberOfLikes} 
                                                owner={item?.owner} creater={item?.creater} item={item} usd={usd}
                                                history={history} artId={item?._id} isDark={theme === 'dark'} 
                                            />
                                        </Col>
                                    ))
                                }
                            </LoadData>
                        </Row>
                    </Col>

                </Row>

                {
                    count > 8 &&
                    <Pagination className="my-3" isDark={theme === 'dark'} textColor={textColor}
                        nextClick={() => setPage({ ...page, page: page.page + 1 })} total={count}
                        backClick={() => setPage({ ...page, page: page.page - 1 })} perPage={8} 
                        activeIndex={page.page} changePage={(i) => setPage({ ...page, page: i })} 
                    />
                }

            </RenderPage>

        </DocumentTitle>

    );

}
 
export default Discover;