import { logInUser, registerUser } from "services/authService";
import { isLoginAction } from 'redux/actions/action';

export const checkUserExist = (obj, callback) => {

  logInUser(obj).then((res) => {

    if (res.data.status) {
      callback({ status: true, data: res?.data?.user });    
    } else {
      callback({ status: false, data: undefined });
    }

  })
  .catch((err) => {
    callback({ status: false, data: undefined });
  });

};

export const handleSignIn = (obj, callback) => async dispatch => {

  await logInUser(obj).then((res) => {

    if (res.data.status) {
      setUser(res, obj.address, dispatch);
      callback({ status: true, data: res?.data?.user });
    
    } else {
      callback({ status: false, data: undefined });
    }

  })
  .catch((err) => {
    callback({ status: false, data: undefined });
  });

};

export const handleSignUp = (obj, callback) => async dispatch =>  {

  await registerUser(obj)
    .then((res) => {
            
      setUser(res, obj.address, dispatch);
      
      callback({ status: true, data: true });

    })
    .catch((err) => {
      callback({ status: false, data: false });
    });

};

export const checkConnected = (obj, callback) => async dispatch => {
  await logInUser(obj).then((res) => {

    if (res.data.status) {
      setUser(res, obj.address, dispatch);
      callback({ status: true, data: true });
    }
  });
};

const setUser = (res, address, dispatch) => {
  const { user, tokens } = res.data;

  localStorage.setItem('auth_token', tokens);  

  dispatch(isLoginAction({ info: user, token: tokens, address: address }));
};