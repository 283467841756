import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveWallet } from "../../redux/actions/action";
import { Images } from "Assets/Images";
import LayoutWrapper from "hoc/Layout/LayoutWrapper/LayoutWrapper";
import FlexRow from "components/FlexRow/FlexRow";
import {
  connectionCoinbaseWallet,
  connectionMetamaskWallet,
} from "services/contract.service";

const Wallets = (props) => {
  const dispatch = useDispatch();
  const { handleClose, setNotInstalled, findActiveAccount } = props;

  const [wallets, setWallets] = useState([
    {
      id: 1,
      name: "Metamask",
      src: Images.metamask,
      loading: false,
      key: "isMetaMask",
    },
    {
      id: 2,
      name: "Coinbase",
      src: Images.coinbase,
      loading: false,
      key: "isCoinbaseWallet",
    },
  ]);

  useEffect(() => {}, []);

  const connectWallet = async (id, index, key) => {
    connecting(index, true);

    let add;

    if (id === 1) {
      add = await connectionMetamaskWallet(key);
      dispatch(setActiveWallet("metamask"));
    } else {
      add = await connectionCoinbaseWallet(key);    
      dispatch(setActiveWallet("coinbase"));
    }

    if (add) {
      findActiveAccount(add);
    } else {
      connecting(index, false);
      setNotInstalled(id);
    }
  };

  const connecting = (index, flag) => {
    wallets[index] = { ...wallets[index], loading: flag };
    setWallets([...wallets]);
  };

  return (
    <div className="app-flex-column w-100 position-relative">
      <LayoutWrapper src={null} handleClose={() => handleClose(0, false)}>
        <div className="app-flex-column w-100 align-items-center justify-content-center py-4 px-5">
          <span className="text-black font-28px mb-5">Choose Wallet</span>

          <div
            className="w-100 app-flex-column overflow-auto"
            style={{ maxHeight: "160px" }}
          >
            {wallets.map((wallet, index) => (
              <FlexRow
                className="w-100 align-items-center justify-content-start mb-4 pb-2 text-black"
                key={index}
                style={{ borderBottom: "2px solid var(--grey)" }}
              >
                <img alt="" src={wallet.src} className="mr-2" width="30px" />
                <span className="font-20px">{wallet.name}</span>
                <button
                  className="ml-auto py-2 text-uppercase text-white font-13px bg-pink border-0 rounded-lg"
                  onClick={() => connectWallet(wallet.id, index, wallet.key)}
                  style={{ width: 120 }}
                  disabled={wallet?.loading}
                >
                  {wallet?.loading ? "connecting..." : "connect now"}
                </button>
              </FlexRow>
            ))}
          </div>
        </div>
      </LayoutWrapper>
    </div>
  );
};

export default Wallets;
