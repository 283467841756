import React, { useState, useEffect } from 'react';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Span } from 'components/Span/Span';
import AlertPopup from 'components/AlertPopup/AlertPopup';
import { placeBid } from 'services/contract.service';
import { convertToEther, convertToWei } from "utilities/dateValid";
import ArtworkType from 'components/ArtworkType/ArtworkType';
import { SpinnerLoader } from 'components/Spinner/Spinner';
import { setAuctionBidders } from 'services/general.service';
import Web3 from 'web3';
import { Images } from 'Assets/Images';
import ProcessWaiting from 'components/ProcessWaiting/ProcessWaiting';
import { useSelector } from 'react-redux';
import { commaSeparatedPrice } from 'utilities/CommonMethods';

const BidProduct = (props) => {
    
    const { activeWallet } = useSelector((state) => state);

    const { src, price, userAddress, contractAucId, type, initialPrice, usd, isMeta, refreshState, artworkId, userId, auctionId, thumbNail_url } = props;

    const [bid, setBid] = useState(0);
    const [userBalance, setUserBalance] = useState(0);
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState({ show: false, type: '', message: '', title: '' });

    useEffect(() => {
        getBalance();
        setBid(parseFloat(convertToEther(initialPrice)));
    }, []);

    const getBalance = async () => {
        if (window.ethereum) {
            if (userAddress) {
              let web3Instance = await new Web3(window.ethereum);
              let bal = await web3Instance.eth.getBalance(userAddress);
              let ether = await web3Instance.utils.fromWei(bal, "ether");
  
              setUserBalance(Number(ether).toFixed(2));
            }
        }
    };

    const placeBidOnArtwork = async () => {
        if(bid === 0){
            setErr({ show: true, type: 'error', title: 'Insufficient Balance', message: `` });
        }
        else if(userBalance < parseFloat(convertToEther(price))){
            setErr({ show: true, type: 'error', title: 'Not Enough Balance In Your Wallet', message: '' });
        } 
        else {
            if(Number(bid) > Number(convertToEther(initialPrice))){

                if(contractAucId){
                    setLoading(true);

                    let params = {
                        auctionId: contractAucId,
                        amount: convertToWei(bid),
                        isMeta, activeWallet
                    };        

                    Promise.all([placeBid(params)]).then((res) => {
                        if(res?.status || res[0]?.status){
                            setLoading(false);
                            setAuctionBidders(artworkId, { userId: userId, aucId: auctionId }).then((r) => console.log()).catch((e) => console.log());
                            setErr({ show: true, type: 'success', title: `Bid Placed Successfully`, message: `` });
                        }
                    }).catch((err) => {
                        setLoading(false);
                        setErr({ show: true, type: 'error', title: 'Bid Not Placed', message: `` });                    
                    });
                } else {
                    setErr({ show: true, type: 'error', title: 'Contract Auction Not Found.', message: `` });                    
                }

            } else {
                setErr({ show: true, type: 'error', title: `Your bid should be greater than the highest bid`, message: `` });
            }
        }
    };

    const withDollar = (isMeta) => {
        const p = !isMeta ? (convertToEther(price)*usd?.matic).toFixed(2) : (convertToEther(price)*usd?.eth).toFixed(2) ;

        return `($${ commaSeparatedPrice(p) })`;
    };

    return (

        <div className="px-2 pt-3 justify-content-center align-items-center w-100 app-flex-column" >
    
            <ArtworkType musicOriginalNFT={true} height="320px" thumbNail_url={thumbNail_url}
            srcUrl={src} type={type} name={src} className="cursor-pointer-sort rounded" />
            
            <div className="w-100 pt-4 justify-content-center app-flex-column align-items-center">

                <FormControl fullWidth>
                    <Input
                        id="standard-adornment-amount"
                        value={bid} className="pb-1 font-14px mb-2"
                        placeholder="Place your bid" type="number"
                        onChange={(e) => setBid(e.target.value)}
                        endAdornment={
                            <InputAdornment position="start">
                                <Span className="font-15px text-black">
                                    <img alt="icon" src={!isMeta ? Images.polygon : Images.ethLogo} width={ !isMeta ? "19px" : "15px" } />
                                </Span>
                                <Span className="font-15px text-black mx-2">
                                    { !isMeta ? 'MATIC' : 'ETH' }                                    
                                </Span>
                                <Span className="font-15px text-black font-weight-bold">
                                    { withDollar(isMeta) }
                                </Span>
                            </InputAdornment>
                        }
                    />
                </FormControl>

                <button onClick={() => placeBidOnArtwork()} style={{ width: '136px' }} disabled={loading}
                    className="text-white bg-lightPurple border-0 outline-none font-16px rounded py-2 mt-4" 
                >
                    { !loading ? 'Confirm' : <SpinnerLoader size="sm" /> }
                </button>

            </div>

            <AlertPopup 
                data={{ show: err?.show, title: err.title, message: err?.message, type: err?.type }}
                handleClose={() => {
                    setErr({ ...err, show: false });
                    
                    if(err.type === "success"){
                        refreshState();
                    }
                }}
            />

            <ProcessWaiting show={loading} message="Please wait while we place your bid" />

        </div>

    );

}
 
export default BidProduct;