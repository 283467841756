import React, { useEffect, useState } from 'react';

import DocumentTitle from 'components/DocumentTitle/DocumentTitle';
import RenderPage from 'components/RenderPage/RenderPage';
import { goToSection } from 'utilities/CommonMethods';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import { Col, Row } from 'react-bootstrap';
import LabelWithField from "components/LabelWithField/LabelWithField";
import { SpinnerLoader } from 'components/Spinner/Spinner';
import AlertPopup from 'components/AlertPopup/AlertPopup';
import { updatePlatformFee } from 'services/contract.service';
import { ETH_CONTRACTS, POLYGON_CONTRACTS } from 'utilities/AppEndpoint';
import { getPlatformFee, setPlatformFee } from 'services/general.service';
import { isFloat } from 'utilities/dateValid';
import { SimpleDropdownMenu } from 'components/Dropdown/Dropdown';
import { networks } from 'Assets/Data';
import { BsQuestionCircle } from 'react-icons/bs';
import { PopOverToolTip } from 'components/Popover/Popover';
import AllArtworks from './AllArtworks';
import { isMobile } from 'react-device-detect';

const Dashboard = (props) => {
    
    const { currentTheme: { theme }, address, activeWallet } = props;

    const [usd, setUsd] = useState({ bitcoin: 0, eth: 0, matic: 0  });
    const [network, setNetwork] = useState(undefined);
    const [fee, setFee] = useState(0);
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState({ show: false, type: '', message: '', title: '' });

    const adminAddress = [ETH_CONTRACTS.ADMIN_ADDRESS, POLYGON_CONTRACTS.ADMIN_ADDRESS].map(add => add?.toLowerCase());

    useEffect(() => {        
        goToSection('dashboard')
        getFee();        
    }, []);

    const getFee = () => {
        getPlatformFee(address).then((res) => {
            setFee(res?.data?.platformfee);
        }).catch((err) => console.log());
    };

    const update = () => {
        const checkFind = checkFee(fee);

        if(checkFind === 3){
            setErr({ show: true, type: 'error', title: 'Choose Blockchain network.', message: `` });
        }
        else if(checkFind === 0){
            setLoading(true);
            const params = { activeWallet: activeWallet, isMeta: network?.isMeta };

            Promise.all([updatePlatformFee(parseInt(fee), params)]).then((res) => {
                
                setPlatformFee(address, { platFormFee: fee }).then((r) => console.log()).catch((e) => console.log());

                if(res?.status || res[0]?.status){
                    setLoading(false);
                    setErr({ show: true, type: 'success', title: 'Your Platform Fee Updated Successfully.', message: `` });
                }
            }).catch((err) => {
                setLoading(false);                
                setErr({ show: true, type: 'error', title: 'Your Platform Fee Not Updated.', message: `` });
            });
        } else if(checkFind === 2) {
            setErr({ show: true, type: 'error', title: 'Platform Fee should be between 1 - 15', message: `` });                         
        } else {
            setErr({ show: true, type: 'error', title: 'Please enter integer value.', message: `` });                         
        }
    };

    const checkFee = (r) => {
        if(!network){
            return 3;
        }
        else if(isFloat(r)){
            return 1;
        }
        else if(r > 0 && r<= 15){
            return 0;
        } else {
            return 2;
        }
    };

    return (

        <DocumentTitle title="Dashboard">

            <RenderPage title="Platform Fees" className={theme === 'dark' ? 'bg-darkBlack text-white' : 'bg-white text-black'} src={null} id="dashboard"
                setUsd={(usd) => setUsd(usd)}
            >
                
                { (address && adminAddress.includes(address.toLowerCase())) ?

                    <div className="w-100">
                        <Row className="padding-section py-0 mb-4">
                            <Col xs={12} sm={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} xl={{ span: 6, offset: 3 }}>
                                <FlexColumn className="app-flex-column align-items-center justify-content-end position-relative" style={{ height: '140px' }}>
                                    <LabelWithField                     
                                        placeholder="Platform Fee" value={fee} type="number" min="0" step="1"
                                        className="w-100 mb-3" name={fee} disable={false}
                                        onChange={(e) => setFee(e.target.value)} readOnly={false}
                                        fieldClassName="px-3 custom-input-field bg-grey-40 font-14px text-black"
                                        onKeyPress={() => console.log()}
                                    /> <br />
                                    <div className="position-absolute font-clashRegular font-weight-bold d-flex flex-shrink-0 flex-grow-0 align-items-center" style={{ right: 18, top: 10 }}>
                                        <span className="font-18px mr-3">%</span>
                                        <img alt={network?.value} src={network?.logo} width={network?.width} />
                                        <SimpleDropdownMenu chooseOption={(obj) => {
                                                setNetwork(obj);
                                            }} 
                                            menu={networks} title={isMobile ? "Choose Blockchain" : "Choose Your Blockchain"}
                                            selected={network} className={`font-15px`}                             
                                        />
                                        <span data-tip data-for="price-netwrok">
                                            <BsQuestionCircle />
                                            <PopOverToolTip placement={isMobile ? "left" : "top"} id="price-netwrok" />
                                        </span>
                                    </div>
                                    <button  
                                        onClick={update}
                                        className='bg-pink rounded w-auto border-0 text-white hover font-16px' style={{ height: '44px', minWidth: '160px' }}
                                    >
                                        { loading ? <SpinnerLoader size="sm" color="white" /> : 'Update' }
                                    </button>
                                </FlexColumn>
                            </Col>
                            <Col xs={12} sm={12} className="mt-5">
                                <h4 className="font-clashRegular text-center mb-4">All NFT's</h4>
                                <AllArtworks theme={theme} usd={usd} />
                            </Col>
                        </Row>
                    </div> :
                    <Row className="padding-section py-0 mb-4">
                        <span className="text-center w-100">Permission denied.</span>
                    </Row>
                }

                <AlertPopup 
                    data={{ show: err?.show, title: err.title, message: err?.message, type: err?.type }}
                    handleClose={() => setErr({ ...err, show: false })}
                />
                
            </RenderPage>

        </DocumentTitle>

    );

}
 
export default Dashboard;