import {
  CONNECT_META_MASK,
  IS_LOGIN,
  IS_LOGOUT,
  CHANGE_THEME,
  IS_PROFILE_ALBUM,
  CHANGE_WALLET,
  UPDATE_PROFILE,
  SET_USER_BALANCE,
  IS_NOTIFICATIONS,
  SET_ACTIVE_WALLET,
} from "../types/types";

export const connectMetaMaskAction = (data) => ({
  type: CONNECT_META_MASK,
  payload: data,
});

export const isUpdateUserProfile = (data) => ({
  type: UPDATE_PROFILE,
  payload: data,
});

export const isChangeMetaMaskAccount = (data) => ({
  type: CHANGE_WALLET,
  payload: data,
});

export const isChangeTheme = (theme) => ({
  type: CHANGE_THEME,
  payload: theme,
});

export const isLoginAction = (data) => ({
  type: IS_LOGIN,
  payload: data,
});

export const isgetNoticationsAction = (data) => ({
  type: IS_NOTIFICATIONS,
  payload: data,
});

export const isSetBalance = (data) => ({
  type: SET_USER_BALANCE,
  payload: data,
});

export const isLogoutAction = () => ({
  type: IS_LOGOUT,
  payload: null,
});

export const isProfileAlbumOpen = (flag) => ({
  type: IS_PROFILE_ALBUM,
  payload: flag,
});
export const setActiveWallet = (flag) => ({
  type: SET_ACTIVE_WALLET,
  payload: flag,
});
