import { HTTP_CLIENT } from 'utilities/intercepter';

export const getActivities = (params) => {
  return HTTP_CLIENT.get(
    `general/getAppActivity?page=${params.page}&perPage=${params.perPage}`
  );
};

export const getArtworkByGenre = (params) => {
  let url = `artwork/getArtworkByGenre?genre=${params.genre}&page=${params.page}&perPage=${params.perPage}`;

  if(params?.keyword){
    url = url + `&keyword=${params.keyword}`;
  }

  return HTTP_CLIENT.get(url);
}

export const getTopArtists = () => {
  return HTTP_CLIENT.get(`general/getTranscendingArtists`);
};

export const handleSearch = (params, obj) => {
  return HTTP_CLIENT.get(
    `general/search?page=${obj.page}&perPage=${obj.perPage}${
      Object.keys(params).length > 0
        ? `${params.keyword ? `&keyword=${params.keyword}` : ""}${
            params.filter && `&filter=${params.filter}`
          }`
        : ""
    } `
  );
};

export const getAllArtworksForAdmin = (obj) => {
  return HTTP_CLIENT.get(`artwork/getArtworkForAdmin?page=${obj?.page}&perPage=${obj?.limit}`);
};

export const getAllNotification = (obj) => {
  let link = `general/getNotifications`;

  if(obj){
    link = `general/getNotifications?page=${obj.pageDet}&perPage=${obj.limit}`;
  }

  return HTTP_CLIENT.get(link);
};

export const getPlatformFee = (address) => {
  return HTTP_CLIENT.get(`users/getPlatFormFee/?address=${address}`);
};

export const setPlatformFee = (address, obj) => {
  let FormBody = new FormData();
  if (Object.keys(obj).length > 0) {
    Object.keys(obj).forEach((res) => {
      FormBody.append(`${res}`, obj[res]);
    });
    return HTTP_CLIENT.post(`users/setPlatFormFee/?address=${address}`, FormBody);
  }
};

export const getUserRoyalty = (address, params) => {
  return HTTP_CLIENT.get(`users/getRoyality/?address=${address}`);
};

export const setUserRoyalty = (address, obj) => {
  let FormBody = new FormData();
  if (Object.keys(obj).length > 0) {
    Object.keys(obj).forEach((res) => {
      FormBody.append(`${res}`, obj[res]);
    });
    return HTTP_CLIENT.post(`users/setRoyality/?address=${address}`, FormBody);
  }
};

export const setAuctionBidders = (artworkId, obj) => {

  let FormBody = new FormData();
  if (Object.keys(obj).length > 0) {
    Object.keys(obj).forEach((res) => {
      FormBody.append(`${res}`, obj[res]);
    });
    return HTTP_CLIENT.post(`auction/setAuctionBidders/?artworkId=${artworkId}`, FormBody);
  }
};

export const getCoinsRates = (callback) => {
  fetch('https://min-api.cryptocompare.com/data/pricemultifull?fsyms=ETH&tsyms=USD,EUR,GBP')
  .then((res) => res.json())
  .then((res2) => {

    callback({ status: true, data: res2.DISPLAY.ETH });

  }).catch((err) => {

    callback({ status: false, data: undefined });

  });
}

export const getRequiredCoinRates = (callback) => {
  fetch('https://api.coingecko.com/api/v3/simple/price?ids=bitcoin%2Cethereum%2Cmatic-network&vs_currencies=usd')
  .then((res) => res.json())
  .then((res2) => {

    const { bitcoin, ethereum } = res2;

    callback({ status: true, data: { bitcoin: bitcoin?.usd, eth: ethereum?.usd, matic: res2['matic-network']?.usd  } });

  }).catch((err) => {

    callback({ status: false, data: { bitcoin: 0, eth: 0, matic: 0  } });

  });
}