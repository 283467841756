import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import AlertPopup from 'components/AlertPopup/AlertPopup';
import { deleteACollection } from 'services';
import { updateUserObj } from 'redux/thunk/thunk';
import { useDispatch } from 'react-redux';
import { Images } from 'Assets/Images';

const ArtistCollectionItem = (props) => {
    
    const dispatch = useDispatch();

    const { item, onClick, close, userId } = props;
    const [err, setErr] = useState({ show: false, type: '', message: '', title: '' });
    const [loading, setLoading] = useState(false);

    const openDialog = () => {
        setErr({ show: true, type: 'error', message: '', title: 'Are you sure you want to remove this collection' });
    };

    const removeCollection = () => {
        setLoading(true);

        setTimeout(() => {
            deleteACollection({ "collectionId": item?._id }).then((res) => {
                setLoading(false);
                dispatch(updateUserObj(userId));
                setErr({ show: false, type: '', message: '', title: '' });
                close();
            }).catch((err) => {
                setLoading(false);
                setErr({ show: true, type: 'error', message: err?.response?.data?.message, title: 'Network Error' });
            });
        }, 1000);
    };

    const url = item?.symbol?.toLowerCase() === 'video' ? Images.collectionsImages[2] :
    item?.symbol?.toLowerCase() === 'music' ? Images.collectionsImages[0] :
    item?.symbol?.toLowerCase() === 'gif' ? Images.collectionsImages[3] : Images.collectionsImages[1]

    return (

        <Col xs={12} sm={12} md={6} lg={4} xl={3} className="mb-4 position-relative">
            <div className="align-items-center justify-content-center rounded cursor-pointer-sort app-flex-column w-100 h-auto" 
                onClick={() => onClick()}
            >

                <img alt="collection-image" width="100%" src={url} />

            </div>

            {/* { !item?.artworks?.length ?
            <span
                onClick={(e) => {
                    e.stopPropagation();
                    openDialog();
                }} 
                className="cursor-pointer-sort position-absolute py-1 px-3 text-black border border-black rounded-pill" 
                style={{ right: 24, bottom: '10px', fontSize: '9px' }}
            >
                Remove
            </span> : null } */}

            <AlertPopup 
                data={{ show: err?.show, title: err.title, message: err?.message, type: err?.type }}
                handleClose={() => removeCollection()} loading={loading}
                showCancel={() => setErr({ ...err, show: false })}
            />

        </Col>

    );

}
 
export default ArtistCollectionItem;