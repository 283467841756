import { Routes } from "Routes/Routes";

export const dataLists = [
    { id: 1, title: `We are committed to respect and protect the privacy of the personal information we obtain from you through the Service, and we have created this Privacy Policy to inform you about how we collect, use and share your personal information, and about our privacy practices in general.`, text: undefined, showNumber: undefined, subList: [] },
    { id: 2, title: `We continually strive to find new ways to enhance your experience with the Service and we may modify this Privacy Policy from time to time to reflect changes in our privacy practices.  You are encouraged to review this Privacy Policy periodically and to check the “Last Updated” date at the top of the Privacy Policy for the most recent version.`, 
        text: undefined, showNumber: undefined, subList: [] 
    },
    { id: 3, title: `Terms of Service.`, inline: true, anchor: `<a style="color: inherit" alt="TOS" href=${Routes.terms} target="_blank">Terms of Service</a>`, split: 'which', index: 0,
        text: `Use of the Service is subject to our which is hereby incorporated and made part of our Site disclosures which shall include this Privacy Policy the Terms of Service and any other disclosures posted to the Site (collectively the “Site Disclosures”). By using the Service, you agree to be bound by our Site Disclosures.`, showNumber: undefined, subList: [] 
    },
    { id: 4, title: `Age.`, inline: true, anchor: undefined, split: undefined, index: 0,
        text: `The Service is not directed to children under the age of eighteen (18) or the age of majority where you reside, whichever is younger. If you become aware that your child has provided us with personal information, please contact us at the email address listed below. If we become aware that a child under this age has provided us with personal information, we will take steps to remove such information and terminate the child’s account.`, showNumber: undefined, subList: [] 
    },

    { id: 5, title: 'Translation.', text: undefined, showNumber: undefined, subList: [], head: true },

    { id: 6, title: `We may translate this Privacy Policy into other languages for your convenience. Nevertheless, the English version governs your relationship with Company, and any inconsistencies among the different versions will be resolved in favor of the English version.`, 
    text: undefined, showNumber: undefined, subList: [] },

    { id: 7, title: 'Application of this Privacy Policy.', text: undefined, showNumber: undefined, subList: [], head: true },
    { id: 8, title: `This policy applies where we are acting as a data controller with respect to your personal data, in other words, where we determine the purposes and means of the processing of that personal data.`, text: undefined, showNumber: undefined, subList: [] },
    { id: 9, title: `This policy applies where we are collecting Personally Identifiable Information (as further described below) from individuals and / or sole proprietors and does not apply to the collection of data from corporate entities.  This policy applies however we collect Personally Identifiable Information, however accessed and / or used, whether via personal computers, mobile devices or otherwise.`, text: undefined, showNumber: undefined, subList: [] },
    { id: 10, title: `In the event while using the Service you may be directed to other websites that are operated and controlled by third parties that are beyond our control. This policy does not apply to the privacy practices of these third-party websites.`, text: undefined, showNumber: undefined, subList: [] },

    { id: 11, title: 'Collecting Personal Information and How We Use It.', text: undefined, showNumber: undefined, subList: [], head: true },

    { id: 12, title: `Personally Identifiable Information.`, inline: true, anchor: undefined, split: undefined, index: 0,
        text: `When you engage in certain activities via the Service you may provide information to us which may include personal information, which is information that may reasonably be used to identify you, such as your name, address, e-mail address, telephone number, and if applicable, cryptocurrency wallets addresses, credit card number, images you upload and other content you upload or provide to us. We may also collect information available on the web.  We may use or share Personally Identifiable Information to provide products and/or services to you, to enable third-parties to provide products and/or services to you, to enhance the operation of the Service, improve our marketing and promotional efforts, analyze use of the Service, and tailor your experience with third parties as provided below in this Privacy Policy. We may also use Personally Identifiable Information to troubleshoot, resolve disputes, accomplish administrative tasks, contact you, enforce our agreements with you, including our Terms and Conditions and this Privacy Policy, and to comply with applicable laws and in cooperation with law enforcement activities. The legal basis for this processing is your consent or our legitimate interests, namely monitoring and improving our website and service.`, 
        showNumber: undefined, subList: [] 
    },

    { id: 13, title: `How we collect Personally Identifiable Information.`, smallBold: true, text: undefined, showNumber: undefined, subList: [
            { id: 13.1, topTitle: '', title: '', text: `We may process your account data ("account data").  The account data may include your passWord and/or User ID, name, telephone number, street address, email address, and other Personally Identifiable Information. The source of the account data is you. The account data may be processed for the purposes of operating our Website, providing our Service, ensuring the security of our Website and services, maintaining back-ups of our databases and communicating with you. The legal basis for this processing is your consent or our legitimate interests, namely monitoring and improving our website and services.`, showNumber: 1 },
            { id: 13.2, topTitle: '', title: '', text: `we may process your information included in your personal profile on our website ("profile data").  The profile data may include your name, address, telephone number, email address, and profile pictures.  The profile data may be processed for the purposes of enabling and monitoring your use of our website and services. The legal basis for this processing is your consent or our legitimate interests, namely monitoring and improving our website and services.`, showNumber: 1 },
            { id: 13.3, topTitle: '', title: '', text: `we may process data about your use of our website and services ("usage data"). The usage data may include your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths, as well as information about the timing, frequency and pattern of your website and services usage. The source of the usage data is our analytics tracking system. This usage data may be processed for the purposes of analyzing the use of the website and Service. The legal basis for this processing is your consent or our legitimate interests, namely monitoring and improving our website and service.`, showNumber: 1 },
            { id: 13.4, topTitle: '', title: '', text: `we may process information contained in or relating to any communication that you send to us ("correspondence data"). The correspondence data may include the communication content and metadata associated with the communication.  Our website will generate the metadata associated with communications made using website contact forms. The correspondence data may be processed for the purposes of communicating with you and record-keeping. The legal basis for this processing is your consent or our legitimate interests, namely monitoring and improving our website and services.`, showNumber: 1 },
            { id: 13.5, topTitle: '', title: '', text: `we may process information contained in any inquiry you submit to us regarding goods and/or services ("inquiry data"). The inquiry data may be processed for the purposes of offering, marketing and selling relevant goods and/or services to you.`, showNumber: 1 },
            { id: 13.6, topTitle: '', title: '', text: `we may process information that you provide to us for the purpose of subscribing to our email notifications and/or newsletters ("notification data"). When you sign up for an account or use certain features, you may be opting to receive messages and/or phone calls from us, other users of the Service and/or third-party Providers.  You cannot opt-out of Administrative Emails. “Administrative Emails” relate to your activity on the Company Service and include but are not limited to emails regarding your account, requests or inquiries, and purchases of products and services, if applicable.  If you do not want to receive promotional emails from us, you may elect to opt-out of receiving promotional emails at any time after registering, by e-mailing us at the email listed at the end of this Privacy Policy or by hitting the “unsubscribe” button at the bottom of any of our e-mails.`, showNumber: 1 },
            { id: 13.7, topTitle: '', title: '', text: `we may process information relating to transactions, including purchases of goods and services, that you enter into with us or third-party Providers ("transaction data"). The transaction data may include first and last names, an email address to be used as a login, billing contact information, cryptocurrency wallet addresses, credit card number and associated credit card data, and a password. The transaction data may be processed for the purpose of supplying the purchased goods and services and keeping proper records of those transactions. The legal basis for this processing is the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract and our legitimate interests.`, showNumber: 1 },
            { id: 13.8, topTitle: '', title: '', text: `if you exchange messages with others through the Service, we may store them to process and deliver them, allow you to manage them, and we may review and disclose them in connection with investigations related to the operation and use of the Service. We may choose to not deliver messages that we believe are objectionable, such as spam messages or requests to exchange reviews for compensation.  If you send or receive messages through the Service via SMS text message, we may log phone numbers, phone carriers, and the date and time that the messages were processed. Carriers may charge recipients for texts that they receive. We may also store information that you provide through communications to us, including from phone calls, letters, emails and other electronic messages, or in person. If you are a representative of a business listed on the Service, we may contact you, including by phone or email, using the contact information you provide us, make publicly available, or that we have on record for your business.`, showNumber: 1 },
            { id: 13.9, topTitle: '', title: '', text: `if you enroll in the Service through a third party, such as Facebook or Google, or another party, then we may receive Personally Identifiable Information from such third party and by using the Service, you consent to our receipt and sharing of Personally Identifiable Information and its use pursuant to this Privacy Policy. For example, some social networking services allow you to push content from our Service to your contacts or to pull information about your contacts so you can connect with them on or through our Service. Some social networking services also will facilitate your registration for our Service or enhance or personalize your experience on our Service. Your decision to use a social networking service or other third-party technology in connection with our Service is voluntary. We reserve the right to use, transfer, assign, share, and provide access to all Personally Identifiable Information and other information about you and your computer, mobile or other device that we receive through third-party social networking services in the same ways described in this Privacy Policy as all of your other information.`, showNumber: 1 },
            { id: 13.10, topTitle: '', title: '', text: `we may process any of your personal data identified in this policy where necessary for the establishment, exercise or defense of legal claims, whether in court proceedings or in an administrative or out-of-court procedure. The legal basis for this processing is our legitimate interests, namely the protection and assertion of our legal rights, your legal rights and the legal rights of others.`, showNumber: 1 },
            { id: 13.11, topTitle: '', title: '', text: `we may process your personal data where such processing is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.`, showNumber: 1 },
        ] 
    },

    { id: 14, title: 'Providing your personal data to others.', text: undefined, showNumber: undefined, subList: [], head: true },
    { id: 15, title: `Except as stated in this Section we do not sell or share your Personally Identifiable Information.`, text: undefined, showNumber: undefined, subList: [] },
    { id: 16, title: `We may disclose your personal information to any member of our group, which means our subsidiaries, affiliates or holding company, if any.`, text: undefined, showNumber: undefined, subList: [] },
    { id: 17, title: `We employ third parties to perform functions on our behalf such as hosting or operating the Site, processing and/or fulfilling orders for products purchased through the Service, sending e-mail and/or other communications, maintaining customer and user information and databases, and credit card payment processing. These third parties may have access to your personal information in connection with performing these functions.`, text: undefined, showNumber: undefined, subList: [] },
    { id: 18, title: `In addition, as our business changes, we may buy or sell various assets. In the event all or a portion of the assets owned or controlled by us, our parent or any subsidiary or affiliated entity are sold, assigned, transferred or acquired by another company, the information from and/or about our Service users may be among the transferred assets.`, text: undefined, showNumber: undefined, subList: [] },
    { id: 19, title: `We may disclose your personal data where such disclosure is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person. We may also disclose your personal data where such disclosure is necessary for the establishment, exercise or defense of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.`, text: undefined, showNumber: undefined, subList: [] },

    { id: 21, title: `Non-Personally Identifiable Information.`, inline: true, anchor: undefined, split: undefined, index: 0,
        text: `Non-Personally Identifiable Information is information that does not identify a specific person. This type of information may include things like the Uniform Resource Locator (“URL”) of the website you visited before coming to the Site or otherwise participating in the Service, the URL of the website you visit after leaving the Site, the type of browser you are using, your Internet Protocol (“IP”) address, mobile carrier information, mobile device information, or general and/or aggregated location data that does constitute Personally Identifiable Information. We, and/or our authorized third parties may automatically collect this information when you visit or use the Service using electronic tools like Cookies and Web beacons or Pixel tags, as described below in this Privacy Policy.  We use Non-Personally Identifiable Information to troubleshoot, administer the Services, analysis trends, gather demographic information, comply with applicable law, and cooperate with law enforcement activities. We reserve the right to use, transfer, sell, and share aggregated, anonymous data about our users as a group for any lawful business purpose, such as analyzing usage trends and seeking compatible advertisers, sponsors, clients and customers.`, showNumber: undefined, subList: [] 
    },

    { id: 22, title: 'Release of Non-Personally Identifiable Information.', text: undefined, showNumber: undefined, subList: [], head: true },
    { id: 23, title: `We may disclose or share Non-Personally Identifiable Information with Third Party Providers and the public. For example, we may share aggregated demographic information (which does not include any Personally Identifiable Information) or use Third Party Providers to track and analyze Non-Personally Identifiable usage and volume statistical information from our users to administer the Service. We may also publish this aggregated information for promotional purposes. Such data is collected on our behalf and is owned and used by us.`, text: undefined, showNumber: undefined, subList: [] },
    { id: 24, title: undefined, inline: true, anchor: `<a style="color: inherit" alt="TOS" href="http://www.aboutads.info." target="_blank"> http://www.aboutads.info.</a>`, split: 'https', index: 0,
        text: `We may use Third Party Providers to serve ads when you participate in the Service. These companies may use Non-Personally Identifiable Information about your visits and use of the Service, and visits to other websites or locations to provide, through the use of network tags, advertisements about goods and services that may be of interest to you.  Our advertising service vendors and other Third Parties will at times also use tracking technologies to serve you advertisements tailored to interests you have shown by browsing on this Service and other sites, applications, destinations, and services you have visited, and to determine whether you have seen a particular advertisement before to avoid sending you duplicate advertisements, and for other lawful business purposes. In doing so, these Third Parties may collect non-personally identifiable data including for example the make, model, settings, specifications (e.g., CPU speed, connection speed, browser type, operating system, device identifier) and geographic location of your computer, mobile or other device, as well as date/time stamp, IP address, pages visited, time of visits, content viewed, ads viewed, the site(s), application(s), destination(s), and/or service(s) you arrived from, and other clickstream data. The use of tracking technologies by Third Parties is subject to their own privacy policies, not this Privacy Policy, and we have no responsibility or liability in connection therewith. If you do not want the services that tracking technologies provide, you may be able to opt-out by visiting `, showNumber: undefined, subList: [] 
    },

    { id: 25, title: `Choices on Collection/Use of Information.`, inline: true, anchor: undefined, split: undefined, index: 0,
        text: `You can always choose not to provide certain information, although a certain level of information is required to engage and participate in the Service.`, showNumber: undefined, subList: [] 
    },

    { id: 26, title: 'About Cookies.', text: undefined, showNumber: undefined, subList: [], head: true },
    { id: 27, title: `A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server.`, text: undefined, showNumber: undefined, subList: [] },
    { id: 28, title: `Cookies may be either "persistent" cookies or "session" cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.`, text: undefined, showNumber: undefined, subList: [] },
    { id: 29, title: `Cookies do not typically contain any information that personally identifies a user, but personal information that we store about you may be linked to the information stored in and obtained from cookies.`, text: undefined, showNumber: undefined, subList: [] },

    { id: 30, title: `We use cookies for the following purposes:`, smallBold: true, text: undefined, showNumber: undefined, subList: [
        { id: 30.1, topTitle: '', title: '', text: `authentication - we use cookies to identify you when you visit our website and as you navigate our website.`, showNumber: 1 },
        { id: 30.2, topTitle: '', title: '', text: `status - we use cookies to help us to determine if you are logged into our website.`, showNumber: 1 },
        { id: 30.3, topTitle: '', title: '', text: `security - we use cookies as an element of the security measures to protect our website and services generally.`, showNumber: 1 },
        { id: 30.4, topTitle: '', title: '', text: `advertising - we use cookies to help us to display advertisements that may be relevant to you.`, showNumber: 1 },
        { id: 30.5, topTitle: '', title: '', text: `analysis - we use cookies to help us to analyze the use and performance of our website and services; and`, showNumber: 1 },
        { id: 30.6, topTitle: '', title: '', text: `cookie consent - we use cookies to store your preferences in relation to the use of cookies more generally.`, showNumber: 1 },
    ]},

    { id: 31, title: 'Cookies used by our service providers and/or affiliates.', text: undefined, showNumber: undefined, subList: [], head: true },
    { id: 32, title: `Our service providers, affiliates and partners use cookies, and those cookies may be stored on your computer when you visit our website.`, text: undefined, showNumber: undefined, subList: [] },
    { id: 33, title: undefined, inline: true, anchor: `<a style="color: inherit" alt="TOS" href="https://www.google.com/policies/privacy/" target="_blank"> https://www.google.com/policies/privacy/. </a>`, split: 'https', index: 0,
        text: `We may use Google Analytics or similar tools to analyze the use of our website. Analytics providers gather information about website use by means of cookies. The information gathered relating to our website is used to create reports about the use of our website. Google's privacy policy is available at:`, showNumber: undefined, subList: [] 
    },

    { id: 34, title: 'Managing cookies.', text: undefined, showNumber: undefined, subList: [], head: true },
    { id: 35, title: `Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser, and from version to version. You can however obtain up-to-date information about blocking and deleting cookies via these links:`, smallBold: false, text: undefined, showNumber: undefined, subList: [
        { id: 35.1, title: 'Chrome: ', inline: true,
            anchor: `<a style="color: inherit" alt="TOS" href="https://www.google.com/policies/privacy/" target="_blank"> https://www.google.com/policies/privacy/. </a>`, split: 'https', index: 0,
            text: ``, showNumber: undefined, subList: [] 
        },
        { id: 35.2, title: 'Firefox: ', inline: true,
            anchor: `<a style="color: inherit" alt="TOS" href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank"> https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences. </a>`, split: 'https', index: 0,
            text: ``, showNumber: undefined, subList: [] 
        },
        { id: 35.3, title: 'Opera: ', inline: true,
            anchor: `<a style="color: inherit" alt="TOS" href="http://www.opera.com/help/tutorials/security/cookies/" target="_blank"> http://www.opera.com/help/tutorials/security/cookies/. </a>`, split: 'https', index: 0,
            text: ``, showNumber: undefined, subList: [] 
        },
        { id: 35.4, title: 'Internet Explorer: ', inline: true,
            anchor: `<a style="color: inherit" alt="TOS" href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank"> https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies. </a>`, split: 'https', index: 0,
            text: ``, showNumber: undefined, subList: [] 
        },
        { id: 35.5, title: 'Microsoft Edge: ', inline: true,
            anchor: `<a style="color: inherit" alt="TOS" href="https://support.microsoft.com/en-us/search?query=enable%20cookies%20in%20edge" target="_blank"> https://support.microsoft.com/en-us/search?query=enable%20cookies%20in%20edge. </a>`, split: 'https', index: 0,
            text: ``, showNumber: undefined, subList: [] 
        },
        { id: 35.6, title: 'Apple: ', inline: true,
            anchor: `<a style="color: inherit" alt="TOS" href="https://www.apple.com/legal/privacy/en-ww/" target="_blank"> https://www.apple.com/legal/privacy/en-ww/. </a>`, split: 'https', index: 0,
            text: ``, showNumber: undefined, subList: [] 
        }
    ]},

    { id: 36, title: 'Local Device Storage and other Tracking Technologies; Do Not Track (DNT):', text: undefined, showNumber: undefined, subList: [], head: true },
    { id: 37, title: `The Service will at times place and/or store code or other types of information and/or devices (e.g., “cookies”) on your computer, mobile or other device (“Local Device Storage”). We may use Local Device Storage for any lawful business purpose, including without limitation to determine which of our messages have been opened by recipients so we can gauge the effectiveness of marketing campaigns, to control the display of ads, to track usage patterns, the movements of individual users, and your geographic location, to help diagnose problems with our servers, to gather broad demographic information, to analyze trends, to conduct research, to deliver editorial content, to record registration and personalization information, and to otherwise administer the Service. For example, if you register on any part of the Service and are given the option to save your user name and password, we may provide this convenience to you via Local Device Storage. Local Device Storage may also collect and store your personally identifiable information, which may be shared with our parent, subsidiaries, and affiliates and other companies.`, text: undefined, showNumber: undefined, subList: [] },
    { id: 38, title: `If you do not want Local Device Storage, your computer, mobile or other device may include an option that allows you to not accept it. However, if you disable Local Device Storage, some portions of the Service may not function properly.`, text: undefined, showNumber: undefined, subList: [] },

    { id: 39, title: 'Analytics.', text: undefined, showNumber: undefined, subList: [], head: true },
    { id: 40, title: `We continuously improve our websites and our products, and we utilize different third-party web analytics tools to help us do so. We are interested in how visitors use our websites, our desktop tools, mobile applications, or our API, what they like and dislike, and where they have problems. Our products, desktop tools, mobile applications and API use analytical tools to understand feature usage patterns, to enhance and improve your product experience and offer you specific usage tips and guidance. In connection with normal business operations, as well as to increase functionality and enhance your user experience, this usage information may be linked to personal information that we have about individual users. We maintain ownership of this data and we do not share this type of data about individual users with third parties.`, text: undefined, showNumber: undefined, subList: [] },
    { id: 41, title: `This information is used solely to assist us in maintaining a more effective and useful websites and products for our customers. This data will not be shared with third parties without your prior consent or unless required by law.`, text: undefined, showNumber: undefined, subList: [] },

    { id: 42, title: 'Consent to Transfer of Information.', text: undefined, showNumber: undefined, subList: [], head: true },
    { id: 43, title: `Your information may be transferred to, and maintained on, servers and databases located outside of your state, province, country or other governmental jurisdiction where the privacy laws may not be as protective as your jurisdiction. Please be advised that we may transfer your information to and from any state, province, country or other governmental jurisdiction, and process it elsewhere. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to any such transfer.`, text: undefined, showNumber: undefined, subList: [] },

    { id: 44, title: 'Security of Information.', text: undefined, showNumber: undefined, subList: [], head: true },
    { id: 45, title: `In the event you are provided with an opportunity to establish and account or profile on our Site, you may be able to access your Personally Identifiable Information via the Service with your password and username. This password is encrypted. We advise against sharing your password with anyone.  If you access your account via a third-party site or service, you may have additional or different sign-in protections via that third-party site or service. You need to prevent unauthorized access to your account and Personal Information by selecting and protecting your password and/or other sign-in mechanism appropriately and limiting access to your computer, browser, or mobile device by signing off after you have finished accessing your account. Unauthorized entry or use, hardware or software failure, and other factors, may compromise the security of user information at any time. If we believe that the security of your information may have been compromised, we may seek to notify you of that development.`, text: undefined, showNumber: undefined, subList: [] },
    { id: 46, title: `We are committed to taking all reasonable precautions to safeguard the privacy of personal information which has been provided by its users. We use technical, contractual, administrative and physical security steps to protect your personal information. Security measures such as restricted access and the use of passwords and encryption have been adopted to protect your personal information against loss or theft, as well as unauthorized access, disclosure, copying, use or modification. Our employees have been trained to respect your privacy at all times and those employees with access to your personal information shall use your personal information strictly in accordance with this Privacy Policy. We will protect your credit card information by using industry standard encryption technology.`, text: undefined, showNumber: undefined, subList: [] },
    { id: 47, title: `No data transmission over the Internet or via e-mail is completely secure and therefore we cannot guarantee protection of all personal information in all cases. For example, we cannot guarantee protection against interception, misappropriation, misuse, or alteration, or that your information will not be disclosed or accessed by the unauthorized acts of others. Consequently, we cannot ensure or warrant the security of any information you transmit to us, and you do so at your own risk. If you provide us with your credit card number, you should not send it electronically unless the email is encrypted, or your browser indicates that the access to our website is secure. Materials posted to online forums such as bulletin boards or chat rooms are public, not secure, and may be viewed by anyone. Any personal information you post may be collected and used by anyone and may result in unsolicited messages from other parties.`, text: undefined, showNumber: undefined, subList: [] },

    { id: 48, title: 'Privacy Notice for California Residents.', text: undefined, showNumber: undefined, subList: [], head: true },
    { id: 49, title: `California law requires that we provide you with a summary of your privacy rights under the California Online Privacy Protection Act (“COPPA”) and the California Business and Professions Code. We will never knowingly request personally identifiable information from anyone under the age of 13 without prior verifiable parental consent. If we become aware that a child under 13 has provided us with personally identifiable information without verifiable parental consent, we will use our best efforts to remove such information from our files. If a parent or guardian becomes aware that his or her child has provided us with personally identifiable information without verifiable parental consent, he or she should contact us at the email address below.`, text: undefined, showNumber: undefined, subList: [] },
    { id: 50, title: `Other California Privacy Rights.  California’s “Shine the Light” law (Civil Code Section § 1798.83) permits users of our Site that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please contact us via phone, email, or write to us as provided below in our Contact Us Section.`, text: undefined, showNumber: undefined, subList: [] },

    { id: 51, title: 'Nevada Privacy Rights.', text: undefined, showNumber: undefined, subList: [], head: true },
    { id: 52, title: `Nevada law (SB 220) permits customers in Nevada to opt-out of the sale of certain kinds of personal information. As previously mentioned above, we do not sell your personal information to third parties. If you are a Nevada resident and have questions, please contact us via phone, email, or write to us as provided below in our Contact Us Section.`, text: undefined, showNumber: undefined, subList: [] },

    { id: 53, title: `Customer Service.`, inline: true, anchor: undefined, split: undefined, index: 0,
        text: `If you have any comments or questions regarding this Privacy Policy or wish to report any violation of this Privacy Policy, you may contact us at the address below.`, showNumber: undefined, subList: [] 
    },

    { id: 54, title: `Email:`, inline: true, anchor: `<a style="color: inherit" alt="TOS" href="support@marvmentnft.com" target="_blank">support@marvmentnft.com</a>`, split: 'which', index: 0,
        text: ``, showNumber: undefined, subList: [] 
    }

];