export const AUCTION_MINT_STATUS = {
    PENDING: "pending",
    COMPLETE: "complete",
  };
  
  export const SEARCH_FILTERS = {
    USERS: "users",
    ARTWORKS: "artworks",
    COLLECTIONS: "collections",
    VIDEOS: "videos",
    AUDIO: "album",
  };
  
  export const HISTORY_TYPE = {
    ARTWORK_CREATED: "artworkCreated",
    ARTWORK_DELETED: "artworkDeleted",
    AUCTION_STARTED: "auctionStarted",
    AUCTION_END: "auctionEnd",
    BID_PLACED: "bidPlaced",
  };
  
  export const AUCTION_FILTERS = {
    NEW: "new",
    HAS_OFFER: "hasOffer",
    ON_AUCTION: "onAuction",
  };
  
  export const AUCTION_STATUS = {
    OPEN: 'open',
    CLOSED: 'closed',
    TIMEOUT: 'timeout',
  };