export const Images = {
  // home page

  featuredImage: require("./images/featured-img.jpg").default,
  collectingImage: require("./images/collecting.png").default,
  
  logoLight: require("./images/logo-light.png").default,
  logoDark: require("./images/logo-dark.png").default,
  imgLight: require("./images/light-img.png").default,
  imgDark: require("./images/dark-img.png").default,
  ethLogo: require('./images/eth-logo.png').default,
  bgHeaderDark: require("./images/header-bg-dark.png").default,
  bgHeaderLight: require("./images/header-bg-light.png").default,
  bgHeaderRight: require("./images/bgHeaderRight.png").default,

  digital: require("./images/digital.png").default,
  leftSideImage: require("./images/leftsideimg.png").default,
  
  about: [
    require("./images/about-img.png").default,
    require("./images/connect.png").default,
    require("./images/create.png").default,
    require("./images/mint.png").default,
    require("./images/earn.png").default
  ],

  nftBadges: [
    require('./images/sell.png').default,
    require('./images/auction.png').default 
  ],

  bgFooter: require("./images/footer-bg.png").default,
  footerImage: require("./images/footer-img.png").default,

  pictureIcon: require("./images/img-icon.png").default,

  pinkPlus: require("./images/pink-plus.png").default,
  whitePlus: require("./images/white-plus.png").default,

  collectionsImages: [
    require("./images/caudio.png").default,
    require("./images/cart.png").default,
    require("./images/cvideo.png").default,
    require("./images/cgif.png").default
  ],

  polygon: require('./images/icons/polygon.png').default,
  musicBg: require("./images/musicbg.jpg").default,

  icons: [
    require("./images/img.png").default,
    require("./images/video.png").default,
    require("./images/gif.png").default,
    require("./images/audio.png").default,
  ],

  metamaskIcons: [
    require("./images/icons/chrome.png").default,
    require("./images/icons/firefox.png").default,
    require("./images/icons/brave.png").default,
    require("./images/icons/edge.png").default,
  ],

  socialLinkIcons: [
    require("./images/icons/youtube.png").default,
    require("./images/icons/spotify.png").default,
    require("./images/icons/globe.png").default,
    require("./images/icons/itunes.png").default,
    require("./images/icons/insta.png").default,
    require("./images/icons/tiktok.png").default,
  ],

  metamask: require("./images/icons/metamask.png").default,
  coinbase: require("./images/icons/coinbase.png").default,

  uLogo: require("./images/ulogo.png").default,

  picture: require("./images/picture.png").default,
  user: require("./images/user.png").default,

  loginImg: require("./images/login-img.jpeg").default,

  wallets: [
    require("./images/wallet1.png").default,
    require("./images/wallet2.png").default,
  ],

  upload: require("./images/upload.png").default,
  arrow: require("./images/arrow.png").default,
  createNft: require("./images/create-nft.png").default,
  digitalArt: require("./images/digital-art.png").default,

  createNfts: [
    require("./images/nfts1.png").default,
    require("./images/nfts2.png").default,
    require("./images/nfts3.png").default,
    require("./images/nfts4.png").default,
  ],

  menu: require("./images/menu9.png").default,
};
