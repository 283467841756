import React, { useEffect } from 'react';

import DocumentTitle from 'components/DocumentTitle/DocumentTitle';
import RenderPage from 'components/RenderPage/RenderPage';
import { goToSection } from 'utilities/CommonMethods';
import { TextListItem } from 'components/TextListItem/TextListItem';
import { dataLists } from './data';
import { TextInline } from 'components/TextListItem/Inline';

const PrivacyPolicy = (props) => {
    
    const { currentTheme: { theme } } = props;

    useEffect(() => {        
        startTop();
    }, []);

    const startTop = () => {
        setTimeout(() => goToSection('privacypolicypage'), 0);
    }

    const textColor = theme === 'dark' ? 'text-white' : 'text-black';

    return (

        <DocumentTitle title="Privacy Policy">

            <RenderPage title="PRIVACY POLICY" className={theme === 'dark' ? 'bg-darkBlack text-white' : 'bg-white text-black'} src={null} id="privacypolicypage">
                
                <div className={`padding-section py-4 ${textColor}`}>

                    <p className={`d-block text-justify mb-3 font-weight-bold font-18px`}> 
                        PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY ACCESSING OR USING THE SERVICE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY ALL THE TERMS OF THIS PRIVACY POLICY AND OUR TERMS OF SERVICE. IF YOU DO NOT AGREE TO SUCH TERMS, EXIT THIS PAGE AND DO NOT ACCESS OR USE THE SERVICE.
                    </p>

                    { dataLists?.map((p, i) => (
                        p?.inline ? <TextInline key={i} item={p} /> :
                        <TextListItem key={i} p={p} />
                    )) }

                </div>
                
            </RenderPage>

        </DocumentTitle>

    );

}
 
export default PrivacyPolicy;