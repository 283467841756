import { Images } from "Assets/Images";
import { React, useState } from "react";

import { ImageLoader } from '../ContentLoader/ContentLoader';

export const Avatar = ({ src, name, large, medium, className, showDummy }) => {

    const [ showError, setShowError ] = useState(false);

    const style = {
        width: large ? '102px' : medium ? '72px' : '52px',
        height: large ? '100px' : medium ? '70px' : '50px',
        // borderRadius: '50%',
        objectFit: 'cover'
    };

    const error = (e) => {
        setShowError(true);
    }

    if(showError){
        return <ImageLoader />;
    }

    if(src && src !== "") {
        return <img alt="" src={src} style={style} className={className} 
        onErrorCapture={(e) => error(e)} onError={(e) => error(e)} />;
    } 
    else if(showDummy){
        return <img alt="" src={Images.pictureIcon} style={style} className={className} 
        onErrorCapture={(e) => error(e)} onError={(e) => error(e)} />;
    }
    else{
        return <ImageLoader />;
    }
}