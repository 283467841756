import React, { useState, useEffect } from 'react';
import './Product.css';

import Wrapper from 'hoc/Layout/Wrapper/Wrapper';
import { Row, Col } from 'react-bootstrap';
import ListItem from 'components/ListItem/ListItem';
import ProductLayout from './ProductLayout';
import { Routes } from 'Routes/Routes';
import { LargeModal } from 'components/LargeModal/LargeModal';
import PurchaseProduct from 'screens/Modals/PurchaseProduct';
import { getLatestArtworks } from 'services/artService';
import LoadData from "components/LoadData/LoadData";
import CombineListItem from 'components/CombineListItem/CombineListItem';
import { ArtworkPrice } from 'components/ArtworkPrice/ArtworkPrice';
import { ArtworkTabs } from 'components/ArtworkTabs/ArtworkTabs';
import { getHistoryDate } from 'utilities/CommonMethods';
import { ProductLinks } from './ProductLinks';
import { SignInSmooth } from "screens/SignInUp/SignInSmooth";

const FixArtwork = (props) => {
    
    const { state, history, currentArtwork, urlId, usd, historyArr, textColor, colors, isDark, refreshState, random } = props;

    const userId = state?.user?.info?._id ? state?.user?.info?._id : state?.user?.info?.id;

    const ownerId = currentArtwork?.owner?.id ? currentArtwork?.owner?.id : currentArtwork?.owner?._id;
    const createrId = currentArtwork?.creater?.id ? currentArtwork?.creater?.id : currentArtwork?.creater?._id;

    const [ openModal, setOpenModal ] = useState({ flag: false, title: null });
    const [artworks, setArtworks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(0);
    const [showModal, setShowModal] = useState({ index: null, flag: false }); // for signInModal

    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        loadArtworks();
    }, [random]);

    const loadArtworks = () => {
        setLoading(true);
        getLatestArtworks().then((res) => {
            setArtworks(res?.data?.filter((item) => item?.id !== urlId));
            setLoading(false);
        }).catch((err) => {
            setArtworks([]);
            setLoading(false);
        });
    };

    const setModalState = (index, flag) => {
        setShowModal({ index, flag });
    }

    return(

        <Wrapper colors={colors} className="art-detail-wrapper" setUsd={() => console.log()}>   

            <ProductLayout className={textColor} isDark={isDark} userId={userId}
                src={currentArtwork?.artwork_url} showPara connection={state?.connection && state?.user?.isLogin} 
                onClick={() => history.replace(`/${Routes.products}/${Math.random()}`)}
                showPurchaseBtn={(userId !== currentArtwork?.owner?._id && currentArtwork?.openForSale)}
                clickPurchase={(title) => setOpenModal({ flag: true, title: title })} refreshState={refreshState}
                showCancelSaleBtn={(userId === currentArtwork?.owner?._id && currentArtwork?.openForSale)}
                views={currentArtwork?.views} name={currentArtwork?.name} desc={currentArtwork?.description}
                connectWallet={() => setModalState(3, true)}artworkId={currentArtwork?._id} usd={usd} 
                artType={currentArtwork?.artwork_type} activeUser={state?.user?.info} history={history} currentArtwork={currentArtwork}
                numberOfLikes={currentArtwork?.numberOfLikes} creater={currentArtwork?.creater}
            >
            
                <ProductLinks geners={currentArtwork?.genre} isGenre={true} />

                <ArtworkTabs activeTab={activeTab} isDark={isDark} arr={['Info', 'History']} className={textColor} setActiveTab={(i) => setActiveTab(i)} />

                { activeTab === 0 ? 
                    <>
                        <ListItem title="Creator" className="mb-2 cursor-pointer-sort" text={`@${currentArtwork?.creater?.userName}`} type="image"
                            colorHeading="text-lightPurple" adjustment showAvatar={true} showAvatarSrc={currentArtwork?.creater?.profilePic} 
                            clickState={true} onClick={(e) => history.push(`/${Routes.profile}/${createrId}`)}
                        />
                        <ListItem title="Owner" className="mb-2 cursor-pointer-sort" colorHeading="text-lightPurple"
                            text={`@${currentArtwork?.owner?.userName ? currentArtwork?.owner?.userName : ' ----- '}`} 
                            adjustment showAvatar={true} showAvatarSrc={currentArtwork?.owner?.profilePic} type="image" 
                            clickState={true} onClick={(e) => history.push(`/${Routes.profile}/${ownerId}`)}
                        />   
                    </> : 
                    activeTab === 1 ? <div style={{ maxHeight: '140px' }} className="overflow-auto"> 
                        {( historyArr?.length === 0 ? <span>No History received.</span> : historyArr?.map((his, i) => (
                            <span className={`mb-1 d-block ${isDark ? 'text-white' : 'text-black'} font-14px`} key={i}><span className="font-weight-bold">{ getHistoryDate(his?.createdAt) }</span> - @{his?.message}</span>
                        ))) }
                    </div> : null
                }

                <ArtworkPrice isMeta={currentArtwork?.isMeta} item={currentArtwork} usd={usd} isDark={isDark} edition={true} fontSize="font-23px" className={textColor} />

                <ProductLinks isGenre={false}
                    links={{ youtube: currentArtwork?.youtubeLink, spotify: currentArtwork?.spotifyLink, tiktok: currentArtwork?.tiktokLink,
                        wifiHash: currentArtwork?.websiteLink, itunes: currentArtwork?.ituneLink, instagram: currentArtwork?.instagramLink
                    }} 
                />

            </ProductLayout>

            <section className={`py-5 w-100 app-flex-column padding-section ${textColor}`}>
            
                <h6 className="font-36px mb-5 d-block text-center font-weight-bold font-clashRegular">
                    More Work By <span className="text-lightPurple">Amazing Musicians</span>
                </h6>

                <Row className="m-0 p-0">
                    <LoadData loading={loading} arr={artworks} msg={`No artworks found.`}>
                        {
                            artworks?.slice(0, 3).map((item, i) => (
                                <Col xs={12} sm={12} md={6} lg={4} xl={3} key={i} className="mb-4">
                                    <CombineListItem 
                                        artwork_url={item?.artwork_url} artwork_type={item?.artwork_type} artworkName={item?.name} 
                                        openForSale={item?.openForSale} isAuctionOpen={item?.isAuctionOpen} artName={item?.name} 
                                        artPrice={item?.price} endTime={item?.auction?.endTime} numberOfLikes={item?.numberOfLikes} 
                                        owner={item?.owner} creater={item?.creater} item={item}
                                        history={history} artId={item?.id} isDark={isDark} usd={usd}
                                    />
                                </Col>
                            ))
                        }
                    </LoadData>
                </Row>

                {
                    openModal.flag &&
                    <LargeModal 
                        condition={openModal.flag} size={3} title={null}
                        handleClose={() => setOpenModal({ flag: false, title: null })}
                    >
                        <PurchaseProduct 
                            src={currentArtwork?.artwork_url} type={currentArtwork?.artwork_type} currentArtwork={currentArtwork}
                            name={currentArtwork?.name} history={history} contractSaleId={currentArtwork?.sale?.contractSaleId}
                            price={currentArtwork?.price} usd={usd} userBalance={state?.user?.userBalance}
                            userAddress={state?.user?.info?.address} purchased={openModal.title === 'purchase'}
                            refreshState={() => {
                                refreshState();
                                setOpenModal({ flag: false, title: null });
                            }} thumbNail_url={currentArtwork?.thumbNail_url} isMeta={currentArtwork?.isMeta}
                        />
                    </LargeModal>
                }

            </section>
            
            <SignInSmooth currentTheme={props?.currentTheme} modalProps={showModal} defaultBehaviour={false} />

        </Wrapper>

    );

}
 
export default FixArtwork;