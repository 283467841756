import React, { useState, useEffect } from 'react';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import ListItem from 'components/ListItem/ListItem';
import LoadData from 'components/LoadData/LoadData';
import { getUserFollowers, getUserFollowings } from 'services/authService';
import { useHistory } from 'react-router';
import { Routes } from 'Routes/Routes';

const UserFollowersFollowings = ({ userId, followersModal }) => {
    
    const history = useHistory();

    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState({
        page: 0,
        limit: 30,
    });

    useEffect(() => {
        getData();
    }, [followersModal]);

    const getData = () => {
        setLoading(true);
        const obj = { ...page, userId };

        if(followersModal){
            getUserFollowers(obj).then((res) => {
                setResults(res.data.data);
                setLoading(false);
            }).catch((err) => {
                setResults([]);
                setLoading(false);
            });
        } else {
            getUserFollowings(obj).then((res) => {
                setResults(res.data.data);
                setLoading(false);
            }).catch((err) => {
                setResults([]);
                setLoading(false);
            });
        }
        
    };

    return (

        <FlexColumn className="w-95 mx-auto">

            <FlexColumn className="mx-auto notifications mt-4 w-100">
                <LoadData loading={loading} arr={results} msg={`No results found.`}>
                    { results?.map((item, i) => (
                        <ListItem 
                            title={`@${item?.userName}`} onClick={() => history.replace(`/${Routes.profile}/${item?._id}`)}
                            className="py-3 mb-3 cursor-pointer-sort" key={i}
                            colorHeading="text-black" type="image" clickState
                            heading="" showAvatarSrc={item?.profilePic}
                            showBorder showAvatar={true} rightText={item?.createdAt?.substring(0, 10)}
                        />
                    )) }
                </LoadData>
            </FlexColumn>

        </FlexColumn>

    );

}
 
export default UserFollowersFollowings;