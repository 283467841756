import React, { useEffect } from 'react';

const DocumentTitle = ({ title, children, condition }) => {

    useEffect(() => {
        document.title = title;
    }, [condition]);

    return children;
}
 
export default DocumentTitle;