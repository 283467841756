import { Images } from 'Assets/Images';
import FlexRow from 'components/FlexRow/FlexRow';
import React from 'react'
import { convertToEther } from 'utilities/dateValid';
import { commaSeparatedPrice } from 'utilities/CommonMethods';

export const ArtworkPrice = (props) => {

    const { item, bidderName, isDark, highBidAmount, edition, fontSize, className, goToBidderProfile, isMeta, usd } = props;

    const price = item?.sale ? item?.sale?.price : item?.auction ? item?.auction?.initialPrice : item?.price;

    const withDollar = (isMeta) => {
        const p = !isMeta ? (convertToEther(price)*usd?.matic).toFixed(2) : (convertToEther(price)*usd?.eth).toFixed(2) ;

        return `($${ commaSeparatedPrice(p) })`;
    };

    return(

        <div className={`app-flex-column w-auto py-4 ${className} ${isDark ? 'text-white' : 'text-black'}`}>
            
            { (item?.isAuctionOpen && bidderName) ? 
                <span className="font-18px mb-2 font-weight-bold text-capitalize">
                    Highest Bidder <span className='cursor-pointer-sort' onClick={goToBidderProfile}>(@{bidderName}):</span>
                    <img alt="icon" src={!isMeta ? Images.polygon : Images.ethLogo} width="20px" className='mx-2' /> 
                    {highBidAmount ? ` ${highBidAmount} ${!isMeta ? 'MATIC' : 'ETH'}` : ''}
                </span> 
            : null }

            <FlexRow className={`align-items-center justify-content-start w-auto flex-wrap ${fontSize}`}>
                
                <span className="mr-3 font-weight-bold">PRICE:</span>
                
                <img alt="icon" src={!isMeta ? Images.polygon : Images.ethLogo} width="23px" className='mr-2' />

                <span className="font-weight-bold mr-3">
                    { convertToEther(price) } { !isMeta ? 'MATIC' : 'ETH' } { withDollar(isMeta) }
                </span>

                { (item?.multipleNFT || (item?.edition && item?.totalEdition)) ? <span className='mr-3'>|</span> : null }

                { (item?.multipleNFT && edition) ? 
                
                <span className='d-block font-weight-bold'>EDITION: {item?.edition}/{item?.group?.totalCount}</span> : null }

            </FlexRow>
        </div>

    );
}