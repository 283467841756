import React from 'react';
import { Div } from 'components/Div/Div';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import { Row, Col } from 'react-bootstrap';
import { SpinnerLoader } from 'components/Spinner/Spinner';
import ArtworkType from 'components/ArtworkType/ArtworkType';

const ProductDetailPopupItem = (props) => {
    
    const { src, type, className, children, btnText, btnContainerClass, onClick, loading, columnLayout, thumbNail_url } = props;

    return (

        <FlexColumn className={`w-100 ${className}`}>

            <Row>
                <Col xs={12} sm={12} md={12} lg={columnLayout ? { span: 12, offset: 0 } : 4} xl={columnLayout ? { span: 12, offset: 0 } : 4} 
                    className="justify-content-center align-items-center app-flex-column px-0"
                >
                    <ArtworkType 
                        height="320px" srcUrl={src} type={type} name={src} thumbNail_url={thumbNail_url}
                        className="cursor-pointer-sort rounded mx-auto" musicOriginalNFT={true} 
                    />
                </Col>
                <Col xs={12} sm={12} md={12} lg={columnLayout ? { span: 12, offset: 0 } : 8} xl={columnLayout ? { span: 12, offset: 0 } : 8} className="px-0">
                    
                    { children }
                    
                    <Div className={`d-block ${btnContainerClass}`}>
                        <button onClick={onClick} style={{ width: '136px' }} disabled={loading}
                            className="text-white bg-lightPurple border-0 outline-none font-16px rounded py-2" 
                        >
                            { !loading ? btnText : <SpinnerLoader size="sm" /> }
                        </button>
                    </Div>

                </Col>
            </Row>

        </FlexColumn>

    );

}
 
export default ProductDetailPopupItem;