import React, { useState } from 'react';
import AlertPopup from 'components/AlertPopup/AlertPopup';
import { claimNft } from 'services/contract.service';
import ArtworkType from 'components/ArtworkType/ArtworkType';
import { SpinnerLoader } from 'components/Spinner/Spinner';

const ClaimNft = (props) => {
    
    const { activeWallet, src, auctionId, claim, type, currentArtwork, refreshState, thumbNail_url, isMeta } = props;

    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState({ show: false, type: '', message: '', title: '' });

    const confirmClaimNft = () => {
        setLoading(true);
        
        const params = { auctionId, isMeta, activeWallet };

        Promise.all([claimNft(params)]).then((res) => {
            if(res?.status || res[0]?.status){
                setLoading(false);
                setErr({ show: true, type: 'success', title: 'Successfully Claimed', message: `` });                                
            }
        }).catch((err) => {
            setErr({ show: true, type: 'error', title: 'Not Successfully Claimed', message: `` });                
            setLoading(false);
        });

    };

    return (

        <div className="px-2 pt-3 justify-content-center align-items-center w-100 app-flex-column">
    
            <ArtworkType height="320px" srcUrl={src} type={type} name={src} thumbNail_url={thumbNail_url}
            musicOriginalNFT={true} className="cursor-pointer-sort rounded" />
            
            <button onClick={() => confirmClaimNft()} style={{ width: '136px' }} disabled={loading}
                className="text-white bg-lightPurple border-0 outline-none font-16px rounded py-2 mt-4" 
            >
                { !loading ? 'Confirm' : <SpinnerLoader size="sm" /> }
            </button>

            <AlertPopup 
                data={{ show: err?.show, title: err.title, message: err?.message, type: err?.type }}
                handleClose={() => {
                    setErr({ ...err, show: false });
                    if(err.type === "success"){
                        refreshState();
                    }
                }}
            />
        </div>

    );

}
 
export default ClaimNft;