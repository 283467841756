import React, { useState, useEffect } from 'react';
import './RenderPage.css';

import { Heading } from 'components/Heading/Heading';
import FooterArt from 'components/FooterArt/FooterArt';
import Footer from 'components/FooterArt/Footer';
import { useSelector } from 'react-redux';
import SignIn from 'screens/SignInUp/SignIn';
import CustomModal from "components/CustomModal/CustomModal";

import { Preloader } from 'components/Preloader/Preloader';
import { getRequiredCoinRates } from 'services/general.service';

const RenderPage = (props) => {
    
    const [showModal, setShowModal] = useState({ index: null, flag: false }); // for signInModal
    const state = useSelector(state => state);

    useEffect(() => {
        const interval = setInterval(() => {
            getRequiredCoinRates((res) => {
                props.setUsd(res.data);
            });
        }, 20000);
    
        return () => clearInterval(interval); 
    }, []);

    const setModalState = (index, flag) => {
        setShowModal({ index, flag });
    }

    return (

        <div id={props.id}
            className={`page-container position-relative ${props?.className} app-flex-column`} 
            style={{ backgroundImage: `url(${props.src})` }}
        >

            <Preloader isDark={state?.theme === 'dark'} />
            
            { props.title &&
            <Heading className="d-block text-center text-capitalize mb-5 font-36px font-clashRegular"> 
                { props.title } 
            </Heading> }

            { props.children }

            { !state?.user?.info && <FooterArt getStarted={() => setModalState(1, true)} /> }

            <Footer isLogin={state?.user?.isLogin && state?.user?.info} />

            <CustomModal show={showModal?.flag} handleClose={() => setModalState(0, false)} size={1}>
                <SignIn handleClose={(i, flag) => setModalState(i, flag)} address="" currentTheme={null} defaultBehaviour={false} />
            </CustomModal>

        </div>

    );

}
 
export default RenderPage;