import React, { useState, useEffect } from 'react';

import CreateNft from './CreateNft/CreateNft';
import DigitalArt from './DigitalArt/DigitalArt';
import Featured from './Featured/Featured';
import Header from './Header/Header';

import Wrapper from 'hoc/Layout/Wrapper/Wrapper';
import DocumentTitle from 'components/DocumentTitle/DocumentTitle';
import { getRequiredCoinRates, getTopArtists } from 'services/general.service';
import { getAllArtService } from 'services/artService';
import { useHistory } from 'react-router';
import { getAllUsers } from 'services/authService';
import { SignInSmooth } from "screens/SignInUp/SignInSmooth";

import CustomModal from "components/CustomModal/CustomModal";
import { isMobile } from 'react-device-detect';
import DeepLinksPopup from './DeepLinksPopup';
import { Images } from 'Assets/Images';

const HomePage = (props) => {
    
    const [showModal, setShowModal] = useState({ index: null, flag: false }); // for signInModal

    const { currentTheme: { colors, theme } } = props;
    const textColor = theme === 'dark' ? 'text-white bg-darkBlack' : 'text-black bg-white';
    const history = useHistory();
    const [usd, setUsd] = useState({ bitcoin: 0, eth: 0, matic: 0  });
    const [arts, setArts] = useState([]);
    const [artists, setArtists] = useState([]);
    const [topArtists, setTopArtists] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState({ page: 0, limit: 6 });

    const [deepLinkPopup, setDeepLinkPopup] = useState(false);

    useEffect(() => {

        if(isMobile){
            setDeepLinkPopup(true);
        }

        load(page);
    }, []);

    const load = (page) => {
        setLoading(true);

        getAllUsers().then((res) => {
            setArtists(res?.data?.data?.filter((item) => item?.role === 'artist'));
        }).catch((err) => {
            setArtists([]);
        });

        getTopArtists().then((res) => {
            setTopArtists(res?.data?.data);
        }).catch((err) => {
            setTopArtists([]);
        });

        getAllArtService({}).then((res) => {
            setArts(res?.data?.data);
            setLoading(false);
        }).catch((err) => {
            setArts([]);
            setLoading(false);
        });

        getRequiredCoinRates(() => {

        });

    };

    const setModalState = (index, flag) => {
        setShowModal({ index, flag });
    }

    return(

        <DocumentTitle title="Marvment">
           
            <Wrapper className="home-page" colors={colors} setUsd={(usd) => setUsd(usd)}>
            
                <Header 
                    arts={arts} history={history} loading={loading} isLogin={props.isLogin}
                    textColor={textColor} isDark={theme === 'dark'} 
                    counts={{ arts: arts?.length, auctions: arts?.filter((item, i) => item?.isAuctionOpen)?.length, 
                        users: artists?.length }}
                    getStarted={() => setModalState(3, true)} usd={usd}
                />

                <DigitalArt history={history} textColor={textColor} theme={theme} isDark={theme === 'dark'} usd={usd} />

                <Featured history={history} currentTheme={props.currentTheme} isDark={theme === 'dark'} arts={arts} loading={loading} usd={usd} />

                <CreateNft textColor={textColor} history={history} isDark={theme === 'dark'} topArtists={topArtists} loading={loading} />

            </Wrapper>

            <SignInSmooth currentTheme={props?.currentTheme} modalProps={showModal} defaultBehaviour={false} />

            <CustomModal
                show={(deepLinkPopup && !window.ethereum)}
                handleClose={() => setDeepLinkPopup(false)}
                size={3}
            >
                <DeepLinksPopup handleClose={() => setDeepLinkPopup(false)} />
            </CustomModal>

        </DocumentTitle>

    );

}
 
export default HomePage;