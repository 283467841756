import React from 'react';

const FlexColumn = (props) => {
    
    const { children, className, style } = props;

    return(

        <div className={`w-100 app-flex-column ${ className }`} style={style} {...props}>
            { children }
        </div>

    );

}
 
export default FlexColumn;