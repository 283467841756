import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import FlexColumn from "components/FlexColumn/FlexColumn";
import LabelWithField from "components/LabelWithField/LabelWithField";
import { Row, Col } from "react-bootstrap";
import { Div } from "components/Div/Div";
import { Heading } from "components/Heading/Heading";
import AlertPopup from "components/AlertPopup/AlertPopup";
import { putNftOnSale } from "services/contract.service";
import { SpinnerLoader } from "components/Spinner/Spinner";
import { CheckDate, getETHOrMatic, commaSeparatedPrice } from "utilities/CommonMethods";
import moment from "moment";
import {
  getToday,
  SelectedDay,
  convertToWei,
  convertToEther,
} from "utilities/dateValid";
import { createAuction } from "services/contract.service";
import ProcessWaiting from "components/ProcessWaiting/ProcessWaiting";

const AuctionSell = (props) => {
  
  const { activeWallet } = useSelector((state) => state);

  const {
    data,
    saleNft,
    usd,
    collectionAddress,
    tokenId,
    refreshState,
    isMeta,
  } = props;

  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState();

  const [value, onChange] = useState(null); // for time change
  const [startDate, setStartDate] = useState(new Date());
  const [time, setTime] = useState(new Date());
  const [processWaitingMsg, setProcessWaitingMsg] = useState("");

  const [err, setErr] = useState({
    show: false,
    type: "",
    message: "",
    title: "",
  });

  useEffect(() => {
    if (data) {
      setPrice(convertToEther(data?.price));
    }
  }, [data]);

  useEffect(() => {
    if (saleNft) {
    }
  }, [saleNft]);

  const clickConfirm = () => {
    if (!price) {
      setErr({
        show: true,
        type: "error",
        title: "Sale price error",
        message: "Enter Price for sale",
      });
    } else {
      if (saleNft && collectionAddress) {
        setLoading(true);
        setProcessWaitingMsg("Please wait while we put your NFT for Sale");

        if (tokenId) {
          let params = {
            collectionAdress: collectionAddress,
            tokenId: tokenId,
            price: convertToWei(price),
            isMeta,
            activeWallet
          };

          putNftOnSale(params)
            .then((res) => {
              setErr({
                show: true,
                type: "success",
                title: "NFT Successfully Placed On Sale",
                message: "",
              });
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              setErr({
                show: true,
                type: "error",
                title: "NFT Not Placed On Sale",
                message: "",
              });
            });
        } else {
          setLoading(false);
          setErr({
            show: true,
            type: "error",
            title: "NFT Token Id not Found.",
            message: "",
          });
        }
      } else if (!saleNft && collectionAddress && tokenId) {
        putOnAuction();
      } else {
        setErr({
          show: true,
          type: "error",
          title: "Collection Address Not Found.",
          message: "",
        });
      }
    }
  };

  const putOnAuction = () => {
    if (value) {
      const getdt = startDate;
      let hour = value?.replace(/:/, ",");
      const getdate = getdt.setHours(
        parseInt(hour.split(",")[0]),
        parseInt(hour.split(",")[1])
      );

      let validate = CheckDate(getdate);
      if (validate) {
        setStartDate(new Date(getdate));
        var getunix = moment(getdate).unix() * 1000;
        let obj = {
          initialPrice: price,
          artwork: data?._id,
          endTime: getunix,
        };
        auctions(obj);
      }
    } else {
      const checkDay = SelectedDay(startDate);
      const today = getToday();
      if (new Date(checkDay) > new Date(today)) {
        var getunix = moment(startDate).unix() * 1000;
        let obj = {
          initialPrice: price,
          artwork: data?._id,
          endTime: getunix,
        };
        auctions(obj);
      } else {
        setErr({
          show: true,
          type: "error",
          title: `You Can't Select the Same Time & Date as Current Date`,
          message: "",
        });
      }
    }
  };

  const auctions = (obj) => {
    let params = {
      collectionAddress: collectionAddress,
      tokenId: tokenId,
      startPrice: convertToWei(obj.initialPrice),
      startTime: moment.utc().format("X"),
      endTime: moment(obj.endTime).utc().format("X"),
      isMeta,
      activeWallet,
    };

    setLoading(true);
    setProcessWaitingMsg("Please wait while we put your NFT on Auction");

    createAuction(params)
      .then((res) => {
        setErr({
          show: true,
          type: "success",
          title: "NFT Successfully Placed On Auction",
          message: "",
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setErr({
          show: true,
          type: "error",
          title: "NFT Not Placed On Auction",
          message: "",
        });
      });
  };

  return (
    <FlexColumn className="pt-5 h-auto mx-auto justify-content-start action-popup">
      {!saleNft && (
        <Row className="">
          <Col xl={2} lg={3} md={12} sm={12} xs={12}>
            <Heading className="text-grey-60 font-13px font-roboto my-3">
              Auction End Date
            </Heading>
          </Col>
          <Col xl={5} lg={4} md={12} sm={12} xs={12} className="mb-3">
            <DatePicker
              selected={startDate}
              minDate={new Date()}
              onChange={(date) => {
                setStartDate(date);
              }}
              className="custom-input-field"
            />
          </Col>
          <Col xl={5} lg={4} md={12} sm={12} xs={12} className="mb-3">
            <TimePicker
              onChange={(e) => {
                setTime(e);
                onChange(e);
              }}
              value={time}
              className="custom-input-field"
              format="h:m:s a"
              disableClock={false}
            />
          </Col>
        </Row>
      )}

      <Row className="">
        <Col xl={3} lg={4} md={4} sm={2} xs={2} className="my-auto text-right">
          <label>Price</label>
        </Col>
        <Col xl={7} lg={6} md={8} sm={9} xs={10} className="position-relative">
          <LabelWithField
            placeholder="Price"
            value={price}
            type="number"
            min="0.001"
            step=".001"
            className="w-100 mb-3"
            name={price}
            disable={false}
            onChange={(e) => setPrice(e.target.value)}
            readOnly={false}
            fieldClassName="mb-3 px-3 custom-input-field bg-grey-40 font-14px text-black"
            onKeyPress={() => console.log()}
          />
          {getETHOrMatic(
            "position-absolute font-15px",
            isMeta,
            { right: 28, top: 17 },
            price,
            usd
          )}
        </Col>
      </Row>

      <Div className="text-center pt-1">
        <button
          onClick={clickConfirm}
          disabled={loading}
          className="font-roboto outline-none w-auto px-5 font-14px rounded normal-btn bg-lightPurple text-white border-0"
        >
          {loading ? <SpinnerLoader size="sm" /> : "Submit"}
        </button>
      </Div>

      <AlertPopup
        data={{
          show: err?.show,
          title: err.title,
          message: err?.message,
          type: err?.type,
        }}
        handleClose={() => {
          setErr({ ...err, show: false });

          if (err?.type === "success") {
            refreshState();
          }
        }}
      />

      <ProcessWaiting show={loading} message={processWaitingMsg} />
    </FlexColumn>
  );
};

export default AuctionSell;
