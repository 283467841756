const DOMAIN = "https://api-v1.getbackend.net/";
const BASE_URL = DOMAIN + "v1/";

const ETH_CONTRACTS = {
  MINT_NFT_NEW_CONTRACT_ADDRESS: "0xF610A19aBF2C4035650b11d9CD0E0ff9cE448f5e",
  MINT_NFT_CONTRACT_ADDRESS: "0xF610A19aBF2C4035650b11d9CD0E0ff9cE448f5e",
  AUCTION_CONTRACT_ADDRESS: "0xad2fB2710eC42297FB159fb502d6251a11FA896c",
  ADMIN_ADDRESS: "0xE81eFceCCeF66B4478264975F828E625C4147C62",
  SIGNER_ADDRESS: "0xE36C5DD307F55940A094fF52E34d902c56a6b151"
};

const POLYGON_CONTRACTS = {
  MINT_NFT_NEW_CONTRACT_ADDRESS: "0xF610A19aBF2C4035650b11d9CD0E0ff9cE448f5e",
  MINT_NFT_CONTRACT_ADDRESS: "0xF610A19aBF2C4035650b11d9CD0E0ff9cE448f5e",
  AUCTION_CONTRACT_ADDRESS: "0xad2fB2710eC42297FB159fb502d6251a11FA896c",
  ADMIN_ADDRESS: "0xE81eFceCCeF66B4478264975F828E625C4147C62",
  SIGNER_ADDRESS: "0xE36C5DD307F55940A094fF52E34d902c56a6b151"
};

const networkChain = { eth: 1, poly: 137 };

export { BASE_URL, ETH_CONTRACTS, POLYGON_CONTRACTS, DOMAIN, networkChain };



// For testing purpose

// const DOMAIN = "http://54.208.142.61:3000/";
// const DOMAIN = "https://devmarvment.herokuapp.com/";

// const DOMAIN = "https://devmarvment.herokuapp.com/";
// const BASE_URL = DOMAIN + "v1/";
// const ETH_CONTRACTS = {
//   MINT_NFT_NEW_CONTRACT_ADDRESS: "0xB759D26b6037D805E9B879555aEcD1dc238a642d",
//   MINT_NFT_CONTRACT_ADDRESS: "0x5BEb80E7de86fca638E6E2227263Ad1794f07561",
//   AUCTION_CONTRACT_ADDRESS: "0x34ba4EF67C65E7865BF4dF3013Ad65a9d18edB4C",
//   ADMIN_ADDRESS: "0x192DDbb00E5aA7E3107f82030C4C8AAB1EB903B7",
//   SIGNER_ADDRESS: "0xE36C5DD307F55940A094fF52E34d902c56a6b151"
// };

// const POLYGON_CONTRACTS = {
//   MINT_NFT_NEW_CONTRACT_ADDRESS: "0x6a096Df018ef12746d040Ead202530401648390a",
//   MINT_NFT_CONTRACT_ADDRESS: "0xA23D02Ea0873A9ED220a2B991f8566290D74E781",
//   AUCTION_CONTRACT_ADDRESS: "0x2DaDBD5a4B1C8e70fd9Bd08cab83599d3D7Ca2F6",
//   ADMIN_ADDRESS: "0x192DDbb00E5aA7E3107f82030C4C8AAB1EB903B7",
//   SIGNER_ADDRESS: "0xd6558c9E5A6E0dbCee248D59D0BA2E70D8137509"
// };

// const networkChain = {
//   eth: 4,
//   poly: 80001
// };

// export { BASE_URL, ETH_CONTRACTS, POLYGON_CONTRACTS, DOMAIN, networkChain };