import { HTTP_CLIENT } from "utilities/intercepter";

export const createAlbum = (obj) => {
    let FormBody = new FormData();
    if (Object.keys(obj).length > 0) {
      Object.keys(obj).forEach((res) => {
        FormBody.append(`${res}`, obj[res]);
      });
  
      return HTTP_CLIENT.post(`album/createAlbum`, FormBody);
    }
};

export const updateAlbum = (obj, id) => {
    let FormBody = new FormData();
    if (Object.keys(obj).length > 0) {
      Object.keys(obj).forEach((res) => {
        FormBody.append(`${res}`, obj[res]);
      });   
  
      return HTTP_CLIENT.post(`album/updateAlbum?albumId=${id}`, FormBody);
    }
};

export const getSingleAlbum = (id) => {
    return HTTP_CLIENT.get(`album/getSingleAlbum?albumId=${id}`);
};

export const deleteSingleAlbum = (id) => {
  return HTTP_CLIENT.get(`album/deleteAlbum?albumId=${id}`);
};

export const getUserAlbums = () => {
    return HTTP_CLIENT.get(`album/getUserAlbum`);
};

export const getArtworksByAlbum = (id, page) => {
    return HTTP_CLIENT.get(`album/getArtworksFromAlbum?albumId=${id}&page=${page.page}&perPage=${page.perPage}`);
};