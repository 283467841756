const LIGHT_THEME = {
    white: '#ffffff',
    lightWhite: '#F5F5F5',
    black: '#000000',
    lightPurple: '#9370FB',
    lightWhite: '#E2E2E2',
    lightGray: '#C4C4C4',
    lightGray20: '#E2E2E2',
    blue: '#5674B9',
    darkBlack: '#05010F',
};

const DARK_THEME = {
    white: '#ffffff',
    lightWhite: '#F5F5F5',
    black: '#000000',
    lightPurple: '#9370FB',
    lightWhite: '#E2E2E2',
    lightGray: '#ffffff',
    lightGray20: '#E2E2E2',
    blue: '#5674B9',
    darkBlack: '#05010F'
};

export { DARK_THEME, LIGHT_THEME };