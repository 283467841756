import FlexColumn from 'components/FlexColumn/FlexColumn';
import FlexRow from 'components/FlexRow/FlexRow';
import { Heading } from 'components/Heading/Heading';
import React from 'react';
import { LargeModal } from '../LargeModal/LargeModal';

const LogoutPopup = (props) => {
    
    const { logout, close, condition } = props;

    if(condition){
        return(
            <LargeModal 
                condition={condition} removePaddingBottom
                handleClose={() => close(false)} size={3}
            >
                <FlexColumn className="py-3 justify-content-start">

                    <Heading className="font-18px my-3 d-block">Are You Sure You Want To Sign Out?</Heading>

                    <FlexRow className="w-auto align-items-center justify-content-end">
                        <button onClick={logout}
                            className="text-white bg-pink border-0 outline-none font-16px rounded py-2 px-4" 
                        >
                            Sign Out
                        </button>
                        <button onClick={close}
                            className="text-white bg-pink border-0 outline-none font-16px rounded py-2 px-4 ml-3" 
                        >
                            Close
                        </button>
                    </FlexRow>
                </FlexColumn>
            </LargeModal>
        );
    }

   else return null;

}
 
export default LogoutPopup;