import React, { useState, useEffect } from 'react';
import './Profile.css';

import { Routes } from 'Routes/Routes';
import { Images } from 'Assets/Images';

import { Row, Col } from 'react-bootstrap';
import StepperTabs from 'components/StepperTabs/StepperTabs';
import { appRoles, filterProfile, profileTabs } from 'Assets/Data';
import ProfileInformation from './ProfileInformation';
import RenderPage from 'components/RenderPage/RenderPage';
import { LargeModal } from 'components/LargeModal/LargeModal';
import AlertPopup from 'components/AlertPopup/AlertPopup';
import Settings from './Settings';
import Notifications from '../Modals/Notifications';
import withProtected from 'hoc/Layout/Protected/Protected';
import DocumentTitle from 'components/DocumentTitle/DocumentTitle';
import { getUserAllCollection } from 'services';
import { getAllUserArtFavourite, getAllUserArts, removeFavouriteArtService, getOpenArtWorkList, deleteArtService } from 'services/artService';
import LoadData from 'components/LoadData/LoadData';
import FlexRow from 'components/FlexRow/FlexRow';
import CheckFilter from 'components/CheckFilter/CheckFilter';
import ArtistCollectionItem from './ArtistCollectionItem';
import { updateUserObj } from 'redux/thunk/thunk';
import CreateCollection from 'screens/Collection/Create';
import CombineListItem from 'components/CombineListItem/CombineListItem';
import { goToSection } from 'utilities/CommonMethods';
import { getAllNotification } from 'services/general.service';
import Pagination from 'components/Pagination/Pagination';
import { CreateAlbum } from 'screens/Album/CreateAlbum';
import { getUserAlbums } from 'services/albumService';
import { Box } from 'components/Box/Box';

const Profile = (props) => {

    // redux setups
    const { state, dispatch, history } = props;

    const { _id, userName, createdAt, followers, following, collections, artworks, bio, profilePic, role } = state?.user?.info;
    const userId = state?.user?.info?.id ? state?.user?.info?.id : state?.user?.info?._id;
    const textColor = state?.theme === 'dark' ? 'text-white bg-darkBlack' : 'text-black bg-white';

    const locationState = history?.location?.state;

    const [ openAlbumModal, setOpenAlbumModal ] = useState({ flag: false, title: null });
    const [usd, setUsd] = useState({ bitcoin: 0, eth: 0, matic: 0  });
    const [ openModal, setOpenModal ] = useState({ flag: false, title: null });    
    const [collection, setCollection] = useState([]);
    const [albums, setAlbums] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(locationState === 1 ? locationState : 0);
    const [userArtCount, setUserArtCount] = useState(0);
    const [count, setCount] = useState(0);
    const [activeFilterId, setActiveFilterId] = useState(0);
    const [artistObj, setArtistObj] = useState({ 
        flag: role?.toLowerCase() === appRoles[1] ? true : false, item: undefined, 
        title: role?.toLowerCase() === appRoles[1] ? 'Collections' : 'Artworks'
    }); // for artist screen change with condition
    const [notificationCount, setNotificationCount] = useState(0);
    const [page, setPage] = useState({
        page: 0, limit: 8, userId: userId
    });

    const [err, setErr] = useState({ show: false, type: '', message: '', title: '' });
    const [delErr, setDelErr] = useState({ show: false, type: '', message: '', title: '', loading: false, id: undefined });

    const removeFavBtn = role?.toLowerCase() === appRoles[1] ? (activeTab === 1) : (activeTab === 1);

    useEffect(() => {
        goToSection('personal-profile');
        dispatch(updateUserObj(userId));   
        getAllUserArts(page).then((res) => setUserArtCount(res?.data?.count));
        getAllNotification(undefined).then((res) => setNotificationCount(res?.data?.data?.length));
        getUserMusicAlbums();
    }, []);

    useEffect(() => {
        setLoading(true);
        setActiveFilterId(0);

        if(role?.toLowerCase() === appRoles[1]){
            if(activeTab === 0){
                getUserCollections();
            } else if(activeTab === 1) {
                getUserAuctions();
            } else if(activeTab === 2) {
                getUserFavArtworks();
            }
        }
        
        if(role?.toLowerCase() === appRoles[0]){
            if(activeTab === 0){
                getUserAuctions();
            } else if(activeTab === 1) {
                getUserFavArtworks();
            }
        }

    }, [activeTab, role]);

    // for artist screen
    useEffect(() => {
        if(role?.toLowerCase() === appRoles[1]){
            if(artistObj?.item){
                getCollectionArtworks(artistObj?.item?._id, albums);
            }
            if(!artistObj?.item){
                getUserCollections();
            }
        }
    }, [artistObj]);

    useEffect(() => {
        setPage({ page: 0, limit: 8, userId: userId });
    }, [activeFilterId, activeTab]);

    useEffect(() => {
        goToSection('personal-profile');
        
        if(activeFilterId > 0){
            artworkFilter(activeFilterId);
        }
        
        if(activeTab === 0){
            getUserCollections();
        } else if(activeTab === 1) {
            getUserAuctions();
        } else if(activeTab === 2) {
            getUserFavArtworks();
        }
    }, [page]);

    const getUserCollections = () => {
        setLoading(true);
        getUserAllCollection(userId).then((res) => {
            setCollection(res?.data?.data);
            setLoading(false);

            if(locationState === 2){
                const menu = res?.data?.data[1];
                setArtistObj({ item: menu, title: `${menu?.symbol} Collections`, flag: false });
                history.push({ pathname: `/${Routes.profile}`, state: 0 });
            }
        }).catch((err) => {
            setLoading(false);
            setCollection([]);
        });
    };

    const getUserFavArtworks = () => {
        getAllUserArtFavourite(page).then((res) => {
            setCount(res?.data?.count);
            setCollection(res?.data?.data);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            setCollection([]);
        });
    }

    const getUserAuctions = () => {
        getAllUserArts(page).then((res) => {
            setCount(res?.data?.count);
            
            setCollection(res?.data?.data);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            setCollection([]);
        });
    }

    const getCollectionArtworks = (id, albums) => {
        setLoading(true);

        getOpenArtWorkList(id).then((res) => {
            if(artistObj?.item && artistObj.item?.symbol === 'MUSIC'){
                setCollection(albums.concat(res?.data?.data?.filter((fil) => fil?.owner?._id === userId)));
            } else {
                setCollection(res?.data?.data?.filter((fil) => fil?.owner?._id === userId));
            }
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            setCollection([]);
        });
    };

    const openClickModal = (str) => {
        setOpenModal({ flag: true, title: str });
    }

    const removeFromFav = (id) => {
        if(id){
            const obj = { "artworkId": id };

            if((role?.toLowerCase() === appRoles[0] && activeTab === 1) || (role?.toLowerCase() === appRoles[1] && activeTab === 2)){
                removeFavouriteArtService(obj).then((res) => {
                    setDelErr({ ...delErr, show: false, loading: false, id: undefined });
                    setErr({ show: true, type: 'success', title: 'Artwork Removed from Favorites', message: `` });
                    window.location.reload();
                }).catch((err) => {
                    setDelErr({ ...delErr, show: false, loading: false, id: undefined });
                    setErr({ show: true, type: 'error', title: 'Artwork Not Removed from Favorites', message: `` });
                });      
            } else {
                deleteArtService(obj).then((res) => {
                    setDelErr({ ...delErr, show: false, loading: false, id: undefined });
                    setErr({ show: true, type: 'success', title: 'Artwork Successfully Deleted', message: '' });
                    window.location.reload();
                }).catch((err) => {
                    setDelErr({ ...delErr, show: false, loading: false, id: undefined });
                    setErr({ show: true, type: 'error', title: 'Artwork Not Deleted', message: '' });
                });
            }
        }
    };

    const artworkFilter = (id) => {
        setLoading(true);

        getAllUserArts(page).then((res) => {
            let result = [];
            if(id === 1 && res?.data?.data?.length){
                result = res?.data?.data?.filter((item) => item?.isAuctionOpen);
            }
            if(id === 2 && res?.data?.data?.length){
                result = res?.data?.data?.filter((item) => item?.openForSale);
            }
            if(id === 3 && res?.data?.data?.length){
                result = res?.data?.data?.filter((item) => !item?.openForSale && !item?.isAuctionOpen);
            }
            setCollection(result);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            setCollection([]);
        });
    };

    const getUserMusicAlbums = (flag) => {
        getUserAlbums().then((res) => {
            const result = res.data.albums?.map(a => {
                return { albumDetails: a, creater: a?.creater, isAlbum: true, artwork_url: a?.coverImage };
            });

            setAlbums(result);

            if(flag){
                getCollectionArtworks(artistObj?.item?._id, result);
            }
        }).catch((err) => {

        });
    };

    const resetArtistFilter = () => {
        setArtistObj({ item: undefined, title: 'Collections', flag: true });
    };

    return (

        <DocumentTitle title="Profile">

            <RenderPage className={textColor} id="personal-profile" setUsd={(usd) => setUsd(usd)}>
                
                <Row className={`padding-section m-0 ${textColor}`}>
                
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-1">
                        <ProfileInformation src={profilePic ? profilePic : Images.uLogo} name={userName} user={state?.user?.info}
                            date={createdAt.substring(0, 10)} bio={bio} role={role} publicProfile={false} userId={userId}
                            followers={followers?.length} loading={false} creation={artworks?.length}
                            following={following?.length} collection={collections?.length} textColor={textColor}
                            userRole={role?.toLowerCase() === appRoles[0]} showFollowBtn={false} userArts={userArtCount}
                            openModal={(title) => openClickModal(title)} reset={resetArtistFilter} notificationCount={notificationCount}
                            openEditProfile={() => history.replace(`/${userName?.split(' ')?.join('')?.toLowerCase()}/edit`)}
                            showArtObjects={artistObj?.item} openUserArtTab={() => setActiveTab(1)} isDark={state?.theme === 'dark'}
                            showCollectionBtn={(role?.toLowerCase() === appRoles[1] && !artistObj?.item && collection?.length < 4)}
                        />
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <StepperTabs activeTab={activeTab} tabs={profileTabs(role, artistObj.title)} getActive={(index) => {
                            if(role?.toLowerCase() === appRoles[1] && index === 0){
                                resetArtistFilter();
                            }
                            setActiveTab(index);
                        }} />

                        <FlexRow className="my-3 align-items-center adjustment-end">
                            { (activeTab === 0 && !artistObj.flag) && 
                                <CheckFilter 
                                    arr={filterProfile} onClick={(item) => setActiveFilterId(item?.id)} 
                                    className="adjustment-end text-uppercase" activeId={activeFilterId}
                                />
                            }
                        </FlexRow>
                        <Row className="mb-3 px-2">
                            
                            { (artistObj?.item && activeTab === 0) && 
                                <Col xs={12} sm={12} md={6} lg={4} xl={3} className="mb-4 cursor-pointer-sort" 
                                    onClick={() => history.push(`/${Routes.submitNfts}/${artistObj?.item?._id}`)}>
                                    <Box isDark={state?.theme === 'dark'} title={ artistObj.item?.symbol === 'MUSIC' ? 'upload single music nft' : 'upload nft' } />
                                </Col>
                            }

                            { (artistObj?.item && artistObj.item?.symbol === 'MUSIC' && activeTab === 0) && 
                                <Col xs={12} sm={12} md={6} lg={4} xl={3} className="mb-4 cursor-pointer-sort" 
                                    onClick={() => setOpenAlbumModal({ flag: true, title: 'Create' })}>
                                    <Box isDark={state?.theme === 'dark'} title="create album" />
                                </Col>
                            }

                            <LoadData spinner={loading} arr={collection} msg={activeTab === 0 ? '' : 'No data available'} spinnerClassName={ artistObj?.item ? "w-75" : "w-100"}>
                            
                                { (role?.toLowerCase() === appRoles[1] && artistObj.flag && activeTab === 0) ?
                                    collection?.slice(0, 4)?.map((menu, i) => (
                                        <ArtistCollectionItem item={menu} key={i} userId={userId} close={() => resetArtistFilter()}
                                        onClick={() => setArtistObj({ item: menu, title: `${menu?.symbol} Collections`, flag: false })} />
                                    )) :
                                    collection.map((menu, i) => (
                                        <Col xs={12} sm={12} md={6} lg={4} xl={3} key={i} className="mb-4">
                                            <CombineListItem 
                                                artwork_url={menu?.artwork_url} artwork_type={menu?.artwork_type} artworkName={menu?.name} 
                                                openForSale={menu?.openForSale} isAuctionOpen={menu?.isAuctionOpen} artName={menu?.name} 
                                                artPrice={menu?.price} endTime={menu?.auction?.endTime} numberOfLikes={menu?.numberOfLikes} 
                                                owner={menu?.owner} creater={menu?.creater} item={menu} showEditAlbum={true}
                                                showRemove={(activeTab === 2 || (!menu?.isAuctionOpen && !menu?.openForSale))}
                                                clickRemove={(artId) => setDelErr({ show: true, type: 'error', message: 'Are you sure want to delete this artwork?',
                                                 title: 'Delete Artwork', loading: false, id: artId })} usd={usd}
                                                history={history} artId={menu?._id} isDark={state?.theme === 'dark'} 
                                                reRender={() => getUserMusicAlbums(true)}
                                            />
                                        </Col>
                                )) }   
                            </LoadData>
                        </Row>

                        {
                            (activeTab > 0 && count > 8) &&
                            <Pagination isDark={state?.theme === 'dark'} 
                                nextClick={() => setPage({ ...page, page: page.page + 1 })} total={count}
                                backClick={() => setPage({ ...page, page: page.page - 1 })} perPage={8} textColor={textColor}
                                activeIndex={page.page} changePage={(i) => setPage({ ...page, page: i })} 
                            />
                        }

                    </Col>
                </Row>

                {
                    openModal.flag &&
                    <LargeModal 
                        condition={openModal.flag} title={openModal.title}
                        size={openModal.title === 'Set Royalty' ? 3 : 2}
                        handleClose={() => setOpenModal({ flag: false, title: null })}
                    >

                        { openModal.title === 'settings' && <Settings /> }
                        { openModal.title === 'Notification' && <Notifications /> }
                        
                        { (openModal.title === 'Create Collection' || openModal.title === 'Set Royalty') && 
                        <CreateCollection userAddress={state?.user?.info?.address} collection={artistObj?.item} 
                            close={() => {
                                setOpenModal({ flag: false, title: null });
                                setActiveTab(0);
                                resetArtistFilter();
                            }} userId={userId} activeWallet={state?.activeWallet}
                        /> }

                    </LargeModal>
                }

                <AlertPopup 
                    data={{ show: err?.show, title: err.title, message: err?.message, type: err?.type }}
                    handleClose={() => {
                        setErr({ ...err, show: false });
                    }}
                />

                <AlertPopup 
                    data={{ show: delErr?.show, title: delErr.title, message: delErr?.message, type: delErr?.type }}
                    handleClose={() => {
                        setDelErr({ ...delErr, loading: true });
                        setTimeout(() => removeFromFav(delErr.id), 1000);
                    }} loading={delErr.loading}
                    showCancel={() => setDelErr({ ...delErr, show: false, id: undefined })} 
                />

                { openAlbumModal?.flag &&
                <CreateAlbum openModal={openAlbumModal} album={undefined} 
                onClose={(obj) => setOpenAlbumModal(obj)} reRender={() => getUserMusicAlbums(true)} />  }

            </RenderPage>

        </DocumentTitle>
    );

}
 
export default withProtected(Profile, Routes.empty);