import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './global.css';
import Marvment from './App';
import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.scss';

import { BrowserRouter as Router } from "react-router-dom";

import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { configureStore } from './redux/store';

const store = configureStore();
const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
        <Router>          
          <Marvment />
        </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// "sass": "^1.49.7",
// "node-sass": "^5.0.0",

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// http://sardartufani.com/projects/digitalcrypto

// "start": "react-scripts --max_old_space_size=4096 start",
// "build": "react-scripts --max_old_space_size=4096 build",

//  "start": "react-scripts start",
// "build": "react-scripts build",

// https://xd.adobe.com/view/6d0ec47b-ec6a-4d7a-8011-852ebd5ac5f6-385d/grid

reportWebVitals();
