import React from "react";

import { Switch, withRouter } from 'react-router-dom';
import { Routes } from 'Routes/Routes';
import PublicRoute from 'Routes/public';

import { getTheme } from 'utilities/CommonMethods';
import { useSelector } from 'react-redux';

import MainLayout from "./hoc/Layout/Layout";

// pages
import HomePage from 'screens/Home/Home';
import Discover from 'screens/Discover/Discover';
import Activity from 'screens/Activity/Activity';
import SubmitNFT from "screens/SubmitNft/SubmitNft";
import Profile from 'screens/Profile/Profile';
import MainOfArtworks from 'screens/Product/Main';
import CollectionDetail from "screens/Collection/CollectionDetail";
import PublicProfile from 'screens/Profile/PublicProfile';
import GlobalSearch from "screens/GlobalSearch/GlobalSearch";
import EditProfile from "screens/Modals/EditProfile";
import Dashboard from 'screens/Dashboard/Dashboard';
import Album from 'screens/Album/Album';

import About from 'screens/About/About';
import PrivacyPolicy from "screens/PrivacyPolicy/PrivacyPolicy";
import ServiceTerms from "screens/ServiceTerms/ServiceTerms";
import FAQ from "screens/FAQ/FAQ";

const Marvment = (props) => {

  const { history: { location } } = props;
  const state = useSelector(state => state);
  const currentTheme = { theme: state?.theme, colors: getTheme(state?.theme) };

  return(

    <Switch>

      <MainLayout isLogin currentTheme={currentTheme} state={state}>
        
        <PublicRoute exact={true} component={() => <HomePage currentTheme={currentTheme} isLogin={state?.user?.info} />} path={Routes.main} />

        <PublicRoute exact={true} component={() => <Discover currentTheme={currentTheme} />} path={`/${Routes.discover}`} />

        <PublicRoute exact={true} component={() => <Activity currentTheme={currentTheme} />} path={`/${Routes.activity}`} />
        
        <PublicRoute exact={true} component={() => <GlobalSearch currentTheme={currentTheme} />} path={`/${Routes.search}`} />
        
        <PublicRoute exact={true} component={() => <About currentTheme={currentTheme} />} path={`/${Routes.about}`} />

        <PublicRoute exact={true} component={() => <FAQ currentTheme={currentTheme} />} path={`/${Routes.faq}`} />

        <PublicRoute exact={true} component={() => <PrivacyPolicy currentTheme={currentTheme} />} path={`/${Routes.policy}`} />
        
        <PublicRoute exact={true} component={() => <ServiceTerms currentTheme={currentTheme} />} path={`/${Routes.terms}`} />

        <PublicRoute exact={true} component={() => <Dashboard currentTheme={currentTheme} activeWallet={state?.activeWallet} 
        address={state?.address[0]} />} path={`/${Routes.dashboard}`} />

        <PublicRoute exact={true} component={Profile} path={`/${Routes.profile}`} />
        
        <PublicRoute exact={true} component={() => <PublicProfile currentTheme={currentTheme} />} path={`/${Routes.profile}/:id`} />

        <PublicRoute exact={true} component={SubmitNFT} path={`/${Routes.submitNfts}`} />
        
        <PublicRoute exact={true} component={SubmitNFT} path={`/${Routes.submitNfts}/:collectionId`} />

        <PublicRoute exact={true} component={SubmitNFT} path={`/${Routes.submitNfts}/:collectionId/:albumId`} />
        
        <PublicRoute exact={true} component={() => <MainOfArtworks colors={currentTheme?.colors} />} path={`/${Routes.products}`} />
        
        <PublicRoute exact={true} component={() => <MainOfArtworks colors={currentTheme?.colors} />} path={`/${Routes.products}/:id`} />

        <PublicRoute exact={true} component={() => <Album currentTheme={currentTheme} />} path={`/${Routes.album}`} />
        
        <PublicRoute exact={true} component={() => <Album currentTheme={currentTheme} />} path={`/${Routes.album}/:id`} />
        
        <PublicRoute exact={true} component={() => <CollectionDetail currentTheme={currentTheme} />} path={`/${Routes.collections}/:id`} />
        
        <PublicRoute exact={true} component={() => <EditProfile user={state?.user?.info} />} path={`/:username/edit`} />

      </MainLayout>

    </Switch>

  );

}

export default withRouter(Marvment);
