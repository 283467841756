import {
  IS_LOGIN,
  IS_LOGOUT,
  CHANGE_WALLET,
  UPDATE_PROFILE,
  SET_USER_BALANCE,
  IS_PROFILE_ALBUM,
  IS_NOTIFICATIONS,
  CHANGE_THEME,
  SET_ACTIVE_WALLET,
} from "../types/types";

import { initialState, emptyState } from "./state";

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  // address, connection, wallets, user, [args order]

  switch (type) {
    case CHANGE_WALLET: {
      const { address, connection, wallets } = payload;

      return {
        ...state,
        address: [address],
        wallets: wallets,
        connection: connection,
        user: state.user,
      };
    }

    case IS_LOGIN: {
      const { info, token, address } = payload;

      return {
        ...state,
        address: [address],
        wallets: state.wallets,
        connection: true,
        user: { ...state.user, token, info, isLogin: true },
      };
    }

    case UPDATE_PROFILE: {
      const { data } = payload;

      return {
        ...state,
        user: {
          ...state.user,
          info: data,
        },
      };
    }

    case IS_NOTIFICATIONS: {
      return {
        ...state,
        notifications: payload,
      };
    }

    case CHANGE_THEME: {
      return {
        ...state,
        theme: payload,
      };
    }

    case IS_PROFILE_ALBUM: {
      return {
        ...state,
        albumButtons: payload,
      };
    }

    case SET_USER_BALANCE: {
      return {
        ...state,
        user: {
          ...state.user,
          userBalance: payload?.data,
        },
      };
    }

    case IS_LOGOUT: {
      return {
        ...emptyState,
        theme: state.theme,
      };
    }
    case SET_ACTIVE_WALLET: {
      return {
        ...state,
        activeWallet: payload,
      };
    }

    default:
      return state;
  }
};
