import React from 'react';
import { BsArrowRight, BsArrowLeft } from 'react-icons/bs';

export const ArrowIcon = (props) => {

    const { left, className, onClick } = props;

    return(

        <div className={`rounded-circle bg-white app-flex-column cursor-pointer-sort align-items-center justify-content-center ${className}`} 
        style={{ width: '75px', height: '75px', boxShadow: '0px 3px 6px #00000029' }} onClick={onClick}>
            { left ? <BsArrowLeft className="font-30px text-black" /> : <BsArrowRight className="font-30px text-black" /> }
        </div>

    );

}