import { getSingleUserService } from 'services';
import { isUpdateUserProfile } from 'redux/actions/action';

export const updateUserObj = (userId) => dispatch => {

    getSingleUserService(userId, (res) => {

        if(res){
            dispatch(isUpdateUserProfile({ data: res }));   
        }
        else {
            console.log();
        }
    });

};