import React, { useState, useEffect } from 'react';
import './Profile.css';

import { Images } from 'Assets/Images';
import { Row, Col } from 'react-bootstrap';
import ProfileInformation from './ProfileInformation';
import StepperTabs from 'components/StepperTabs/StepperTabs';
import RenderPage from 'components/RenderPage/RenderPage';
import AlertPopup from 'components/AlertPopup/AlertPopup';
import CombineListItem from 'components/CombineListItem/CombineListItem';
import DocumentTitle from 'components/DocumentTitle/DocumentTitle';
import { getAllUserArts } from 'services/artService';
import { followAUser, getSingleUserService, unFollowAUser } from 'services';
import LoadData from 'components/LoadData/LoadData';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import CheckFilter from 'components/CheckFilter/CheckFilter';
import { filterProfile } from 'Assets/Data';
import { goToSection } from 'utilities/CommonMethods';
import Pagination from 'components/Pagination/Pagination';

const PublicProfile = (props) => {

    const { currentTheme: { colors, theme } } = props;
    // redux setups
    const { id } = useParams();
    const history = useHistory();
    const state = useSelector(state => state);
    const [usd, setUsd] = useState({ bitcoin: 0, eth: 0, matic: 0  });
    const [collection, setCollection] = useState([]);
    const [userArtCount, setUserArtCount] = useState(0);
    const [user, setUser] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [userLoading, setUserLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(0);
    const [activeFilterId, setActiveFilterId] = useState(0);
    const [page, setPage] = useState({
        page: 0, limit: 8, userId: id
    });
    const [err, setErr] = useState({ show: false, type: '', message: '', title: '' });

    useEffect(() => {
        goToSection('public-profile');
        setPage({ page: 0, limit: 8, userId: id });
        getUserArtworks(undefined);
        getUser();
    }, [activeTab]);

    useEffect(() => {
        setPage({ page: 0, limit: 8, userId: id });
    }, [activeFilterId]);

    useEffect(() => {
        goToSection('public-profile');
        
        if(activeFilterId > 0){
            getUserArtworks(activeFilterId);
        } else {
            getUserArtworks(undefined);
        }
    }, [page]);

    const getUserArtworks = (id) => {
        setLoading(true);
        getAllUserArts(page).then((res) => {

            if(!id){
                setUserArtCount(res?.data?.count);
                setCollection(res?.data?.data);
                setLoading(false);
            } else {

                let result = [];
                if(id === 1 && res?.data?.data?.length){
                    result = res?.data?.data?.filter((item) => item?.isAuctionOpen);
                }
                if(id === 2 && res?.data?.data?.length){
                    result = res?.data?.data?.filter((item) => item?.openForSale);
                }
                if(id === 3 && res?.data?.data?.length){
                    result = res?.data?.data?.filter((item) => !item?.openForSale && !item?.isAuctionOpen);
                }

                setUserArtCount(res?.data?.count);
                
                setCollection(result);
                setLoading(false);
            }
        }).catch((err) => {
            setLoading(false);
            setCollection([]);
        });
    }

    const getUser = () => {
        setUserLoading(true);
        getSingleUserService(id, (res) => {
            if(res){
                setUser(res);
                setUserLoading(false);
            } else {
                setUserLoading(false);
            }
        });
    };

    const followUnFollow = (title) => {
        if(!user){
            setErr({ show: true, type: 'error', title: 'Something went wrong', message: `` });
        } else if(!state?.user?.isLogin) {
            setErr({ show: true, type: 'error', title: 'Please Sign In/Up To Your MarvmentNFT Account', message: `` });
        } else {

            const obj = { "otherUserId": id };

            if(title?.toLowerCase() === 'follow'){
                followAUser(obj).then((res) => {
                    setErr({ show: true, type: 'success', title: `Followed Successfully`, 
                    message: `You have successfully followed ${user?.role} ${user?.userName}` });
                }).catch((err) => {
                    setErr({ show: true, type: 'error', title: 'Network error', message: `Something went wrong.` });
                });
            }

            if(title?.toLowerCase() === 'following'){
                unFollowAUser(obj).then((res) => {
                    setErr({ show: true, type: 'success', title: `UnFollowed Successfully`, 
                    message: `You have successfully unfollowed ${user?.role} ${user?.userName}` });
                }).catch((err) => {
                    setErr({ show: true, type: 'error', title: 'Network error', message: `Something went wrong.` });
                });
            }
        }
    };

    const textColor = theme === 'dark' ? 'text-white' : 'text-black';

    return (

        <DocumentTitle title="Profile">

            <RenderPage className={theme === 'dark' ? 'bg-darkBlack text-white' : 'bg-white text-black'} id="public-profile" 
                setUsd={(usd) => setUsd(usd)}
            >
                
                <Row className="padding-section m-0">

                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-4">
                        <ProfileInformation src={user?.profilePic ? user?.profilePic : Images.uLogo} collection={user?.collections?.length}
                            bio={user?.bio} role={user?.role} name={user?.userName} followers={user?.followers?.length}
                            loading={userLoading} creation={user?.artworks?.length} textColor={textColor} userId={id}
                            showFollowBtn={id !== state?.user?.info?._id} art={user?.artworks?.length} following={user?.following?.length}
                            clickFollowBtn={(title) => followUnFollow(title)} publicProfile={true} user={user}
                            btnText={ user?.followers?.includes(state?.user?.info?._id) ? "Following" : "Follow"}
                            userArts={userArtCount} isDark={theme === 'dark'}
                        />
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <StepperTabs tabs={[{ id: 1, label: 'Artworks' }]} getActive={(index) => setActiveTab(index)} />
                        
                        <CheckFilter 
                            arr={filterProfile} onClick={(item) => setActiveFilterId(item?.id)} 
                            className={`adjustment-end text-uppercase mt-3 mb-4 ${textColor}`} activeId={activeFilterId}
                        />

                        <Row className="mb-3">
                            <LoadData loading={loading} arr={collection} msg={`No artworks found.`}>
                                { collection.map((item, i) => (
                                    <Col xs={12} sm={12} md={6} lg={4} xl={3} key={i} className="mb-4">
                                        <CombineListItem 
                                            artId={item?._id} item={item}
                                            artwork_url={item?.artwork_url} artwork_type={item?.artwork_type} artworkName={item?.name} 
                                            openForSale={item?.openForSale} isAuctionOpen={item?.isAuctionOpen} artName={item?.name} 
                                            artPrice={item?.price} endTime={item?.auction?.endTime} numberOfLikes={item?.numberOfLikes} 
                                            owner={item?.owner} creater={item?.creater} usd={usd}
                                            history={history} isDark={theme === 'dark'} 
                                        />
                                    </Col>
                                )) }   
                            </LoadData>
                        </Row>
                    </Col>

                    {
                        userArtCount > 8 &&
                        <Pagination isDark={state?.theme === 'dark'} 
                            nextClick={() => setPage({ ...page, page: page.page + 1 })} total={userArtCount}
                            backClick={() => setPage({ ...page, page: page.page - 1 })} perPage={8} textColor={textColor}
                            activeIndex={page.page} changePage={(i) => setPage({ ...page, page: i })} 
                        />
                    }

                </Row>

                <AlertPopup 
                    data={{ show: err?.show, title: err.title, message: err?.message, type: err?.type }}
                    handleClose={() => {
                        setErr({ ...err, show: false });
                        if(err.type === "success"){
                            getUser();
                        }
                    }}
                />

            </RenderPage>

        </DocumentTitle>
    );

}
 
export default PublicProfile;