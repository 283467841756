import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Heading } from "components/Heading/Heading";
import FlexColumn from "components/FlexColumn/FlexColumn";
import ProductDetailPopupItem from "./PopupItem";
import AlertPopup from "components/AlertPopup/AlertPopup";
import { buyNft, cancelNftSale } from "services/contract.service";
import Web3 from "web3";
import { convertToWei, convertToEther } from "utilities/dateValid";
import { ArtworkPrice } from "components/ArtworkPrice/ArtworkPrice";
import ProcessWaiting from "components/ProcessWaiting/ProcessWaiting";

const PurchaseProduct = (props) => {
  
  const { activeWallet } = useSelector((state) => state);

  const {
    history,
    src,
    name,
    price,
    usd,
    contractSaleId,
    purchased,
    userAddress,
    type,
    currentArtwork,
    isMeta,
    refreshState,
    thumbNail_url,
  } = props;

  const [loading, setLoading] = useState(false);
  const [fetchBalance, setFetchBalance] = useState(true);
  const [userBalance, setUserBalance] = useState(0);
  const [err, setErr] = useState({
    show: false,
    type: "",
    message: "",
    title: "",
  });
  const [processWaitingMsg, setProcessWaitingMsg] = useState("");

  useEffect(() => {
    getBalance();
  }, []);

  const getBalance = async () => {
    if (userAddress) {
      let web3Instance = await new Web3(window.ethereum);
      let bal = await web3Instance.eth.getBalance(userAddress);
      let ether = await web3Instance.utils.fromWei(bal, "ether");
      setUserBalance(Number(ether).toFixed(2));
      setFetchBalance(false);
    }
  };

  const purchase = () => {
    let p = currentArtwork?.sale ? currentArtwork?.sale?.price : price;
    if (!fetchBalance) {
      if (userBalance < parseFloat(convertToEther(p))) {
        setErr({
          show: true,
          type: "error",
          title: "Not Enough Balance In Your Wallet",
          message: ``,
        });
      } else {
        if (contractSaleId) {
          setLoading(true);
          setProcessWaitingMsg("Please wait while we buy this NFT");
          setErr({ show: false, ...err });

          let params = {
            saleId: contractSaleId,
            amount: convertToWei(convertToEther(p)),
            isMeta,
            activeWallet
          };

          Promise.all([buyNft(params)])
            .then((res) => {
              if (res?.status || res[0]?.status) {
                setLoading(false);
                setErr({
                  show: true,
                  type: "success",
                  title: "NFT Purchased Successfully",
                  message: ``,
                });
              }
            })
            .catch((err) => {
              setErr({
                show: true,
                type: "error",
                title: "NFT Not Purchased",
                message: ``,
              });
              setLoading(false);
            });
        } else {
          setErr({
            show: true,
            type: "error",
            title: "Contract Sale Id Not Found",
            message: ``,
          });
        }
      }
    }
  };

  const cancelSaleNft = () => {
    if (!fetchBalance) {
      setLoading(true);
      setProcessWaitingMsg("Please wait while we cancel your NFT on Sale");

      if (contractSaleId) {
        Promise.all([
          cancelNftSale({ saleId: contractSaleId, isMeta, activeWallet }),
        ])
          .then(() => {
            setLoading(false);
            setErr({
              show: true,
              type: "success",
              title: "NFT sale cancellation may take a few minutes",
              message: ``,
            });
          })
          .catch((err) => {
            setErr({
              show: true,
              type: "error",
              title: "There was an error in removing NFT from sale",
              message: ``,
            });
            setLoading(false);
          });
      } else {
        setErr({
          show: true,
          type: "error",
          title: "Contract Sale Id Not Found",
          message: ``,
        });
      }
    }
  };

  return (
    <ProductDetailPopupItem
      className="pt-5 position-relative"
      src={src}
      btnText={purchased ? "Confirm" : "Cancel"}
      btnContainerClass="text-center"
      type={type}
      columnLayout={true}
      thumbNail_url={thumbNail_url}
      onClick={() => (purchased ? purchase() : cancelSaleNft())}
      loading={loading}
    >
      <FlexColumn className="">
        <Heading className="font-30px text-black mt-4 mb-2 w-100 text-center">
          {purchased ? `Confirm purchase of ${name}` : `Cancel Sale of ${name}`}
          {purchased && (
            <ArtworkPrice
              fontSize="font-18px"
              item={currentArtwork}
              isDark={false}
              usd={usd}
              edition={true}
              className="text-center align-items-center"
              isMeta={isMeta}
            />
          )}
        </Heading>
      </FlexColumn>

      <AlertPopup
        data={{
          show: err?.show,
          title: err.title,
          message: err?.message,
          type: err?.type,
        }}
        handleClose={() => {
          setErr({ ...err, show: false });
          if (err.type === "success") {
            refreshState();
            // history?.replace({ pathname: history?.location?.state, state: undefined });
          }
        }}
      />

      <ProcessWaiting show={loading} message={processWaitingMsg} />
    </ProductDetailPopupItem>
  );
};

export default PurchaseProduct;
