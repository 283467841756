import React from 'react';
import FlexRow from 'components/FlexRow/FlexRow';
import { metamaskExtensionsIcons, coinbaseExtensionsIcons } from 'Assets/Data';
import { Images } from 'Assets/Images';

import { FaTimes } from 'react-icons/fa';

export const InstallExtension = ({ isMetaMask, close }) => {

    const arr = isMetaMask ? metamaskExtensionsIcons : coinbaseExtensionsIcons;

    return(

        <div className="w-100 position-relative d-flex flex-column align-items-center justify-content-center bg-grey-50 py-4 rounded-lg">

            <FaTimes className='position-absolute cursor-pointer-sort font-20px font-weight-bold'
             style={{ top: 16, right: 16 }} onClick={close} />

            <img alt={ isMetaMask ? 'Metamask' : 'Coinbase' } src={ isMetaMask ? Images.metamask : Images.coinbase } width="50px" />

            <h1 className="font-25px w-75 text-center mt-3 mb-4">
                To begin install your { isMetaMask ? 'Metamask' : 'Coinbase' } wallet by selecting your browser type
            </h1>

            { arr.map((item, i) => (
                <FlexRow className="align-items-center justify-content-start cursor-pointer-sort w-auto my-2" key={item.id} 
                    onClick={() => window.open(item.link, '_blank')} 
                >
                    <img alt={item.value} src={item.icon} className="mr-3" width="35px" 
                        title={item.value}
                    />                
                    <span className="font-18px" style={{ width: '130px' }}>
                        <b>{ item?.value }</b>
                    </span>
                </FlexRow>
            )) }

            

        </div>

    );

};