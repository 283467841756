import React, { useState } from 'react';
import FlexRow from 'components/FlexRow/FlexRow';
import { MyLoader } from 'components/ContentLoader/ContentLoader';
import BorderButton from 'components/BorderButton/BorderButton';
import UserFollowersFollowings from 'screens/Modals/FollowersFollowings';
import { LargeModal } from 'components/LargeModal/LargeModal';
import { Images } from 'Assets/Images';
import { BiArrowBack } from 'react-icons/bi';

const ProfileInformation = (props) => {
    
    const { src, name, role, bio, userArts, loading, user, textColor, followers, following, userId, openUserArtTab, isDark } = props;

    const { clickFollowBtn, btnText, publicProfile, openModal, showFollowBtn, showArtObjects, reset, openEditProfile } = props;

    const [ withModal, setWithModal ] = useState({ flag: false, title: null });    

    const btns = [
        { id: 1, text: 'Followers', count: followers, condition: true, onClick: () => setWithModal({ flag: true, title: 'Followers' }) },
        { id: 2, text: 'Followings', count: following, condition: true, onClick: () => setWithModal({ flag: true, title: 'Followings' }) },
        { id: 3, text: 'Artworks', count: userArts, condition: role?.toLowerCase() === 'artist', 
        onClick: (e) => publicProfile ? e.preventDefault() : openUserArtTab() }
    ];

    const leftBtns = [
        { id: 1, text: btnText, condition: (publicProfile && showFollowBtn), onClick: (t) => clickFollowBtn(t) },
        { id: 2, text: 'Edit your profile', condition: !publicProfile, onClick: () => openEditProfile() },
        { id: 2.1, text: 'Set Royalty', condition: !publicProfile, onClick: (t) => openModal(t) },
        { id: 3, text: 'Notification', count: true, condition: !publicProfile, onClick: (t) => openModal(t) },
        // { id: 4, text: 'Create Collection', condition: (!publicProfile && showCollectionBtn), onClick: (t) => openModal(t) },
        // { id: 6, text: 'Set Royalty', condition: (!publicProfile && showArtObjects), onClick: (t) => openModal(t) },
    ];

    if(loading)
        return <MyLoader />;

    else if(!user)
        return <div className={`font-clashRegular font-15px mt-4 ${textColor}`}>User not found.</div>    

    return(

        <div className="profile-setup position-relative m-0">
            
            <img alt="" src={(src && src !== 'undefined') ? src : Images.uLogo} className="rounded-circle" />

            <span className={`font-28px d-block text-capitalize font-machinaRegular ${textColor}`}>{ name }</span>

            { bio && <span className={`font-18px font-clashRegular w-75 text-grey-20 ${textColor} text-break text-center`}>{ bio }</span> }

            <div className="d-flex flex-nowrap flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row align-items-center justify-content-start mt-4 w-auto">
                {
                    leftBtns?.map((btn, i) => (
                        btn.condition &&
                        <div className="position-relative">
                            <BorderButton title={btn.text} className={`bg-transparent rounded mouse-hover border-pink border outline-0 outline-none profile-btns
                                font-15px font-clashRegular mb-2 ${isDark ? 'text-white' : 'text-black'} font-weight-bold`} 
                                onClick={(t) => btn.onClick(t)} key={i} 
                            />

                            {/* { btn?.count && 
                            <span className="bg-pink rounded-circle app-flex-column position-absolute
                            p-0 align-items-center justify-content-center font-13px text-white"
                            style={{ width: 12, height: 12, top: -4, right: 8 }}>
                            </span> } */}
                            
                        </div>
                    ))
                }
            </div>

            <FlexRow className="flex-wrap align-items-center justify-content-center pt-3 pb-2 profile-followers" style={{ borderBottom: '1px solid grey' }}>
                {
                    btns?.map((btn, i) => (
                        btn.condition &&
                        <div className={`align-items-center justify-content-center px-4 border-0 text-white mb-2
                            font-clashRegular cursor-pointer-sort mouse-hover ${textColor}`}
                            onClick={btn?.onClick} key={i}
                        >
                            <span className="py-2 font-23px mr-2 font-weight-bold">{ btn?.count ? btn?.count : 0 }</span>
                            <span className="font-18px">{ btn?.text }</span>
                        </div>
                    ))
                }
            </FlexRow>

            { (!publicProfile && showArtObjects) && 
            <FlexRow className="align-items-center justify-content-start mt-4 m-0 font-15px text-lightPurple cursor-pointer-sort w-auto mr-auto" 
                onClick={() => reset('Back to Collection')}
            >
                <span> <BiArrowBack className="font-23px " /> </span>
                <span className="ml-2 underline text-underline"> Back to Collection </span>
            </FlexRow> }

            {
                withModal.flag &&
                <LargeModal 
                    condition={withModal.flag} title={withModal.title} size={2}
                    handleClose={() => setWithModal({ flag: false, title: null })}
                >
                    <UserFollowersFollowings userId={userId} followersModal={withModal.title === 'Followers'} />
                </LargeModal>
            }

        </div>

    );

}
 
export default ProfileInformation;