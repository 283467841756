import React, { useState } from 'react';
import { Heading } from 'components/Heading/Heading';
import FlexRow from 'components/FlexRow/FlexRow';
import { Div } from 'components/Div/Div';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import RadioButton from 'components/RadioButton/RadioButton';
import { Span } from 'components/Span/Span';
import { SpinnerLoader } from 'components/Spinner/Spinner';

const Settings = (props) => {
    
    const [loading, setLoading] = useState(false);

    const [values, setValues] = useState({ flag1: false, flag2: false, flag3: false, flag4: false });

    const onChange = (name, value) => {
        setValues({ ...values, [name]: value });
    };

    const saveSettings = () => {
        setLoading(true);  
    };

    return (

        <FlexColumn className="w-90 mx-auto">

            <Heading className="mb-3 text-black d-block font-18px">
                Email Notifications
            </Heading>

            <FlexRow className="w-100 align-items-center justify-content-between mb-2">
                <Heading className="text-black font-14px font-normal">
                    Transaction-based notifications
                </Heading>
                <RadioButton flag={values.flag1} onClick={() => onChange('flag1', !values.flag1)} />
            </FlexRow>

            <FlexRow className="w-100 align-items-center justify-content-between mb-2">
                <Heading className="text-black font-14px font-normal">
                    New follower notifications
                </Heading>
                <RadioButton flag={values.flag2} onClick={() => onChange('flag2', !values.flag2)} />
            </FlexRow>

            <FlexRow className="w-100 align-items-center justify-content-between mb-2">
                <Heading className="text-black font-14px font-normal">
                    New artwork notifications
                </Heading>
                <RadioButton flag={values.flag3} onClick={() => onChange('flag3', !values.flag3)} />
            </FlexRow>

            <FlexRow className="w-100 align-items-center justify-content-between mb-4">
                <Heading className="text-black font-14px font-normal">
                    New likes notifications
                </Heading>
                <RadioButton flag={values.flag4} onClick={() => onChange('flag4', !values.flag4)} />
            </FlexRow>

            <Heading className="mb-3 text-black d-block font-18px">
                Minimum Bid
            </Heading>

            <FlexRow className="w-100 align-items-center justify-content-start mb-4">
                <Heading className="text-black font-14px mr-4 font-normal">
                    Require incoming bids to be at least
                </Heading>
                <Heading className="text-black font-16px px-5" 
                    style={{ borderBottom: '1px solid rgba(1, 1, 1, 0.4)' }}>
                    0ETH
                </Heading>
            </FlexRow>

            <Heading className="mb-3 text-black d-block font-18px">
                Transaction History
            </Heading>

            <FlexRow className="w-100 align-items-center justify-content-start mb-4">
                <Span className="font-13px font-normal text-underline text-skyblue">
                    Download Transaction History
                </Span>
            </FlexRow>

            <Div className="text-center d-block">
                <button onClick={saveSettings}
                    className="text-white bg-lightPurple border-0 outline-none font-16px rounded normal-btn" 
                >
                    { loading ? <SpinnerLoader size="sm" /> : 'Save' }
                </button>
            </Div>

        </FlexColumn>

    );

}
 
export default Settings;