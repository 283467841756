import React from 'react';
import { NavLink } from 'react-router-dom';

const NavItem = ({ route, value, color, clickAble }) => {
    
    if(clickAble){
        return(
            <NavLink exact={true} to={`/${route}`} className={`nav-links cursor-pointer-sort ${color} ${value ? '' : 'px-3'}`} activeClassName="active-link">
                { value }
            </NavLink>
        );
    } else {
        return(
            <span className={`nav-links ${color} ${value ? '' : 'px-4'}`}>
                { value }
            </span>
        );
    }

}
 
export default NavItem;