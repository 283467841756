import React, { useState, useCallback, useEffect } from "react";
import "./SubmitNft.css";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { SpinnerLoader } from "components/Spinner/Spinner";
import { getAllOpenCollection } from "services";
import { useParams, Redirect } from "react-router";
import withProtected from "hoc/Layout/Protected/Protected";
import { Routes } from "Routes/Routes";
import DocumentTitle from "components/DocumentTitle/DocumentTitle";
import { appRoles } from "Assets/Data";
import UploadFile from "./UploadFile";
import BorderButton from "components/BorderButton/BorderButton";
import {
  SaveArt,
  deleteArtService,
} from "services/artService";
import { mintArtwork, mintFee } from "services/contract.service";
import AlertPopup from "components/AlertPopup/AlertPopup";
import { goToSection, matchFileArtwork } from "utilities/CommonMethods";
import RenderPage from "components/RenderPage/RenderPage";
import ProcessWaiting from "components/ProcessWaiting/ProcessWaiting";
import { SubmitNFTForm } from "./Form";

const SubmitNFT = (props) => {
  const activeWallet = useSelector((state) => state.activeWallet);
  const {
    state: {
      theme,
      user: { info },
    },
    history,
  } = props;
  const textColor =
    theme === "dark" ? "text-white bg-darkBlack" : "text-black bg-white";

  const { collectionId, albumId } = useParams();

  const [usd, setUsd] = useState({ bitcoin: 0, eth: 0, matic: 0  });
  const [values, setValues] = useState(undefined);
  const [collections, setCollections] = useState([]);
  const [file, setFile] = useState(undefined);
  const [thumbnail, setThumbnail] = useState(undefined);

  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [err, setErr] = useState({
    show: false,
    type: "",
    message: "",
    title: "",
  });

  useEffect(() => {
    if (collectionId) {
      goToSection('submit-nft');
      getCollections();
    }
  }, [collectionId]);

  useEffect(() => {
    if (file) {
      const fileSize = file?.file?.size / 1024 / 1024;

      if (
        matchFileArtwork(
          file?.type?.toLowerCase(),
          collections[0]?.symbol?.toLowerCase()
        )
      ) {
        setFile(undefined);
        setErr({
          show: true,
          type: "error",
          title: "Please upload the correct file type.",
          message: ``,
        });
      } else if (file?.type === "video" && fileSize > 20) {
        setFile(undefined);
        setErr({
          show: true,
          type: "error",
          title: "Maximum Video size 20 MB",
          message: ``,
        });
      } else {
        setValues({ ...values, category: file?.type });
      }
    }
  }, [file]);

  useEffect(() => {
    if (thumbnail) {
      if (thumbnail?.type?.toLowerCase() !== "image") {
        setThumbnail(undefined);
        setErr({
          show: true,
          type: "error",
          title: "Please upload the image file.",
          message: ``,
        });
      }
    }
  }, [thumbnail]);

  const getCollections = () => {
    getAllOpenCollection()
      .then((res) => {
        setCollections(
          res?.data?.data?.filter((item) => item?._id === collectionId)
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setCollections([]);
      });
  };

  const createNft = () => {
    const { name, price, desc, geners, isMeta } = values;

    let obj = {
      creater: info?._id,
      isMeta,
      collectionId: collectionId,
    };

    const checkGener =
      collections[0]?.symbol?.toLowerCase() === "art" ||
      collections[0]?.symbol?.toLowerCase() === "gif";

    if (!name || !price || !desc || !file) {
      setErr({
        show: true,
        type: "error",
        title: `Fields With (*) Are Required`,
        message: "",
      });
    } else if (
      matchFileArtwork(
        file?.type?.toLowerCase(),
        collections[0]?.symbol?.toLowerCase()
      )
    ) {
      setErr({
        show: true,
        type: "error",
        title: "Please Upload The Correct File Type.",
        message: ``,
      });
    } else if (!checkGener && !geners) {
      setErr({
        show: true,
        type: "error",
        title: `Fields With (*) Are Required`,
        message: "",
      });
    } else {
      obj["name"] = name;
      obj["description"] = desc;
      obj["price"] = price;
      obj["artwork_type"] = file?.type;
      obj["image"] = file?.file;

      if (!checkGener) {
        obj["genre"] = geners?.value;
      }

      if (values?.youtube) {
        obj["youtubeLink"] = values?.youtube;
      }

      if (values?.web) {
        obj["websiteLink"] = values?.web;
      }

      if (values?.spotify) {
        obj["spotifyLink"] = values?.spotify;
      }

      if (values?.itune) {
        obj["ituneLink"] = values?.itune;
      }

      if (values?.instagram) {
        obj["instagramLink"] = values?.instagram;
      }

      if (values?.tiktok) {
        obj["tiktokLink"] = values?.tiktok;
      }

      const currentCollection = collections?.find(
        (item) => item?._id === collectionId
      );

      if (
        collections[0]?.symbol?.toLowerCase() === "music" ||
        collections[0]?.symbol?.toLowerCase() === "audio"
      ) {
        if (thumbnail) {
          obj["artwork_thumbnail_image"] = thumbnail?.file;
          obj["isAudioNFT"] = true;

          if (albumId) {
            obj["albumId"] = albumId;
          }

          if (currentCollection) {
            setSaveLoading(true);
            saveArtWork(obj, currentCollection);
          }
        } else {
          setErr({
            show: true,
            type: "error",
            title: "Please Upload The Thumbnail File.",
            message: ``,
          });
        }
      } else {
        if (currentCollection) {
          setSaveLoading(true);
          saveArtWork(obj, currentCollection);
        }
      }
    }
  };

  const saveArtWork = (obj, currentCollection) => {
    SaveArt(obj)
      .then(async (data) => {

        let params = {
          address: info?.address,
          // signature: data?.data?.signature,
          ipfsUrl: data?.data?.updatedArtwork?.meta_url,
          mintPrice: data?.data?.price,
          isMeta: obj.isMeta,
          activeWallet,
          txIdentifier: data?.data?.txIdentifier.toString(),
          priceSignature: data?.data?.priceSignature
        };

        Promise.all([mintArtwork(params)])
          .then((res1) => {
            Promise.all([mintFee(params)])
            .then((res2) => {
              setSaveLoading(false);
              setErr({
                show: true,
                type: "success",
                title: "NFT Successfully Minted.",
                message: "",
              });
            })
            .catch((err) => {
              console.log(err);
              deleteArt(data?.data?.updatedArtwork?._id);
            });            
          })
          .catch((err) => {
            console.log(err);
            deleteArt(data?.data?.updatedArtwork?._id);
          });
      })
      .catch((err) => {
        showErr(err);
      });
  };

  const showErr = (err) => {
    setSaveLoading(false);
    setErr({
      show: true,
      type: "error",
      title: "Error in Minting NFT.",
      message: "",
    });
  };

  const goToRoute = (state) => {
    history.replace({ pathname: `/${Routes.profile}`, state: state });
  };

  const deleteArt = (id) => {
    const obj = { artworkId: id };

    deleteArtService(obj)
      .then((res) => {
        setSaveLoading(false);
      })
      .catch((err) => {
        showErr(err);
      });
  };

  if (collectionId && info?.role?.toLowerCase() === appRoles[1]) {
    return (
      <DocumentTitle title="Submit Your NFT">
        <RenderPage
          className={`${textColor} position-relative`}
          title="Upload NFT"
          id="submit-nft" setUsd={(usd) => setUsd(usd)}
        >
          {loading ? (
            <div className={`p-5 font-16px d-block text-center ${textColor}`}>
              Loading collection ...
            </div>
          ) : collections.length === 0 ? (
            <div className="p-5 font-16px d-block text-center text-red">
              Collection not found.
            </div>
          ) : (
            <Row className="pt-2 pb-5 padding-section">
              <Col
                xs={12}
                sm={12}
                md={{ span: 10, offset: 1 }}
                lg={{ span: 8, offset: 2 }}
                xl={{ span: 8, offset: 2 }}
                className="left-side-inner-space"
              >
                <BorderButton
                  title="Back to Collection"
                  onClick={(t) => goToRoute(0)}
                  className={`bg-transparent rounded mouse-hover border-pink border outline-0 outline-none profile-btns
                                    font-15px font-clashRegular mb-2 ${
                                      theme === "dark"
                                        ? "text-white"
                                        : "text-black"
                                    } font-weight-bold position-absolute
                                    d-md-block d-lg-block d-xl-block d-none
                                `}
                  style={{ left: 14, top: -95 }}
                />

                <BorderButton
                  title="Back to Collection"
                  onClick={(t) => goToRoute(0)}
                  className={`bg-transparent rounded mouse-hover border-pink border outline-0 outline-none profile-btns
                                    font-15px font-clashRegular mb-4 ${
                                      theme === "dark"
                                        ? "text-white"
                                        : "text-black"
                                    } font-weight-bold
                                    d-md-none d-lg-none d-xl-none w-auto
                                `}
                />

                {collections[0]?.symbol?.toLowerCase() === "music" ||
                collections[0]?.symbol?.toLowerCase() === "audio" ? (
                  <Row>
                    <Col xs={12} sm={12} md={6} lg={6}>
                      <label className="d-block font-18px mb-2">
                        Upload Track Cover Art
                      </label>
                      <UploadFile
                        getFile={(file) => setThumbnail(file)}
                        isDark={theme === "dark"}
                        bgColor={
                          theme === "dark" ? "bg-grey50light" : "bg-grey-50"
                        }
                        selectedFile={thumbnail}
                      />
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                      <label className="d-block font-18px mb-2">
                        Upload music file
                      </label>
                      <UploadFile
                        getFile={(file) => setFile(file)}
                        isDark={theme === "dark"}
                        bgColor={
                          theme === "dark" ? "bg-grey50light" : "bg-grey-50"
                        }
                        selectedFile={file}
                      />
                    </Col>
                  </Row>
                ) : (
                  <UploadFile
                    getFile={(file) => setFile(file)}
                    isDark={theme === "dark"}
                    bgColor={theme === "dark" ? "bg-grey50light" : "bg-grey-50"}
                    selectedFile={file}
                  />
                )}

                <SubmitNFTForm
                  theme={theme}
                  file={file}
                  collections={collections}
                  usd={usd}
                  getData={(v) => setValues(v)}
                  removeGenreField={
                    collections[0]?.symbol?.toLowerCase() === "art" ||
                    collections[0]?.symbol?.toLowerCase() === "gif"
                  }
                />

                <button
                  className="bg-button gradient text-white border-0 rounded mx-auto font-18px"
                  onClick={createNft}
                  disabled={saveLoading}
                  style={{ width: 140, height: 47 }}
                >
                  {saveLoading ? <SpinnerLoader size="sm" /> : "Mint"}
                </button>
              </Col>
            </Row>
          )}

          <AlertPopup
            data={{
              show: err?.show,
              title: err.title,
              message: err?.message,
              type: err?.type,
            }}
            handleClose={() => {
              setErr({ ...err, show: false });
              if (err.type === "success") {
                goToRoute(1);
              }
            }}
          />

          <ProcessWaiting
            show={saveLoading}
            message="Please wait while we mint your NFT."
          />
        </RenderPage>
      </DocumentTitle>
    );
  } else return <Redirect exact to={Routes.main} />;
};

export default withProtected(SubmitNFT, Routes.empty);
