import { Images } from 'Assets/Images';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import FlexRow from 'components/FlexRow/FlexRow';
import React from 'react';

export const ArtistItem = (props) => {

    const { className, textColor, item, isDark, onClick } = props;

    return(

        <FlexColumn className={`${className} rounded p-3 align-items-center justify-content-center w-100 app-flex-column`} 
            onClick={() => onClick(item?.user?.id)}
            style={{ height: '250px' }}
        >
            
            {/* <FlexRow className={`align-items-center justify-content-around w-100 flex-nowrap ${item?.user?.artworks?.length ? '' : 'pt-3'}`}>
                { item?.user?.artworks?.length ? item?.user?.artworks?.slice(0, 4)?.map((art, i) => (
                    art?.artwork_type === 'image' ?
                        <img alt="" width="23%" height="120px" src={art?.artwork_url} className="rounded" /> :
                    art?.artwork_type === 'audio' ?
                        <div style={{ background: `url(${Images.musicBg})`, width: '23%', height: '120px', objectFit: 'contain' }} 
                            className="app-flex-column justify-content-end rounded">
                            <audio muted loop autoPlay controls src={art?.artwork_url} className="w-100">
                                <source type="audio/*" />
                            </audio>
                        </div>
                        : null
                )) : null }
            </FlexRow> */}

            <div 
                style={{ transform: 'translateY(-20px)', width: '100px', height: '100px' }} 
                className={`rounded-circle app-flex-column align-items-center justify-content-center ${isDark ? 'bg-white' : 'bg-black'}`} 
            >
                <img alt="" className="rounded-circle" width="92px" height="92px" src={item?.user?.profilePic ? item?.user?.profilePic : Images.uLogo} />
            </div>
            <span className={`font-23px font-clashRegular ${textColor} mb-1`}> {item?.user?.userName} </span>
            
            { item?.user?.bio && 
            <span className={`font-15px ${textColor} mb-1 d-block text-center font-clashRegular px-2 break-lines`} title={item?.user?.bio ? item?.user?.bio : ''}>
                { item?.user?.bio }
            </span> }

        </FlexColumn>

    );

}