import React from 'react';
import { Spinner } from "react-bootstrap";

export const SpinnerLoader = ({ size, className, color, animation }) => {
    return (
        <Spinner animation={animation ? animation : "border"} 
            role="status" size={size} className={className} color={color ? color : 'white'}>
            <span className="sr-only">Loading...</span>
        </Spinner>
    );
}