import { Div } from 'components/Div/Div';
import React from 'react';

const RadioButton = (props) => {
    
    const { flag, onClick } = props;

    return(

        <Div 
            style={{ width: '53px', height: '25px' }} 
            className={`rounded-pill bg-grey-50 cursor-pointer-sort justify-content-center py-0 d-flex flex-column
                ${ flag ? 'align-items-end' : 'align-items-start' }
            `}
            onClick={() => onClick()}
        >
            <Div style={{ width: '24px', height: '100%' }} className="bg-lightPurple rounded-circle">

            </Div>
        </Div>

    );

}
 
export default RadioButton;