import React from 'react';
import './CustomModal.css';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';

const CustomModal = (props) => {
    
    const { show, handleClose, children, size } = props;

    const { theme } = useSelector(state => state);

    return (

        <Modal 
            show={show} onHide={handleClose} centered
            keyboard={false}
            backdrop="static"
            size={ size === 0 ? 'xl' : size === 1 ? 'lg' : size === 2 ? 'lg' : size === 4 ? 'sm' : 'md' }
            style={{ background: theme === 'dark' ? 'rgba(0, 0, 0, .65)' : 'rgba(255, 255, 255, .40)' }}
        >

            { children }

        </Modal>

    );

}
 
export default CustomModal;