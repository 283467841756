import React from 'react';
import './Pagination.css';
import FlexRow from 'components/FlexRow/FlexRow';
import { Span } from 'components/Span/Span';
import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi';

const Pagination = (props) => {
    
    const { total, perPage, className, nextClick, backClick, activeIndex, changePage, textColor } = props;

    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(total / perPage); i++) {
        pageNumbers.push(i);
    }

    const pagination = (c, m) => {
        var current = c,
            last = m,
            delta = 2,
            left = current - delta,
            right = current + delta + 1,
            range = [],
            rangeWithDots = [],
            l;
    
        for (let i = 1; i <= last; i++) {
            if ((i === 1 || i === last || i >= left) && i < right) {
                range.push(i);
            }
        }
    
        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }
    
        return rangeWithDots;
    }

    return(

        <FlexRow className={`w-100 flex-wrap align-items-center justify-content-center bg-transparent py-1 px-2 ${className}`}>
            
            <button disabled={!(activeIndex > 0)} 
                className={`border-0 remove-text-btns bg-transparent rounded px-3 py-1 mr-2 font-16px cursor-pointer-sort ${textColor}`} onClick={backClick}>
                Back
            </button>

            <button disabled={!(activeIndex > 0)} 
                className="text-white border-0 bg-lightPurple show-icon-btn rounded px-2 py-1 mr-2 font-16px cursor-pointer-sort" onClick={backClick}>
                <HiChevronDoubleLeft />
            </button>

            {
                pagination(activeIndex+1, pageNumbers.length).map((item, i) => (
                    <Span className={`font-14px cursor-pointer-sort mr-3 px-3 rounded
                    ${ i === activeIndex ? 'text-white border-0 bg-lightPurple py-2 font-weight-bold' : 
                    `p-2 ${textColor} border-grey-60 border font-normal` } `} onClick={() => changePage(i)} key={i}>
                        { item }
                    </Span>
                ))
            }

            <button disabled={!(activeIndex+1 < (total / perPage))}
                className={`border-0 bg-transparent rounded remove-text-btns px-3 py-1 mr-4 font-16px cursor-pointer-sort ${textColor}`} 
                onClick={nextClick}>
                Next
            </button>

            <button disabled={!(activeIndex+1 < (total / perPage))}
                className="text-white border-0 bg-lightPurple rounded show-icon-btn px-2 py-1 mr-4 font-16px cursor-pointer-sort" 
                onClick={nextClick}>
                <HiChevronDoubleRight />
            </button>

        </FlexRow>

    );

}
 
export default Pagination;