import React from 'react';
import CustomModal from "../CustomModal/CustomModal";
import LayoutWrapper from 'hoc/Layout/LayoutWrapper/LayoutWrapper';
import { Div } from '../Div/Div';
import { Heading } from '../Heading/Heading';

export const LargeModal = (props) => {

    const { condition, children, handleClose, title, size, removePaddingBottom } = props;

    if(condition){
        return (
            <CustomModal show={condition} handleClose={handleClose} size={size ? size : 0}>
                
                <div className="w-100 position-relative app-flex-column">

                    <LayoutWrapper src={null} handleClose={handleClose}>

                        <Div className={`px-5 text-black w-100 rounded ${removePaddingBottom ? '' : 'pb-5'}`}>
                            
                            { (title && title !== null) && <Heading className="d-block text-center font-30px pt-5">
                                { title }
                            </Heading> }

                            { children }

                        </Div>

                    </LayoutWrapper>
                
                </div>

            </CustomModal>
        );
    }

    else return null;

}