import React, { useEffect, useState } from "react";
import "./TopBar.css";
import { NavLink, useHistory } from "react-router-dom";
import { Images } from "Assets/Images";
import { Routes } from "Routes/Routes";
import { topBarContent, topBarContentRightSideWithAuth, mobileNavDisplay } from "Assets/Data";
import { Nav, Navbar } from "react-bootstrap";
import NavItem from "./nav-item";
import { useSelector, useDispatch } from "react-redux";
import { FaSearch } from "react-icons/fa";
import { isLogoutAction, isChangeTheme } from "redux/actions/action";
import LogoutPopup from "components/LogoutPopup/LogoutPopup";
import { ToggleSwitch } from "components/ToggleSwitch/ToggleSwitch";
import { goToSection } from "utilities/CommonMethods";
import { MobileNav } from './MobileNav';
import { ETH_CONTRACTS, POLYGON_CONTRACTS } from 'utilities/AppEndpoint';
import { getAccounts } from 'services/contract.service';
import { checkUserExist } from './../../screens/SignInUp/network';

const TopBar = (props) => {

  const { clickLink } = props;
  const history = useHistory();

  // redux setups
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const adminAddress = [ETH_CONTRACTS.ADMIN_ADDRESS, POLYGON_CONTRACTS.ADMIN_ADDRESS].map(add => add?.toLowerCase());

  const [address, setAddress] = useState(undefined);
  const [logout, setLogout] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [hasUser, setHasUser] = useState(undefined);

  useEffect(() => {
    async function fetchAccounts(){
        const accounts = await getAccounts();

        if(accounts?.length){

          setAddress(accounts[0]);

          checkUserExist({ address: accounts[0] }, (res) => {
            setHasUser(res?.data);
          });
        }
    }

    fetchAccounts();
  }, []);

  useEffect(() => {

    const interval = setInterval(() => {
      listenToScroll();
    }, 700);

    return () => clearInterval(interval);
  }, [state]);

  const listenToScroll = () => {
      const topBarBg = state?.theme === "dark" ? 'bg-black' : 'bg-pinkShade';

      if (document.body.scrollTop > 118) {
        if(document.getElementById("app-nav-bar")){
          document.getElementById("app-nav-bar").className = `${topBarBg} navbar navbar-expand-lg navbar-light fixed-top padding-section font-clashRegular`;
        }
      } else {
        if(document.getElementById("app-nav-bar")){
          document.getElementById("app-nav-bar").className = "bg-transparent navbar navbar-expand-lg fixed-top padding-section font-clashRegular";
        }
      }
  }

  return (
    
    <Navbar
      collapseOnSelect expand="lg" id="app-nav-bar"
      className="navbar navbar-expand-lg fixed-top padding-section font-clashRegular"
    >
      <NavLink to={Routes.main}>
        <img alt="" src={ state?.theme === 'dark' ? Images.logoDark : Images.logoLight } className="mr-4 app-logo" />
      </NavLink>

      <MobileNav className="show-mobile-nav ml-auto" getSignIn={() => clickLink(3)} getLogout={() => setLogout(true)}
        userAvatar={state?.user?.info ? (state?.user?.info?.profilePic ? state?.user?.info?.profilePic : Images.uLogo) : Images.uLogo}
        isLogin={state?.user?.isLogin} display={mobileNavDisplay} menus={topBarContent} 
        showDashboard={(state?.user?.isLogin && state?.address[0] && adminAddress.includes(state?.address[0].toLowerCase()) )}
        isDark={state?.theme === "dark" ? true : false} isChangeTheme={(th) => dispatch(isChangeTheme(th))}
        mainBtnText={ (hasUser && address) ? 'Sign In' : (!hasUser && address) ? 'Get Started' : 'Connect' }
      />

      <Navbar.Toggle
        className={`bg-grey-30 border-0 d-none`}
        onClick={() => setShowMenu(!showMenu)}
      />

      <Navbar.Collapse>
        <Nav className="navbar-nav">
          {topBarContent.map((link, i) => (
            <Nav.Item key={i} onClick={() => setTimeout(() => {
                goToSection(link.sectionId);
              }, 1000)}
            >
              <NavItem
                {...link} clickAble={true}
                color={state?.theme === "dark" ? 'text-white' : 'text-black'}
              />
            </Nav.Item>
          ))}

          <Nav.Item
            className={`nav-links cursor-pointer-sort ${state?.theme === "dark" ? 'text-white' : 'text-black'}`}
            onClick={() => window.open('https://Marvment.com', '_blank')}
          >
            Music Distribution
          </Nav.Item>

          { (state?.user?.isLogin && state?.address?.length && state?.address[0]?.toLowerCase() === ETH_CONTRACTS.ADMIN_ADDRESS?.toLowerCase()) &&
            <Nav.Item>
              <NavItem {...topBarContentRightSideWithAuth[1]} clickAble={true}
                color={state?.theme === "dark" ? 'text-white' : 'text-black'}
              /> 
            </Nav.Item> 
          }

          <Nav.Item
            className="nav-links"
            onClick={() => history.push(`/${Routes.search}`)}
          >
            <FaSearch className={`cursor-pointer-sort ${state?.theme === "dark" ? "text-white" : "text-black"}`} />
          </Nav.Item>

          <Nav.Item className="nav-links flex-shrink-0 flex-grow-0">
            <ToggleSwitch
              className="toggleSwitch cursor-pointer-sort"
              isDark={state?.theme === "dark" ? true : false}
              changeTheme={(th) => dispatch(isChangeTheme(th))}
            />
          </Nav.Item>

          <Nav.Item onClick={() => setTimeout(() => {
              goToSection(topBarContentRightSideWithAuth[0].sectionId);
            }, 1000)}
          >
            { state?.user?.isLogin ? 
              <NavItem {...topBarContentRightSideWithAuth[0]} clickAble={true}
                color={state?.theme === "dark" ? 'text-white' : 'text-black'}
              /> : <NavItem clickAble={false}
                color={state?.theme === "dark" ? 'text-white' : 'text-black'}
            /> }

          </Nav.Item>

          {!state?.user?.isLogin &&
            <Nav.Item
              className="bg-button text-white py-2 rounded px-4 hover bg-pink font-weight-bold"
              onClick={() => clickLink(3)}
            >
              { (hasUser && address) ? 'Sign In' : (!hasUser && address) ? 'Get Started' : 'Connect' } 
            </Nav.Item> 
          }

          { state?.user?.isLogin && <Nav.Item
              className={`bg-button mr-0 px-4 rounded hover ${state?.theme === "dark" ? "bg-pink font-weight-bold text-white" : "bg-pink font-weight-bold text-white"}`}
              onClick={() => setLogout(true)}
            >
              Sign Out
            </Nav.Item> 
          }

        </Nav>
      </Navbar.Collapse>

      <LogoutPopup
        condition={logout}
        close={() => setLogout(false)}
        logout={() => {
          localStorage.clear();
          dispatch(isLogoutAction());
          setLogout(false);
          window.location.reload();
        }}
      />
    </Navbar>
  );
};

export default TopBar;
