import interceptor, { HTTP_CLIENT } from "utilities/intercepter";

const getAllUserArts = (obj) => {
  return HTTP_CLIENT.get(
    `artwork/getUserArtworks?page=${obj.page}&perPage=${obj.limit}&userId=${obj.userId}`
  );
};

const getAllUserArtFavourite = (obj) => {
  return HTTP_CLIENT.get(
    `artwork/getFavouriteArtworks?page=${obj.page}&perPage=${obj.limit}&userId=${obj.userId}`
  );
};

const SaveArt = (obj) => {
  let FormBody = new FormData();
  if (Object.keys(obj).length > 0) {
    Object.keys(obj).forEach((res) => {
      FormBody.append(`${res}`, obj[res]);
    });

    return HTTP_CLIENT.post(`artwork/saveArtwork`, FormBody);
  }
};

const getSingleArtService = (artId) => {
  return HTTP_CLIENT.get(`artwork/getSingleArtwork?artworkId=${artId}`);
};

const getOpenArtService = (obj) => {
  let link = `artwork/getOpenArtWorks?page=${obj.page}&perPage=${obj.limit}`;

  if(obj?.openForSale){
    link = `artwork/getOpenArtWorks?openForSale=TRUE&page=${obj.page}&perPage=${obj.limit}`;
  }
  if(obj?.isAuctionOpen){
    link = `artwork/getOpenArtWorks?isAuctionOpen=TRUE&page=${obj.page}&perPage=${obj.limit}`;
  }

  return HTTP_CLIENT.get(link);
};

const getOpenAuctionService = (obj) => {
  let link = `auction/getAuctionListing?page=${obj.page}&perPage=${obj.limit}`;

  if(obj?.openForSale){
    link = `auction/getAuctionListing?openForSale=TRUE&page=${obj.page}&perPage=${obj.limit}`;
  }
  if(obj?.isAuctionOpen){
    link = `auction/getAuctionListing?isAuctionOpen=TRUE&page=${obj.page}&perPage=${obj.limit}`;
  }
  
  return HTTP_CLIENT.get(link);
};

const getAllArtService = (obj) => {

  let link = `artwork/getAllArtWorks`;

  if((obj?.page === 0 || obj?.page > 0) && obj?.limit)
    link = `artwork/getAllArtWorks?page=${obj?.page}&perPage=${obj?.limit}`;

  if(obj?.openForSale){
    link = `artwork/getAllArtWorks?openForSale=TRUE&page=${obj?.page}&perPage=${obj?.limit}`;
  }
  if(obj?.isAuctionOpen){
    link = `artwork/getAllArtWorks?isAuctionOpen=TRUE&page=${obj?.page}&perPage=${obj?.limit}`;
  }

  return HTTP_CLIENT.get(link);
};

const getLatestArtworks = () => {
  return HTTP_CLIENT.get(`artwork/getLatestArtWorks`);
};

const increaseViewCountService = (artworkId) => {
  return HTTP_CLIENT.post(`artwork/increaseViewCount`, { artworkId });
};

const updateTokenIdOfArtwork = (params) => {
  return HTTP_CLIENT.post(`artwork/updateTokenId`, params);
};

const startAuctionService = (obj) => {
  return HTTP_CLIENT.post("artwork/openArtworkAuction", obj);
};

const mintAuction = (params) => {
  return HTTP_CLIENT.post("artwork/changeAuctionStatus", params);
};

const placeBidService = (obj) => {
  return HTTP_CLIENT.post("artwork/placeBid", obj);
};

const IncreaseViewCountArt = () => {
  return HTTP_CLIENT.post("artwork/increaseViewCount");
};

const getAllAuctions = (obj, filter) => {
  return HTTP_CLIENT.get(
      `auction/getAuctionListing?page=${obj.page}&perPage=${obj.limit}${filter && filter
      }`
  );
};

const getOpenArtWorkList = (collectionId) => {
  return HTTP_CLIENT.get(
    `artwork/getCollectionArtworks?collectionId=${collectionId}`
  );
};

const AddFavouriteArtService = (obj) => {
  return HTTP_CLIENT.post(`artwork/addToFavourite`, obj)
};

const removeFavouriteArtService = (obj) => {
  return HTTP_CLIENT.post(`artwork/removeFromFavourite`, obj)
};

const deleteArtService = (obj) => {
  return HTTP_CLIENT.post(`artwork/deleteArtwork`, obj)
};

const artHistoryService = (obj, callback) => {
  HTTP_CLIENT.get(
    `artwork/getArtworkHistory?artworkId=${obj.artwork}&page=${obj.page}&perPage=${obj.limit}`
  ).then((res) => {
      callback(res);
    })
    .catch((err) => {
      callback(false);
    });
};


const auctionBids = (auctId, callback) => {
  HTTP_CLIENT.get(`artwork/getAuctionBids?auctionId=${auctId}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      callback(false);
    });
};

export {
  getAllUserArts,
  getAllUserArtFavourite,
  SaveArt,
  getSingleArtService,
  startAuctionService,
  placeBidService,
  IncreaseViewCountArt,
  updateTokenIdOfArtwork,
  getAllAuctions,
  getOpenArtWorkList,
  increaseViewCountService,
  mintAuction,
  AddFavouriteArtService,
  deleteArtService,
  artHistoryService,
  auctionBids,
  getAllArtService,
  removeFavouriteArtService,
  getOpenArtService,
  getOpenAuctionService,
  getLatestArtworks
};
