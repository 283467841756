import axios from "axios";
import { BASE_URL, EndPoint } from "./AppEndpoint";

export const HTTP_CLIENT = axios.create({
  baseURL: BASE_URL,
});

HTTP_CLIENT.interceptors.request.use(
  (config) => {
    
    const token = localStorage.getItem('auth_token');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers['Access-Control-Allow-Origin'] = '*';
    }

    return config;
  },
  (err) => {
    if (err?.response?.status === 401) {
      //place your reentry code
    }
    Promise.reject(err);
  }
);

export const AuthenticationError = (err) => {

};
