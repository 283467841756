import React from 'react';
import CustomModal from 'components/CustomModal/CustomModal';
import { BiError, IoMdDoneAll } from 'react-icons/all';
import { SpinnerLoader } from 'components/Spinner/Spinner';

const AlertPopup = (props) => {
    
    const { data, showCancel, loading } = props;

    return (

        <CustomModal size={3} show={data.show} handleClose={props.handleClose}>
            
            <div className="d-flex align-items-start p-4">
                
                <div className="flex-shrink-0">
                    {
                        data?.type === "error" ?  
                        <div className="d-flex flex-column align-items-center justify-content-center rounded-circle" 
                            style={{ width: '80px', height: '80px', border: '2px solid red' }}>
                            <BiError className="font-36px text-red" />
                        </div>
                        :
                        <div className="d-flex flex-column align-items-center justify-content-center rounded-circle" 
                        style={{ width: '80px', height: '80px', border: '2px solid green' }}>
                            <IoMdDoneAll className="font-36px text-success" />
                        </div>
                    }
                </div>

                <div className="ml-4">

                    <h6 className="font-20px font-roboto mb-2 mt-1"> { data?.title } </h6>

                    <span className="font-15px mb-4 d-block"> { data?.message } </span>

                    <button onClick={props.handleClose}
                        className="font-roboto outline-none font-14px rounded normal-btn bg-pink text-white border-0" 
                    >
                        { loading ? <SpinnerLoader size="sm" /> : 'OK' }
                    </button>
                    
                    {
                        showCancel &&
                        <button onClick={showCancel}
                            className="font-roboto ml-3 outline-none font-14px rounded normal-btn bg-pink text-white border-0" 
                        >
                            Cancel
                        </button>
                    }

                </div>
            </div>

        </CustomModal>

    );

}
 
export default AlertPopup;