import React, { useState } from 'react';
import { Images } from 'Assets/Images';
import { Row, Col } from 'react-bootstrap';
import { ArtistItem } from 'components/ArtistItem/ArtistItem';
import { ArrowIcon } from 'components/ArrowIcon/ArrowIcon';
import './CreateNft.css';
import LoadData from 'components/LoadData/LoadData';
import { Routes } from 'Routes/Routes';

const CreateNft = (props) => {
    
    const { textColor, isDark, topArtists, loading, history } = props;

    const [slider, setSlider] = useState({ start: 0, end: 3 });

    const sliderMove = (flag) => {
        if(flag){
            if(slider.end === topArtists?.length-1){
                setSlider({ start: 0, end: 3 });
            } else {
                setSlider({ start: slider.start+1, end: slider.end+1 });
            }
        } else {
            if(slider.start > 0){
                setSlider({ start: slider.start-1, end: slider.end-1 });
            } else {
                setSlider({ start: 0, end: 3 });
            }
        }
    }

    const data = [
        { id: 1, title: 'SET UP YOUR WALLET', src: Images.createNfts[0],
            text: 'To get started, connect your Metamask wallet to the MarvmentNFT marketplace. Your wallet allows secure access to your NFTs and keep them safe.' },
        { id: 2, title: 'UPLOAD AND MINT NFTS', src: Images.createNfts[1],
            text: `Upload your digital assets (art, music, videos, gifs) into your MarvmentNFT collection. This is where you mint your NFTs and place them on the Ethereum blockchain.` },
        { id: 3, title: 'SELL AND AUCTION YOUR NFTS', src: Images.createNfts[2],
            text: 'From your uploaded collection, sell or auction your NFTS. Specify your sale price or the time of your auction and make them available for purchase by your fans.' },
        { id: 4, title: 'EARN ROYALTIES', src: Images.createNfts[3],
            text: 'Set your desired royalty rate from 1-10%.  Marvment NFTS smart contracts defines a royalty rate allowing artists to earn royalties perpetually from future sales. Yes, this is life-time income for artists.' },
    ];

    return(

        <section className={`py-5 create-nft-section w-100 app-flex-column position-relative padding-section ${textColor}`} id="digital-art">
            
            <img alt="" width="385px" height="100%" src={Images.leftSideImage} style={{ zIndex: 1, left: 0 }}
                className="position-absolute d-sm-none d-md-none d-lg-block d-xl-block bgHeaderRight"
            />

            <img alt="" width="385px" height="100%" src={Images.leftSideImage} style={{ zIndex: 1, right: 0, transform: 'rotate(180deg)' }}
                className="position-absolute d-sm-none d-md-none d-lg-block d-xl-block bgHeaderRight"
            />

            <h6 className="font-36px mb-5 mt-4 d-block text-center font-weight-bold font-clashRegular">
                How It Works
            </h6>
        
            <Row className="mb-5 nft-item__wrapper" style={{ zIndex: 10 }}>
                <Col xs={12} sm={12} md={12} lg={4} xl={4} className="h-auto my-auto">
                { data.slice(0, 2).map((item, i) => (
                    <div className="w-100 app-flex-column nft-item mt-auto" key={i}>
                        <img alt="" src={item?.src} width="55px" height="55px" />
                        <h6 className="font-23px text-uppercase d-block my-3 font-clashRegular"> { item?.title } </h6>
                        <span className={`font-16px text-justify letter-spacing-normal w-100 font-clashRegular`}>{ item?.text }</span>
                    </div>
                )) }
                </Col>

                <Col xs={12} sm={12} md={12} lg={4} xl={4} className="text-center">
                    <img alt="feature" width="85%" className="mx-auto" src={Images.collectingImage} />
                </Col>

                <Col xs={12} sm={12} md={12} lg={4} xl={4} className="h-auto my-auto">
                { data.slice(2, 4).map((item, i) => (
                    <div className={`w-100 app-flex-column nft-item ${i === 0 ? 'nft-item__third' : ''}`} key={i}>
                        <img alt="" src={item?.src} width="55px" height="55px" />
                        <h6 className="font-23px text-uppercase d-block my-3 font-clashRegular"> { item?.title } </h6>
                        <span className={`font-16px text-justify letter-spacing-normal w-100 font-clashRegular`}>{ item?.text }</span>
                    </div>
                )) }
                </Col>

            </Row>

            <h6 className="font-36px mb-5 d-block text-center font-weight-bold font-clashRegular">Top Artist</h6>

            <Row className="w-100 p-0 m-0 position-relative" style={{ zIndex: 10 }}>
                { topArtists?.length > 3 && <ArrowIcon left className="slider-icons" onClick={() => sliderMove(false)} /> }
                <LoadData loading={loading} msg="There are no top artists as yet" arr={topArtists}>
                    { topArtists?.slice(slider?.start, slider?.end)?.map((item, i) => (
                        <Col xs={12} sm={12} md={6} lg={4} xl={4} key={i} className='cursor-pointer-sort'>
                            <ArtistItem item={item} textColor={textColor} isDark={isDark} onClick={(id) => history.push(`/${Routes.profile}/${id}`)}
                            className={`${textColor} mb-3 ${ isDark ? 'border border-white' : 'border border-darkBlack' }`} />
                        </Col>
                    )) }
                </LoadData>
                { topArtists?.length > 3 && <ArrowIcon right className="slider-icons" onClick={() => sliderMove(true)} /> }
            </Row>

        </section>

    );

}
 
export default CreateNft;