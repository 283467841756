import React, { useEffect } from 'react';

import DocumentTitle from 'components/DocumentTitle/DocumentTitle';
import RenderPage from 'components/RenderPage/RenderPage';
import { goToSection } from 'utilities/CommonMethods';
import CustomAccordian from './Accordian';

const FAQ = (props) => {
    
    const { currentTheme: { theme } } = props;

    useEffect(() => {        
        startTop();
    }, []);

    const startTop = () => {
        setTimeout(() => goToSection('privacypolicypage'), 0);
    }

    const textColor = theme === 'dark' ? 'text-white' : 'text-black';

    return (

        <DocumentTitle title="FAQs">

            <RenderPage title="FAQs" className={theme === 'dark' ? 'bg-darkBlack text-white' : 'bg-white text-black'} src={null} id="privacypolicypage">
                
                <div className={`padding-section py-4 ${textColor}`}>

                    <CustomAccordian color={theme === 'dark' ? 'white' : 'black'} />

                </div>
                
            </RenderPage>

        </DocumentTitle>

    );

}
 
export default FAQ;