import interceptor, { HTTP_CLIENT } from "utilities/intercepter";

const createCollectionService = (obj) => {
  let FormBody = new FormData();
  if (Object.keys(obj).length > 0) {
    Object.keys(obj).forEach((res) => {
      FormBody.append(`${res}`, obj[res]);
    });

    return HTTP_CLIENT.post("collection/createCollection", FormBody);
  }
};

const updateCollectionService = (obj) => {
  let FormBody = new FormData();
  if (Object.keys(obj).length > 0) {
    Object.keys(obj).forEach((res) => {
      FormBody.append(`${res}`, obj[res]);
    });

    return HTTP_CLIENT.post("collection/updateCollection", FormBody);
  }
};

const getAllCollection = (obj) => {
  return HTTP_CLIENT.get(
    `collection/getUserCollections?page=${obj.page}&perPage=${obj.limit}&userId=${obj.userId}`
  );
};

const getCollectionDetail = (id) => {
  return HTTP_CLIENT.get(
    `collection/getCollectionDetails?collectionId=${id}`
  );
};

const getUserAllCollection = (userId) => {
  return HTTP_CLIENT.get(`collection/getAllUserCollections?userId=${userId}`);
};

const getAllOpenCollection = () => {
  return HTTP_CLIENT.get("collection/getAllCollections");
};

const deleteACollection = (obj) => {
  return HTTP_CLIENT.post("collection/deleteCollection", obj);
};

export {
  deleteACollection,
  createCollectionService,
  getAllCollection,
  getCollectionDetail,
  updateCollectionService,
  getUserAllCollection,
  getAllOpenCollection,
};
