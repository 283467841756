import React, { useState, useEffect } from 'react';

import { Row, Col } from 'react-bootstrap';
import DocumentTitle from 'components/DocumentTitle/DocumentTitle';
import { getArtworksByAlbum, getSingleAlbum } from 'services/albumService';
import LoadData from "components/LoadData/LoadData";
import Pagination from 'components/Pagination/Pagination';
import RenderPage from 'components/RenderPage/RenderPage';
import { useHistory } from 'react-router-dom';
import CombineListItem from 'components/CombineListItem/CombineListItem';
import { goToSection } from 'utilities/CommonMethods';
import { useParams } from 'react-router-dom';
import BorderButton from 'components/BorderButton/BorderButton';
import { useSelector } from 'react-redux';
import { Routes } from 'Routes/Routes';
import { getUserAllCollection } from 'services';

const Album = (props) => {
    
    const { id } = useParams();

    const { currentTheme: { theme } } = props;

    const { albumButtons, user } = useSelector(state => state);

    const history = useHistory();
    const userId = user?.info?.id ? user?.info?.id : user?.info?._id;

    const [collection, setCollection] = useState(undefined);
    const [album, setAlbum] = useState(undefined);
    const [albums, setAlbums] = useState([]);
    const [loading, setLoading] = useState(true);
    const [count, setCount] = useState(0);
    const [usd, setUsd] = useState({ bitcoin: 0, eth: 0, matic: 0  });
    const [page, setPage] = useState({ page: 0, perPage: 9 });

    useEffect(() => {
        if(userId && id){
            getUserCollections(userId);
        }
    }, [userId]);

    useEffect(() => {
        goToSection('albumpage');

        if(id){
            loadAlbum(page);
        }
    }, [page]);

    useEffect(() => {
        goToSection('albumpage');

        return () => {
            setAlbums([]);
        }
    }, []);

    const getUserCollections = (userId) => {
        getUserAllCollection(userId).then((res) => {
            setCollection(res?.data?.data?.find(c => c?.symbol?.toLowerCase() === 'music'));
        });
    };

    const loadAlbum = (page) => {
        setLoading(true);

        getSingleAlbum(id).then((res) => {
            setAlbum(res?.data?.album);
        });

        getArtworksByAlbum(id, page).then((res) => {
            setCount(res?.data?.count);
            setAlbums(res?.data?.artworks);
            setLoading(false);
        }).catch((err) => {
            setAlbums([]);
            setLoading(false);
        });

    };

    const goToRoute = (state) => {
        history.replace({ pathname: `/${Routes.profile}`, state: state });
    };

    const goToSubmitNft = (state) => {
        if(collection){
            history.replace({ pathname: `/${Routes.submitNfts}/${collection?._id}/${id}`, state: state });
        }
    };

    const textColor = theme === 'dark' ? 'text-white' : 'text-black';

    return (

        <DocumentTitle title={`Album (${album ? album?.name : ''})`} condition={album}>
        
            <RenderPage title={`Album (${album ? album?.name : ''})`} setUsd={(usd) => setUsd(usd)}
            className={theme === 'dark' ? 'bg-darkBlack text-white' : 'bg-white text-black'} id="albumpage">
            
                <Row className="pt-4 bg-transparent padding-section position-relative">              

                    { albumButtons &&
                    <BorderButton 
                        title="Back to Music Collection" onClick={(t) => goToRoute(2)}
                        className={`bg-transparent rounded mouse-hover border-pink border outline-0 outline-none profile-btns
                            font-15px font-clashRegular mb-2 ${theme === 'dark' ? 'text-white' : 'text-black'} font-weight-bold position-absolute
                            d-md-block d-lg-block d-xl-block d-none
                        `} 
                        style={{ left: 70, top: -87 }}
                    /> }

                    { albumButtons &&
                    <BorderButton 
                        title="Add Album Tracks" onClick={(t) => goToSubmitNft(2)}
                        className={`bg-transparent rounded mouse-hover border-pink border outline-0 outline-none profile-btns
                            font-15px font-clashRegular mb-2 ${theme === 'dark' ? 'text-white' : 'text-black'} font-weight-bold position-absolute
                            d-md-block d-lg-block d-xl-block d-none
                        `} 
                        style={{ right: 60, top: -87 }}
                    /> }

                    { albumButtons &&
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-4 mb-4 d-md-none d-lg-none d-xl-none">
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <BorderButton 
                                    title="Back to Music Collection" onClick={(t) => goToRoute(2)}
                                    className={`bg-transparent rounded mouse-hover border-pink border outline-0 outline-none profile-btns
                                        font-15px font-clashRegular mb-2 ${theme === 'dark' ? 'text-white' : 'text-black'} font-weight-bold
                                    `}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <BorderButton 
                                    title="Add Album Tracks" onClick={(t) => goToSubmitNft(2)}
                                    className={`bg-transparent rounded mouse-hover border-pink border outline-0 outline-none profile-btns
                                        font-15px font-clashRegular mb-2 ${theme === 'dark' ? 'text-white' : 'text-black'} font-weight-bold
                                    `}
                                />
                            </Col>
                        </Row>
                    </Col> }

                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="order-2">
                        <Row>
                            <LoadData loading={loading} arr={albums} msg={`No artowrks found.`}>
                                { albums?.map((item, i) => (
                                    <Col xs={12} sm={6} md={6} lg={4} xl={3} key={i} className="mb-5">
                                        <CombineListItem 
                                            artId={item?._id} item={{ ...item, isAlbum: false, isInAlbum: false }}
                                            artwork_url={item?.artwork_url} artwork_type={item?.artwork_type} artworkName={item?.name} 
                                            openForSale={item?.openForSale} isAuctionOpen={item?.isAuctionOpen} artName={item?.name} 
                                            artPrice={item?.price} endTime={item?.auction?.endTime} numberOfLikes={item?.numberOfLikes} 
                                            owner={item?.owner} creater={item?.creater} usd={usd}
                                            history={history} isDark={theme === 'dark'} 
                                        />
                                    </Col>
                                )) }
                            </LoadData>
                        </Row>

                        {
                            count > 9 &&
                            <Pagination isDark={theme === 'dark'} 
                                nextClick={() => setPage({ ...page, page: page.page + 1 })} total={count}
                                backClick={() => setPage({ ...page, page: page.page - 1 })} perPage={9} textColor={textColor}
                                activeIndex={page.page} changePage={(i) => setPage({ ...page, page: i })} 
                            />
                        }

                    </Col>

                </Row>

            </RenderPage>
        
        </DocumentTitle>
    );

}
 
export default Album;