import React, { useState, useEffect } from 'react';
import CustomModal from "components/CustomModal/CustomModal";
import SignIn from "./SignIn";
import Wallets from "./Wallets";
import { InstallExtension } from "./InstallExtension";
import { getAccounts } from 'services/contract.service';

export const SignInSmooth = ({ currentTheme, modalProps, defaultBehaviour }) => {

    const [notInstalled, setNotInstalled] = useState(0);
    const [showModal, setShowModal] = useState({ index: null, flag: false });
    const [address, setAddress] = useState(undefined);

    const { index, flag } = showModal;

    useEffect(() => {
        async function fetchAccounts(){
            const accounts = await getAccounts();

            if(accounts?.length){
                if(modalProps?.flag){
                    setAddress(accounts[0]);
                    setModalState(1, true);
                }
            } else {
                if(modalProps?.flag){
                    setModalState(modalProps?.index, modalProps?.flag);
                }
            }
        }

        fetchAccounts();
    }, [modalProps]);

    const setModalState = (index, flag) => {
        setShowModal({ index, flag });
    };

    if(flag){
        return (
            <CustomModal
            show={flag}
            handleClose={() => setModalState(0, false)}
            size={index}
        >

            { index === 1 ? (
                <SignIn
                currentTheme={currentTheme} defaultBehaviour={defaultBehaviour} address={address}
                handleClose={(i, flag) => setModalState(i, flag)}
                />
            ) : index === 3 ? (
                <Wallets
                currentTheme={currentTheme}
                handleClose={(i, flag) => setModalState(i, flag)}
                setNotInstalled={(i) => {
                    setNotInstalled(i);
                    setModalState(2, true);
                }}
                findActiveAccount={(add) => {
                    setAddress(add);
                    setModalState(1, true);
                }}
                />
                ) : index === 2 ? (
                    <InstallExtension isMetaMask={notInstalled === 1} 
                    close={() => {
                        setNotInstalled(0);
                        setModalState(3, true);
                    }} 
                    />
                ) : null
                }

            </CustomModal>
        );
    }

    else return null;

};