import interceptor, { HTTP_CLIENT } from "utilities/intercepter";

const logInUser = (obj) => {
  return HTTP_CLIENT.post("auth/login", obj);
};

const registerUser = (obj) => {
  return HTTP_CLIENT.post("auth/register", obj);
};

const getSingleUserService = (userId, callback) => {
  HTTP_CLIENT.get(`/users/${userId}`)
    .then((res) => {
      callback(res?.data?.user);
    })
    .catch((err) => {
      callback(undefined);
    });
};

const getAllUsers = () => {
  return HTTP_CLIENT.get(`users/getAllUsers`);
};

const updateUser = (id, obj) => {
  let FormBody = new FormData();
  Object.keys(obj).forEach((res) => {
    FormBody.append(`${res}`, obj[res]);
  });

  return HTTP_CLIENT.put(`/users/${id}`, FormBody);
};

const followAUser = (obj) => {
  return HTTP_CLIENT.post(`users/followUser`, obj)
};

const unFollowAUser = (obj) => {
  return HTTP_CLIENT.post(`users/unfollowUser`, obj)
};

const getUserFollowers = (obj) => {
  return HTTP_CLIENT.get(`users/getUserFollowers?page=${obj.page}&perPage=${obj.limit}&userId=${obj.userId}`);
};

const getUserFollowings = (obj) => {
  return HTTP_CLIENT.get(`users/getUserFollowing?page=${obj.page}&perPage=${obj.limit}&userId=${obj.userId}`);
};

export { 
  logInUser, registerUser, updateUser, getSingleUserService, 
  getUserFollowers, getUserFollowings, 
  getAllUsers, followAUser, unFollowAUser 
};
