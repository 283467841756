import React, { useState, useEffect, Fragment, useCallback } from 'react';
import AlertPopup from 'components/AlertPopup/AlertPopup';
import { Row, Col } from 'react-bootstrap';

import { GENERES } from 'Assets/Genres';
import CustomDropdown from 'components/Dropdown/Dropdown';
import UploadFile from '../SubmitNft/UploadFile';

import CustomModal from "components/CustomModal/CustomModal";
import { FaTimes } from 'react-icons/fa';
import { createAlbum, updateAlbum } from 'services/albumService';
import { SpinnerLoader } from 'components/Spinner/Spinner';

export const CreateAlbum = (props) => {

    const { onClose, openModal, theme, album, reRender, srcUrl } = props;

    const [values, setValues] = useState({ name: '', genre: '', tracks: '', coverImage: null });
    const [err, setErr] = useState({ show: false, type: '', message: '', title: '' });

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(album && openModal?.flag){
            setValues({ name: album?.name, genre: album?.genre, tracks: album?.tracks, coverImage: { id: 1, src: srcUrl, name: '', file: null, type: 'image' } });
        }
    }, [album, openModal]);
 
    const onChange = useCallback((e) => {
        const { name, value } = e.target;

        setValues({ ...values, [name]: value });
    }, [values]);

    const create = () => {
        const { name, genre, tracks, coverImage } = values;

        if(!name || !genre?.value || !tracks || !coverImage){
            setErr({ show: true, type: 'error', title: `Fields With (*) Are Required`, message: '' });
        } else {
            setLoading(true);

            createAlbum({ name, tracks, coverImage: coverImage?.file, genre: genre?.value }).then((res) => {
                setLoading(false);
                reRender();
                onClose({ flag: false, title: null });
            }).catch((err) => {
                setLoading(false);
                setErr({ show: true, type: 'error', title: `Something went wrong`, message: '' });
            });
        }
    };

    const update = () => {
        const { name, tracks, coverImage } = values;

        if(!name || !tracks || !coverImage){
            setErr({ show: true, type: 'error', title: `Fields With (*) Are Required`, message: '' });
        } else {
            setLoading(true);

            const obj = { name, tracks };

            if(coverImage?.file){
                obj['coverImage'] = coverImage?.file
            }

            updateAlbum(obj, album?.id).then((res) => {
                setLoading(false);
                reRender();
                onClose({ flag: false, title: null });
            }).catch((err) => {
                setLoading(false);
                reRender();
                onClose({ flag: false, title: null });
            });
        }
    };

    const getFieldClassName = () => {
        return `submit-nft-fields w-100 mb-3 
        ${theme === 'dark' ? 'bg-transparent text-white border-white' : 'text-black bg-transparent border border-black'}`;
    };

    return(

        <Fragment>

            {
                openModal.flag &&
                <CustomModal 
                    show={openModal.flag} size={3}
                    handleClose={(num, flag, e) => {
                        e.stopPropagation();
                        onClose({ flag: false, title: null });
                    }}
                >
                
                    <Row className="pt-5 px-5 position-relative">

                        <FaTimes className="text-black cursor-pointer-sort font-20px position-absolute mt-3 mr-3 font-normal"
                            style={{ right: 10, top: 0, zIndex: 5 }} 
                            onClick={(e) => onClose({ flag: false, title: null })} 
                        />

                        <Col xs={12}>
                            <label className="d-block font-15px mb-2 text-center">Upload Album Cover Art</label>
                            <UploadFile getFile={(file) => setValues({ ...values, coverImage: file })} isDark={theme === 'dark'} smallIndicator 
                                bgColor={theme === 'dark' ? 'bg-grey50light' : 'bg-grey-50'} selectedFile={values.coverImage}                                 
                            />
                        </Col>
                        <Col xs={12}>
                            <label className="d-block font-15px mb-2"> Album Name <sup>*</sup></label>
                            <input onChange={(e) => onChange(e)} type="text" step=".001" min="0.001"
                                name="name" value={values.name} 
                                readOnly={false} required
                                className={getFieldClassName()} 
                            />
                        </Col>
                        <Col xs={12}>
                            { album ? 
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <label className="d-block font-15px mb-2"> Tracks <sup>*</sup></label>
                                        <input onChange={(e) => onChange(e)} type="number" step="1" min="1"
                                            name="tracks" value={values.tracks} 
                                            readOnly={false} required onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                                            className={getFieldClassName()} 
                                        />
                                    </Col>
                                </Row> :
                                <Row>
                                    <Col xs={12} sm={12} md={7} lg={8} xl={8}>
                                        <label className="d-block font-15px mb-2"> Genre <sup>*</sup></label>
                                        <CustomDropdown title={ values?.genre?.value } 
                                            arr={GENERES.map((g, i) => { return { id: i, value: g } })} 
                                            className={`${getFieldClassName()} ${ !values?.genre?.value && 'border-red' } `}
                                            chooseOption={(obj) => setValues({ ...values, genre: obj })} 
                                            height="45px" paddingTop={3}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                                        <label className="d-block font-15px mb-2"> Tracks <sup>*</sup></label>
                                        <input onChange={(e) => onChange(e)} type="number" step="1" min="1"
                                            name="tracks" value={values.tracks} 
                                            readOnly={false} required onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                                            className={getFieldClassName()} 
                                        />
                                    </Col>
                                </Row>
                            }                            
                        </Col>

                        <button className="rounded-lg bg-pink py-2 px-4 text-white nft-hover font-14px border-0 mx-auto my-3" 
                            disabled={loading} onClick={() => album ? update() : create()}
                        >
                            { loading ? <SpinnerLoader size="sm" /> : openModal.title }
                        </button>

                    </Row>

                </CustomModal>
            }

            <AlertPopup 
                data={{ show: err?.show, title: err.title, message: err?.message, type: err?.type }}
                handleClose={() => {
                    setErr({ ...err, show: false });
                }}
            />

        </Fragment>

    );

};