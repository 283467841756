export const CONNECT_META_MASK = "CONNECT_META_MASK";

export const BUY_COIN = "BUY_COIN";

export const UPDATE_PROFILE = "UPDATE_PROFILE";

export const IS_NOTIFICATIONS = "IS_NOTIFICATIONS";

// auth
export const CHANGE_WALLET = "CHANGE_WALLET";

export const CHANGE_THEME = "CHANGE_THEME";

export const IS_LOGIN = "IS_LOGIN";

export const IS_LOGOUT = "IS_LOGOUT";

export const IS_PROFILE_ALBUM = "IS_PROFILE_ALBUM";

export const SET_USER_BALANCE = "SET_USER_BALANCE";
export const SET_ACTIVE_WALLET = "SET_ACTIVE_WALLET";
