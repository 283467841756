import { networks } from 'Assets/Data';
import * as React from 'react';
import ReactTooltip from "react-tooltip";

export const PopOverToolTip = ({ placement, id }) => {
    return (
        <ReactTooltip id={id} place={placement} type='light' backgroundColor='#f5f5f5' arrowColor='#f5f5f5'>
            <div className="app-flex-column py-2 px-2 align-items-center justify-content-center" style={{ width: 320 }}>

                { networks.map((net, i) => (
                    <div className='w-100 app-flex-column mb-3 align-items-center justify-content-center' key={i}>
                        <div className='d-flex align-items-center'>
                            <img alt={net?.value} src={net?.logo} className="mr-2 mb-1" width={net?.id === 1 ? "28px" : "22px"} />
                            <h6 className='font-16px font-weight-bold'> { net?.name } </h6>
                        </div>
                        <span className="font-14px font-normal"> { net?.msg } </span>
                    </div>
                )) }

            </div>
        </ReactTooltip>
    );
}