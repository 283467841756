import { Div } from 'components/Div/Div';
import { Input } from 'components/Input/Input';
import React from 'react';

const LabelWithField = (props) => {
    
    const { label, labelClassName, fieldClassName, className, value, type, onChange, onKeyPress, placeholder, id, name, disable, readOnly, step, min } = props;

    const { startIcon, endIcon, inputref } = props;

    return(

        <Div className={`${className}`}>

            { label && <label className={`${labelClassName} d-block text-left mb-1`}>
                { label }
            </label> }

            <Div className={`position-relative`} style={{ height: '55px' }}>
                { startIcon }
                <Input 
                    placeholder={placeholder} value={value} type={type} id={id} name={name}
                    onChange={(e) => onChange(e)} className={fieldClassName} readOnly={readOnly}
                    onKeyPress={onKeyPress} inputref={inputref} disabled={ disable ? disable : false }
                    autoComplete="nope" step={step} min={min}
                />
                { endIcon }
            </Div>

        </Div>

    );

}
 
export default LabelWithField;