import React from 'react';
import './FooterArt.css';
import { Images } from 'Assets/Images';
import FlexRow from 'components/FlexRow/FlexRow';
import { Row, Col } from 'react-bootstrap';
import { topBarContent, topBarContentRightSideWithAuth } from 'Assets/Data';
import { FiFacebook , FiTwitter, AiOutlineInstagram, FaSearch } from 'react-icons/all';
import { NavLink } from 'react-router-dom';
import { goToSection } from 'utilities/CommonMethods';
import { Routes } from 'Routes/Routes';

const Footer = ({ isLogin }) => {
    
    const upper = [
        { id: 1, value: 'Company', link: '' },
        { id: 2, value: 'Resources', link: '' },
        { id: 3, value: 'MarketPlace', link: '' },
        { id: 4, value: 'Terms & privacy', link: '' }
    ];

    const socials = [
        { id: 1, value: 'Facebook', icon: <FiFacebook className="text-white font-18px mr-3" />, link: 'https://www.facebook.com/Marvmentnft-106712355204311' },
        { id: 2, value: 'Twitter', icon: <FiTwitter className="text-white font-18px mr-3" />, link: 'https://twitter.com/marvmentnft/' },
        { id: 3, value: 'Instagram', icon: <AiOutlineInstagram className="text-white font-18px mr-3" />, link: 'https://www.instagram.com/marvmentnft/' }
    ];

    const searchLinks = [
        { id: 4, route: Routes.search, value: 'Search', sectionId: 'searchpage', condition: true },
        { ...topBarContentRightSideWithAuth[0], condition: isLogin },
        { id: 5, route: Routes.about, value: 'About', sectionId: 'aboutpage', condition: true }
    ];

    const open = (link) => {
        window.open(link, '_blank');
    };

    return(

        <div className="footer app-flex-column">
            
            <Row className="footer-overlay text-white">
                
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                    <div className="footer-overlay-links h-100 align-items-start justify-content-center app-flex-column mx-auto">
                        <img alt="" className="app-logo" src={Images.logoDark} />
                        <span className="font-clashRegular font-15px py-3 d-block text-justify">
                            Marvment NFT marketplace gives access to a world of opportunities to artists so they can promote their NFTs and gain exposure by instantly connecting to their admirers while generating a steady and secure income from their creations at the same time.
                        </span>
                        <div className="py-5 w-100 font-clashRegular">
                            <h6 className="font-14px font-weight-bold d-block mb-1">Send your enquiries</h6>
                            <a href="mailto:support@marvmentnft.com" className="">support@marvmentnft.com</a>
                        </div>
                        <div className="w-100">
                            <h6 className="font-14px font-weight-bold d-block mb-2 font-clashRegular">Follow us on</h6>
                            <FlexRow className="justify-content-start align-items-center w-100 flex-wrap">
                                { socials?.map((item, i) => (
                                    <span key={i} onClick={() => open(item?.link)} className='cursor-pointer-sort' title={item.value}>
                                        { item?.icon }
                                    </span>
                                )) }
                            </FlexRow>
                        </div>
                    </div>
                </Col>

                <Col xs={12} sm={12} md={12} lg={6} xl={6} className="px-0 m-0">
                    <img alt="" width="100%" src={Images.footerImage} />
                    <div className="justify-content-start align-items-center w-100 pt-2 pb-3 pr-0 flex-wrap app-flex-row image-links font-clashRegular">
                        { topBarContent.concat(searchLinks).map((item, i) => (
                            (item === null || item?.value === 'Search') ? '' :
                            item?.condition && (
                                <NavLink className={`font-14px text-white mr-4 font-clashRegular text-decoration-none`} 
                                    to={`/${item.route}`} key={i}
                                    onClick={() => setTimeout(() => {
                                        goToSection(item.sectionId);
                                    }, 1000)}
                                >
                                    {item?.value === 'Search' ? <FaSearch className="text-white" /> : item?.value}
                                </NavLink>
                            )
                        )) }
                    </div>    
                </Col>
                
            </Row>
            
            <Row className="footer-overlay" style={{ borderTop: '1px solid var(--white)' }}>
                <Col xs={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }} md={12} lg={{ span: 10, offset: 1 }} xl={{ span: 10, offset: 1 }} className="p-0 my-0">
                    <FlexRow className="justify-content-start align-items-center w-100 py-3 flex-wrap font-clashRegular">
                        <NavLink to={`/${Routes.terms}`} exact className="font-14px text-white mr-4 text-decoration-none">
                            Terms of Services
                        </NavLink>
                        <NavLink to={`/${Routes.policy}`} exact className="font-14px text-white mr-5 text-decoration-none">
                            Privacy Policy
                        </NavLink>
                        <span className="font-14px text-white text-decoration-none">
                            &copy; 2022 ALL RIGHTS RESERVED BY MARVMENT
                        </span>
                    </FlexRow>
                </Col>
            </Row>

        </div>

    );

}
 
export default Footer;