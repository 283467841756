import { Routes } from 'Routes/Routes';
import { FaTwitter, GrFacebookOption, AiOutlineInstagram, MdAttachment, CgFormatJustify, CgSearch, AiOutlineUser } from 'react-icons/all';
import { Images } from 'Assets/Images';

export const topBarContent = [
    { id: 1, value: 'Home', route: '', link: true, sectionId: 'home-section', condition: true },
    { id: 2, value: 'Explore', route: Routes.activity, link: true, sectionId: 'activitypage', condition: true },
    { id: 3, value: 'Discover', route: Routes.discover, link: true, sectionId: 'discoverpage', condition: true },
    { id: 4, route: Routes.faq, value: 'FAQ', sectionId: 'faqpage', condition: true }
];

export const mobileNavDisplay = [
    { id: 1, icon: <AiOutlineUser className="mr-3 font-28px cursor-pointer-sort" />, route: Routes.profile, link: true },
    { id: 2, icon: <CgSearch className="mr-3 font-25px cursor-pointer-sort" />, route: Routes.search, link: true },
    { id: 3, icon: <CgFormatJustify className="mr-3 font-36px cursor-pointer-sort" />, route: '', link: false }
];

export const topBarContentRightSideWithAuth = [
    { id: 4, value: 'Profile', route: Routes.profile, link: true, sectionId: 'profilepage' },
    { id: 5, value: 'Dashboard', route: Routes.dashboard, link: true, sectionId: 'dashboard' }
];

export const dashboardTabs = [
    { id: 1, label: 'Incoming Offers' },
    { id: 2, label: 'Out Going Offers' }
];

export const discoverTabs = [
    { id: 1, label: 'Auctions' },
    { id: 2, label: 'Artworks' }
];

export const profileTabs = (role, changeTitle) => {
    return [
        { id: 1, label: role?.toLowerCase() === appRoles[1] ? changeTitle : 'Collections' },
        { id: 2, label: 'Artworks' },
        { id: 3, label: 'Favorites' }
    ];
}

export const socialIconsCollections = [
    { id: 1, icon: <FaTwitter className="text-grey-60 font-16px" />, link: '' },
    { id: 2, icon: <GrFacebookOption className="text-grey-60 font-18px" />, link: '' },
    { id: 3, icon: <AiOutlineInstagram className="text-grey-60 font-18px" />, link: '' },
    { id: 4, icon: <MdAttachment className="text-grey-60 font-18px" />, link: '' }
];

export const filtersCollections = [
    { id: 1, label: 'Gif', value: 'Gif' },
    { id: 2, label: 'Music', value: 'Music' },
    { id: 3, label: 'Video', value: 'Video' },
    { id: 4, label: 'Art', value: 'Art' }
];

export const appRoles = ['user', 'artist'];

export const filterProfile = [
    { id: 1, value: 'auctions' },
    { id: 2, value: 'on sale' },
    { id: 3, value: 'others' }
];

export const globalSearchFilters = [
    { id: 1, value: 'all' },
    { id: 2, value: 'artworks' },
    { id: 3, value: 'Video' },
    { id: 4, value: 'Music Album' }
];

export const genres = [
    { id: 1, value: 'Jazz' },
    { id: 2, value: 'Pop' },
    { id: 3, value: 'Hip Hop' },
    { id: 4, value: 'Funk' },
];

export const networks = [
    { id: 1, value: 'MATIC', isMeta: false, logo: Images.polygon, width: 20, msg: '(Cheaper Gas Fees - Recommended)', name: 'Polygon Matic' },
    { id: 2, value: 'ETH', isMeta: true, logo: Images.ethLogo, width: 16, msg: '(Higher Gas Fees - Most Popular)', name: 'Ethereum' }
];

export const metamaskExtensionsIcons = [
    { id: 1, value: 'Chrome', link: 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn', icon: Images.metamaskIcons[0] },
    { id: 2, value: 'Mozilla Firefox', link: 'https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/', icon: Images.metamaskIcons[1] },
    { id: 3, value: 'Brave', link: 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn', icon: Images.metamaskIcons[2] },
    { id: 4, value: 'Mirosoft Edge', link: 'https://microsoftedge.microsoft.com/addons/detail/metamask/ejbalbakoplchlghecdalmeeeajnimhm?hl=en-US', icon: Images.metamaskIcons[3] },
];

export const coinbaseExtensionsIcons = [
    { id: 1, value: 'Chrome', link: 'https://www.coinbase.com/wallet', icon: Images.metamaskIcons[0] },
    { id: 2, value: 'Mozilla Firefox', link: 'https://www.coinbase.com/wallet', icon: Images.metamaskIcons[1] },
    { id: 3, value: 'Brave', link: 'https://chrome.google.com/webstore/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad?shortlink=fdb9b250&pid=Wallet%20LP&c=wa_i_m_n_i_m_wal_coi_0_Wallet-LP-Single-Button', icon: Images.metamaskIcons[2] },
    { id: 4, value: 'Mirosoft Edge', link: 'https://www.coinbase.com/wallet/getting-started-extension', icon: Images.metamaskIcons[3] },
];