import React, { useState } from 'react';
import FlexRow from 'components/FlexRow/FlexRow';
import { Row, Col } from 'react-bootstrap';

import { AiFillHeart, BsFillEyeFill, AiOutlineHeart } from 'react-icons/all'; 
import BorderButton from 'components/BorderButton/BorderButton';
import { AddFavouriteArtService, deleteArtService } from 'services/artService';
import AlertPopup from 'components/AlertPopup/AlertPopup';
import ArtworkType from 'components/ArtworkType/ArtworkType';
import { LargeModal } from 'components/LargeModal/LargeModal';
import AuctionSell from 'screens/Modals/AuctionSell';
import { Routes } from 'Routes/Routes';
import { ETH_CONTRACTS, POLYGON_CONTRACTS } from 'utilities/AppEndpoint';

const ProductLayout = (props) => {
    
    const { children, src, artType, showPara, connection, connectWallet, views, name, desc, numberOfLikes, isDark, userId, refreshState } = props;

    const { showPurchaseBtn, clickPurchase, showCancelSaleBtn, artworkId } = props;

    const { activeUser, history, currentArtwork, usd } = props; // for sale, auctions

    const [err, setErr] = useState({ show: false, type: '', message: '', title: '' });

    const [popupAlert, setPopupAlert] = useState({ show: false, type: '', message: '', title: '' });

    const [currentPopup, setCurrentPopup] = useState({ flag: false, item: undefined, index: 0 }); // for sale, auction nft

    // add Artwork To Fav list 
    const checkToFavArtwork = () => {
        const obj = { "artworkId": artworkId };

        AddFavouriteArtService(obj).then((res) => {
            refreshState();
            setErr({ show: true, type: 'success', title: 'Artwork Added to Favorites', message: `` });
        }).catch((err) => {
            setErr({ show: true, type: 'error', title: 'Network Error', message: `` });
        });
    };

    const deleteArtwork = () => {
        const obj = { "artworkId": artworkId };

        deleteArtService(obj).then((res) => {
            setPopupAlert({ ...popupAlert, show: false });
            history.push(`/${Routes.profile}`)
        }).catch((err) => {
            setErr({ show: true, type: 'error', title: 'Artwork Not Deleted', message: '' });
        });
    };

    return(

        <section className={`w-100 app-flex-column padding-section ${props.className}`}>
            
            <Row className="m-0 p-0">

                <Col xs={12} sm={12} md={12} lg={6} xl={6} 
                    className={connection ? 'cursor-pointer-sort position-relative' : 'position-relative'}
                >
                    
                    <div className='art-detail-img'>
                        
                        <ArtworkType height="489px" srcUrl={src} type={artType} name={name} thumbNail_url={currentArtwork?.thumbNail_url}
                        isDark={isDark} controls={true} autoPlay={false} musicOriginalNFT={true} />

                        <FlexRow className="w-100 align-items-end justify-content-start mt-3">

                            <div className="app-flex-column mr-3 align-items-center justify-content-center">
                                { numberOfLikes > 0 ?
                                <AiFillHeart className="text-red cursor-pointer-sort font-16px" 
                                onClick={() => connection ? checkToFavArtwork() : setErr({ show: true, type: 'error', title: 'Please Sign In/Up To Your MarvmentNFT Account', message: `` }) } />
                                : <AiOutlineHeart className="text-red cursor-pointer-sort font-16px" 
                                onClick={() => connection ? checkToFavArtwork() : setErr({ show: true, type: 'error', title: 'Please Sign In/Up To Your MarvmentNFT Account', message: `` }) } />
                                }
                                <span className="font-13px"> {numberOfLikes ? numberOfLikes : 0} </span>
                            </div>

                            <div className="app-flex-column align-items-center justify-content-center">
                                <BsFillEyeFill className="text-grey font-16px cursor-pointer-sort" />
                                <span className="font-13px">{ views }</span>
                            </div>

                        </FlexRow>
                    </div>

                </Col>

                <Col xs={12} sm={12} md={12} lg={6} xl={6} className="margin-adjustment">

                    <Row className="px-0">

                        <Col xs={12} sm={12} md={12} lg={10} xl={10}>
                        
                            <h6 className="font-30px mb-3 font-weight-bold font-clashRegular text-capitalize">
                                { name }
                            </h6>

                            { showPara && 
                                <p className="d-block w-100 pr-4 font-15px mb-4 text-justify text-break">
                                    { desc }
                                </p>
                            }

                            { children }

                            {
                                showPurchaseBtn && connection &&
                                <BorderButton title="Purchase" className="w-100 text-white bg-lightPurple mb-2" onClick={() => clickPurchase('purchase')} />
                            }

                            {
                                showCancelSaleBtn &&
                                <BorderButton title="Cancel Sale" className="w-100 text-white bg-lightPurple mb-2" onClick={() => clickPurchase('salecancel')} />
                            }

                            {
                                (userId === currentArtwork?.owner?._id && !currentArtwork?.isAuctionOpen && !currentArtwork?.openForSale && connection) &&
                                <BorderButton title="Auction It" className="w-100 text-white bg-lightPurple mb-2" 
                                onClick={() => setCurrentPopup({ flag: true, item: currentArtwork, index: 1 })} />
                            }

                            {
                                (userId === currentArtwork?.owner?._id && !currentArtwork?.isAuctionOpen && !currentArtwork?.openForSale && connection) &&
                                <BorderButton title="Sell It" className="w-100 text-white bg-lightPurple mb-2" 
                                onClick={() => setCurrentPopup({ flag: true, item: currentArtwork, index: 2 })} />
                            }

                            { !connection &&
                            <button className="bg-transparent py-2 font-16px px-5 border-lightPurple text-lightPurple" 
                                onClick={() => connectWallet()}
                            >
                                SIGN IN TO COLLECT
                            </button> }

                        </Col>
                
                    </Row>

                </Col>

            </Row>

            <AlertPopup 
                data={{ show: err?.show, title: err.title, message: err?.message, type: err?.type }}
                handleClose={() => {
                    setErr({ ...err, show: false });
                }}
            />

            <AlertPopup 
                data={{ show: popupAlert?.show, title: popupAlert.title, message: popupAlert?.message, type: popupAlert?.type }}
                showCancel={() => {
                    setPopupAlert({ ...popupAlert, show: false });
                }}
                handleClose={() => deleteArtwork()}
            />

            <LargeModal 
                title={ (currentPopup.index === 1 ? 'Auction ' : 
                        currentPopup.index === 2 ? 'Sell ' : '') + 'Your NFT' }
                condition={currentPopup.flag} size={2}
                handleClose={() => setCurrentPopup({ flag: false, item: undefined, index: 0 })}
            >
                <AuctionSell data={{ 
                    ...currentPopup?.item, collectionName: currentArtwork?.collectionId?.name,
                    artistDescription: activeUser?.bio
                    }} saleNft={currentPopup.index === 2} history={history} usd={usd} isMeta={currentArtwork?.isMeta}
                    collectionAddress={ currentArtwork?.isMeta ? ETH_CONTRACTS.MINT_NFT_CONTRACT_ADDRESS : POLYGON_CONTRACTS.MINT_NFT_CONTRACT_ADDRESS } 
                    tokenId={currentPopup?.item?.tokenId}
                    refreshState={() => {
                        setCurrentPopup({ flag: false, item: undefined, index: 0 });
                        refreshState();
                    }}
                />
            </LargeModal>

        </section>

    );

}
 
export default ProductLayout;