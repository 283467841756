import React from 'react';
import './LayoutWrapper.css';
import { FaTimes } from 'react-icons/fa';
import { Div } from 'components/Div/Div';
import FlexRow from 'components/FlexRow/FlexRow';

const LayoutWrapper = (props) => {
    
    const { src, handleClose, children, twoColumn } = props;

    return(

        <FlexRow className="w-100 align-items-center justify-content-between position-relative">

            <FaTimes className="text-black cursor-pointer-sort font-20px position-absolute mt-3 mr-3 font-normal"
                style={{ right: 0, top: 0, zIndex: 5 }} 
                onClick={(e) => handleClose(0, false, e)} 
            />

            {
                twoColumn ? <>
                    <Div className="left-side">
                        <img alt="" src={src} width="100%" className="img-rounded" />
                    </Div>

                    <Div className="right-side app-flex-column font-playfair">
                        { children }
                    </Div>
                </> : children
            }

        </FlexRow>

    );

}
 
export default LayoutWrapper;