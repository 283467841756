import { Images } from 'Assets/Images';
import React, { useState, useEffect } from 'react';
import './Preloader.css';

export const Preloader = ({ isDark }) => {
    
    const [loader, setLoader] = useState(true);

    // useEffect(() => {
    //     setTimeout(() => {
    //         setLoader(false);
    //     }, 3000);
    // }, []);

    // if(loader){
    //     return(
    //         <div className="app-flex-column align-items-center justify-content-center w-100 position-absolute app-preloader" 
    //         style={{ zIndex: 1000, background: 'rgba(0, 0, 0, .75)', left: 0, right: 0, top: 0, height: '100vh' }}>

    //             <img alt="logo" src={ isDark ? Images.logoDark : Images.logoDark} width="240px" className="" />

    //         </div>
    //     );
    // } else return null;

    return null;
}