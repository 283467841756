import React from 'react';

const FlexRow = (props) => {
    
    const { children, className, style, onClick } = props;

    return(

        <div className={`app-flex-row ${ className }`} style={style} onClick={(e) => {
            if(onClick){
                onClick(e);
            } else {
                e.preventDefault();
            }
        }}>
            { children }
        </div>

    );

}
 
export default FlexRow;