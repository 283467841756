import React from 'react';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import { FiArrowRight } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import { Routes } from 'Routes/Routes';
import { ToggleSwitch } from "components/ToggleSwitch/ToggleSwitch";

export const MobileNav = (props) => {

    const { mainBtnText } = props;

    const { className, display, menus, isLogin, getSignIn, getLogout, userAvatar, isChangeTheme, isDark, showDashboard } = props;

    const color = isDark ? 'bg-white text-black' : 'bg-darkBlack text-white';

    const click = (item) => {
        if(!item.link){
            showMenu('flex');
        }
    }

    const showMenu = (className) => {
        document.getElementById('mobile-nav-menu').style.display = className;
    }

    const loginLogout = () => {
        if(isLogin){
            getLogout();
        } else {
            getSignIn();
        }
    }

    const extras = [
        { id: 4, route: Routes.faq, value: 'FAQ', sectionId: 'faqpage', condition: true },
        { id: 5, value: 'Profile', route: Routes.profile, link: true, condition: isLogin },
        { id: 6, value: 'Dashboard', route: Routes.dashboard, link: true, condition: showDashboard }
    ];

    return(

        <div className={`app-flex-row w-auto align-items-center ${className}`}>
            
            { display?.map((item, i) => (
                item?.id === 1 ? ( isLogin && <NavLink exact={true} to={`/${Routes.profile}`}> <img alt="user-avatar" src={userAvatar} width="30px" 
                height="30px" className="rounded-circle border-0 mr-3" /> </NavLink> ) : (
                item?.link ? <NavLink key={i} className={isDark ? 'text-white' : 'text-black'} to={`/${item?.route}`}>{item?.icon}</NavLink>
                : <span key={i} className={isDark ? 'text-white' : 'text-black'} onClick={() => click(item)}>{item?.icon}</span>)
            )) }

            <FlexColumn className={`position-absolute mobile-nav-menu pb-3 ${color}`} id="mobile-nav-menu">

                <span className="py-3 w-100 d-flex px-3" style={{ borderBottom: '1px solid black' }}> 
                    <FiArrowRight className="font-30px cursor-pointer-sort" onClick={() => showMenu('none')} />
                </span>

                <div className="w-auto px-3 position-absolute" style={{ right: 0, top: -8 }}>
                    <ToggleSwitch
                        className={`toggleSwitch cursor-pointer-sort py-2 ${isDark ? 'text-white' : 'text-black'}`}
                        isDark={isDark} changeTheme={(th) => isChangeTheme(th)}
                    />
                </div>

                {
                    menus?.slice(0, 3)?.concat(extras).map((item, i) => (
                        item?.condition &&
                        <NavLink exact={true} to={`/${item?.route}`} onClick={() => showMenu('none')} key={i} className={`cursor-pointer-sort w-100 pt-3 px-3 ${color}`}>
                            { item?.value }
                        </NavLink>
                    ))
                }

                <NavLink exact={true} to={`/`} onClick={() => window.open('https://Marvment.com', '_blank')} 
                    className={`cursor-pointer-sort w-100 pt-3 px-3 ${color}`}>
                    Music Distribution
                </NavLink>

                <span className={`cursor-pointer-sort w-100 pt-3 px-3 ${color}`} onClick={loginLogout}>
                    { isLogin ? 'Sign Out' : mainBtnText }
                </span>

            </FlexColumn>

        </div>

    );

}