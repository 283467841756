import React, { useEffect } from 'react';

import DocumentTitle from 'components/DocumentTitle/DocumentTitle';
import RenderPage from 'components/RenderPage/RenderPage';
import { goToSection } from 'utilities/CommonMethods';
import { TextListItem } from 'components/TextListItem/TextListItem';
import { dataLists } from './data';
import { TextInline } from 'components/TextListItem/Inline';

const ServiceTerms = (props) => {
    
    const { currentTheme: { theme } } = props;

    useEffect(() => {        
        startTop();
    }, []);

    const startTop = () => {
        setTimeout(() => goToSection('termspage'), 0);
    };

    const textColor = theme === 'dark' ? 'text-white' : 'text-black';

    return (

        <DocumentTitle title="Terms of Service">

            <RenderPage title="TERMS OF SERVICES" className={theme === 'dark' ? 'bg-darkBlack text-white' : 'bg-white text-black'} src={null} id="termspage">
                
                <div className={`padding-section pt-4 pb-1 ${textColor}`}>
    
                    <p className={`d-block text-justify mb-3 font-weight-bold font-18px`}> 
                        MarvmentNFT provides artists, musicians and other creators (“Sellers”) a marketplace that enables Sellers to create and distribute non-fungible tokens (“NFTs”) to buyers (“Buyers”) of the NFTs (the “Service”) via a website at marvmentnft.com (the “Site”). The Service and the Site may collectively be referred to herein as the “Platform”). The Service is owned and operated by MarvmentNFT LLC (hereinafter referred to as the “Company” or “us”). Sellers and Buyers are referred to herein collectively as “Users” and all the terms and conditions stated herein apply to all Users unless otherwise stated herein.
                    </p>

                    <p className={`d-block text-justify mb-3 font-weight-bold font-18px`}> 
                        Your use of the Service is subject to the terms and conditions set forth in this Terms of Service (hereinafter referred to as the “Terms” or “TOS”). 
                    </p>

                    <p className={`d-block text-justify mb-3 font-weight-bold font-18px`}> 
                        PLEASE READ THE TERMS OF SERVICE CAREFULLY. BY ACCESSING OR USING ANY PART OF THE SERVICE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND AND AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE TO THESE TERMS, EXIT THIS PAGE AND DO NOT ACCESS OR USE THE SERVICE. USE OF THE SERVICE IS EXPRESSLY CONDITIONED UPON YOUR ACCEPTANCE OF THE TERMS OF SERVICE. IF YOU ARE ACCEPTING THESE TERMS ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE LEGAL AUTHORITY TO ACCEPT THESE TERMS ON THAT ENTITY’S BEHALF, IN WHICH CASE “YOU” WILL MEAN THAT ENTITY.
                    </p>

                    { dataLists?.map((p, i) => (
                        p?.inline ? <TextInline key={i} item={p} /> :
                        <TextListItem key={i} p={p} />
                    )) }

                </div>
                
            </RenderPage>

        </DocumentTitle>

    );

}
 
export default ServiceTerms;