export const initialState = {
  address: [],
  connection: false,
  wallets: [],
  user: {
    info: undefined,
    isLogin: false,
    token: "",
    userBalance: 0,
  },
  notifications: [],
  theme: "light",
  albumButtons: false,
  activeWallet: "disconnected",
};

export const emptyState = {
  address: [],
  connection: false,
  wallets: [],
  user: {
    info: undefined,
    isLogin: false,
    token: "",
    userBalance: 0,
  },
  notifications: [],
  albumButtons: false,
};
