import React from "react";
import FlexRow from "components/FlexRow/FlexRow";

export const ToggleSwitch = ({ isDark, changeTheme, className }) => {
  return (
    <FlexRow
      className={`px-2 rounded-pill align-items-center justify-content-between ${className}`}
      style={{
        width: "110px",
        background: isDark ? 'var(--lightPurple)' : 'var(--grey-50)'
      }}
      onClick={() => changeTheme(isDark ? "light" : "dark")}
    >
      <div
        style={{
          width: "26px",
          height: "26px",
          order: isDark ? 1 : 2,
          background: isDark ? 'var(--black)' : 'var(--pink)',
        }}
        className="rounded-circle"
      ></div>

      <div
        className="font-clashRegular font-14px pr-2 font-weight-bold"
        style={{ order: isDark ? 2 : 1 }}
      >
        {isDark ? "Dark" : "Light"}
      </div>
    </FlexRow>
  );
};
