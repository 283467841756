import React from 'react';
import { Images } from 'Assets/Images';
import LayoutWrapper from 'hoc/Layout/LayoutWrapper/LayoutWrapper';
import FlexRow from 'components/FlexRow/FlexRow';

const DeepLinksPopup = (props) => {
    
    const { handleClose } = props;

    const arr = [
        { id: 1, src: Images.coinbase, link: 'https://go.cb-w.com/4f4IOhcwQob', value: 'Coinbase' },
        { id: 2, src: Images.metamask, link: 'https://metamask.app.link/dapp/www.marvmentnft.com/', value: 'Metamask' }
    ];

    return(

        <div className="app-flex-column w-100 position-relative">

            <LayoutWrapper src={null} handleClose={() => handleClose(false)}> 
                
                <div className="app-flex-column w-100 align-items-center justify-content-center py-4 px-5">

                    <span className="text-black font-28px mb-3">
                        Connect:
                    </span>

                    <div className="w-100 app-flex-column">
                        <div className="d-flex flex-column">
                            { arr.map((item, i) => (
                                <FlexRow 
                                    className="align-items-center justify-content-center py-2 mb-2" key={i} onClick={() => window.open(item?.link)}
                                    style={{ borderTop: '1px solid black', borderBottom: '1px solid black' }}
                                >    
                                    <img alt={item?.value} src={item?.src} width="60px" className="mr-4" />
                                    <h6 className="font-23px font-weight-bold"> {item?.value} </h6>                                
                                </FlexRow>
                            )) }                        
                        </div>
                    </div>

                </div>

            </LayoutWrapper>

        </div>

    );

}
 
export default DeepLinksPopup;