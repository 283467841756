import React from 'react';
import Countdown from 'react-countdown';
import BorderButton from 'components/BorderButton/BorderButton';
import FlexRow from 'components/FlexRow/FlexRow';

const CounterTimer = (props) => {
    
    const { smallTimer, closeBid, endTime, defaultLayout } = props;

    const CompletionList = () =>{
        if(closeBid){
            closeBid();
        }

        return <span className="font-13px font-clashRegular">Time has ended.</span>;
    };

    // Renderer callback with condition
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
        return <CompletionList />;    
    } else {
        // Render a countdown
        return defaultLayout ? <span>
                { `${days}d : ${hours}h : ${minutes}m : ${seconds}s` }
            </span> : 
            smallTimer ? <BorderButton 
                title={ endTime ? `${days}d, ${hours}h, ${minutes}m, ${seconds}s` : "00h, 00m, 00m" } 
                className="py-2 px-3 font-clashRegular border-black" 
            /> : 
            <FlexRow className="w-100 align-items-center justify-content-around">
                <div className="app-flex-column align-items-center justify-content-center">
                    <span className="font-25px font-weight-bold">{days}</span>
                    <span className="font-16px">Days</span>
                </div>
                <div className="app-flex-column align-items-center justify-content-center">
                    <span className="font-25px font-weight-bold">{hours}</span>
                    <span className="font-16px">Hrs</span>
                </div>
                <div className="app-flex-column align-items-center justify-content-center">
                    <span className="font-25px font-weight-bold">{minutes}</span>
                    <span className="font-16px">Min</span>
                </div>
                <div className="app-flex-column align-items-center justify-content-center">
                    <span className="font-25px font-weight-bold">{seconds}</span>
                    <span className="font-16px">Secs</span>
                </div>    
            </FlexRow>;
        }
    };

    return (

        <Countdown
            date={ endTime ? new Date(endTime) : Date.now() + 2000 }
            renderer={renderer}
        />

    );

}
 
export default CounterTimer;