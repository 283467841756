import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const StepperTabs = (props) => {

    const { tabs, adjustment, getActive, activeTab } = props;

    const num = adjustment ? 70 : 100;

    const [value, setValue] = React.useState(0);

    useEffect(() => {
      if(activeTab){
        setValue(activeTab);
      } else {
        setValue(value => value);
      }
    }, [activeTab]);

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            minWidth: 0,
            width: '100%'
          },
          selectTab: {
              fontWeight: 600,
              minWidth: num / tabs?.length+'%',
              borderBottom: '1px solid gray',
              fontSize: '16px',
              [theme.breakpoints.down("md")]: {
                fontSize: '13px',
              },
              [theme.breakpoints.down("sm")]: {
                fontSize: '11px',
              },
              [theme.breakpoints.down("xs")]: {
                fontSize: '11px',
              }
          }
    }));

    const classes = useStyles();
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
        getActive(newValue);
    };

    return(

        <Tabs
            className={classes.root} value={value}
            indicatorColor="primary" textColor="black"
            onChange={handleChange}
            aria-label="disabled tabs example"
            centered
        >
            {
                tabs?.map((tab, i) => (
                    <Tab label={tab?.label} key={i} className={classes.selectTab} />
                ))
            }
      </Tabs>

    );

}
 
export default StepperTabs;