import React from 'react';
import { DropdownButton, Dropdown, SplitButton, ButtonGroup } from 'react-bootstrap';

const CustomDropdown = (props) => {
    
    const { className, chooseOption, arr, title, id, height, paddingTop } = props;

    return(

        <DropdownButton 
            className={`px-0 m-0 ${className}`} style={{ height: height ? height : '50px', paddingTop: paddingTop ? paddingTop : 6 }}
            menuAlign={{ lg: 'left' }}
            title={title ? title : 'Choose'}
            id={ id ? id : "dropdown-item-button" }
            >
                <div className="px-0 py-2 m-0 w-100">
                    
                    {
                        arr?.length ? arr.map((item, index) => (
                            <Dropdown.Item key={index}
                                className="py-1 border-0 px-3 overflow-hidden" 
                                onClick={() => chooseOption(item)}
                                >
                                    {item?.value}
                            </Dropdown.Item>
                        )) : null
                    }
    
                </div>
        </DropdownButton>

    );

}

export const SimpleDropdownMenu = ({ menu, selected, chooseOption, className, title, minWidth }) => {

    if(menu?.length){
        return [DropdownButton].map((DropdownType, idx) => (
            <DropdownType
              as={ButtonGroup}
              key={idx}
              id={`dropdown-button-drop-${idx}`}
              size="md" className={`font-clashRegular font-25px dropdown-button-drop d-inline ${className} ${ minWidth ? 'menu-width' : '' }`}
              variant="inherit"
              title={selected ? selected?.value : title ? title : "Choose"}
            >
                { menu.map((item, i) => (
                    <Dropdown.Item className="font-clashRegular py-2" eventKey={item?.id} key={i} onClick={() => chooseOption(item)}>
                        { item?.logo && <img alt={ item?.value } width={`${item?.width}px`} className='mr-2' src={item?.logo} /> }
                        { item?.value }
                    </Dropdown.Item>                    
                )) }
            </DropdownType>
          ))
    } 
    
    else return null;

};
 
export default CustomDropdown;