import {
    getToday,
    SelectedDay,
    convertToWei,
    convertToEther,
  } from "./dateValid";
import { DARK_THEME, LIGHT_THEME } from "./themes";
import { Images } from 'Assets/Images';

export const convertDate = (timestamp) => {
    return new Date(timestamp).toLocaleDateString();
}

export const comaSeparateNames = (arr) => {
    return arr.length ? arr.map((name, i) => { 
            return name + (i < arr?.length - 1 ? ' ,' : '') 
        })
        : [];
};

export const customizeFileType = (fullType) => {
  return fullType?.split('/')[0]+'/'+fullType?.split('/')[1]?.toUpperCase();
};

export const validateEmail = (value) => {
    const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    return pattern.test(value);
};

export const toFixed = (x) => {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split('e-')[1]);
    if (e) {
        x *= Math.pow(10,e-1);
        x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
        e -= 20;
        x /= Math.pow(10,e);
        x += (new Array(e+1)).join('0');
    }
  }

  return x;
}

export const truncateString = (val, length) => {
  if(val?.length > length){
    return val?.substr(0, length) + ' ...';
  } else {
    return val?.substr(0, 10) + ' ...';
  }
};

export const matchFileArtwork = (fileType, symbol) => {
    if(fileType === "video" && symbol === "video"){
      return false;
    }
    else if((fileType === "image" && symbol === "art") || (fileType === "image" && symbol === "gif")){
      return false;
    }
    else if(fileType === "audio" && symbol === "music"){
      return false;
    } 
    else {
      return true;
    }
}

export const getContrast = (hexColor) => {
    let yiq;

    if(hexColor){
        hexColor = hexColor.replace('#', '');

        const r = parseInt(hexColor.substr(0, 2), 16);

        const g = parseInt(hexColor.substr(2, 2), 16);

        const b = parseInt(hexColor.substr(4, 2), 16);

         yiq = (r * 299 + g * 587 + b * 114) / 1000;
    }

    return yiq >= 129 ? '#000000' : '#D7D9E2';
};

export const renderDropdownData = (arr, keyTitle, keySrc) => {
    return arr?.length ? arr?.map((item) => {
        return {
            title: item[keyTitle],
            src: item[keySrc]
        }
    }) : [];
};

export const goToSection = (id) => {
  if(id){
    const element = document.getElementById(id);
    if(element){
      element.scrollIntoView();      
    }
  }
}

// { behavior: 'smooth' }

export const converPriceIntoText = (value) => {
  if(value>=1000000)
  {
      value=(value/1000000)+"M"
  }
  else if(value>=1000)
  {
      value=(value/1000)+"K";
  }
  
  return value;
}

export const getHistoryDate = (createdAt) => {
  const date = createdAt?.substring(0,10);
  return date?.substring(8,10) + '-' + date?.substring(5,7) + '-' + date?.substring(0,4);
};

export const getRandom = () => {
  return Math.floor(Math.random() * 10);
}

export const CheckDate = (endDate) => {
    const todayMintues = new Date().getMinutes();
    const todayHours = new Date().getHours();
    const endHours = new Date(endDate).getHours();
    const endMinutes = new Date(endDate).getMinutes();
    const checkDay = SelectedDay(endDate);
    const today = getToday();

    if (checkDay > today) {
      return true;
    } else if (checkDay == today) {
      if (endHours > todayHours) {
        return true;
      } else if (endHours == todayHours) {
        if (endMinutes > todayMintues) {
          return true;
        } else {
          alert(
            "You Can't Select the Same Time as Current Time",
          );
          return false;
        }
      } else if (endHours < todayHours) {
        alert(
          "Your Selected Time is Smaller Your Current Time"
        );
        return false;
      } else {
        alert(
          "You Can't Select the Same Time & Date as Current Date"
        );
        return false;
      }
    } else {
      alert(
        "You Selected is Date is Smaller for the Current Date!!"
      );
      return false;
    }
  };

export const getTheme = (theme) => {
  if(theme === 'dark') return DARK_THEME;
  if(theme === 'light') return LIGHT_THEME; 
}

export const isMobileDevice = () => {
  return 'ontouchstart' in window || 'onmsgesturechange' in window;
}

export const getETHOrMatic = (className, isMeta, style, price, usd) => {
  const usdConvert = isMeta ? usd?.eth : usd?.matic;

  const p = (price*usdConvert).toFixed(2);

  return <div className={`${className} align-items-center justify-content-center`} style={style}>
    <img alt="eth" 
      className='mr-2' src={!isMeta ? Images.polygon : Images.ethLogo} 
      width={ !isMeta ? "19px" : "14px" } 
    />
    <span className="mr-2">{ !isMeta ? 'MATIC' : 'ETH' }</span>
    <span>
      ${ commaSeparatedPrice(p) }
    </span>
  </div>
};

export const commaSeparatedPrice = (price) => {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}