import React from 'react';
import { MyLoader } from 'components/ContentLoader/ContentLoader';
import { SpinnerLoader } from 'components/Spinner/Spinner';

const LoadData = (props) => {
    
    const { loading, msg, arr, children, className, spinner, spinnerClassName } = props;

    if(loading) {
        return <div className="w-100 text-center py-1">
            <MyLoader />
        </div>
    }
    else if(spinner) {
        return <div className={`text-center py-1 ${spinnerClassName ? spinnerClassName : 'w-100'}`}>
            <SpinnerLoader size="md" />
        </div>
    }
    else {
        return arr?.length === 0 ? <div className={`w-100 text-center py-1 font-15px ${className}`}>
            { msg }
        </div> : children;
    }

}
 
export default LoadData;