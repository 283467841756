import React, { useState, useEffect } from 'react';
import './GlobalSearch.css';

import { Row, Col } from 'react-bootstrap';
import MenuItem from 'components/MenuItem/MenuItem';
import LoadData from "components/LoadData/LoadData";
import { FiSearch } from 'react-icons/fi';
import { handleSearch, getArtworkByGenre } from 'services/general.service';
import { SEARCH_FILTERS } from 'shared/enums';
import Wrapper from 'hoc/Layout/Wrapper/Wrapper';
import DocumentTitle from 'components/DocumentTitle/DocumentTitle';
import ListItem from 'components/ListItem/ListItem';
import { Routes } from 'Routes/Routes';
import { useHistory } from 'react-router';
import { globalSearchFilters } from 'Assets/Data';
import CheckFilter from 'components/CheckFilter/CheckFilter';
import CombineListItem from 'components/CombineListItem/CombineListItem';
import { goToSection } from 'utilities/CommonMethods';
import Pagination from 'components/Pagination/Pagination';
import FlexRow from 'components/FlexRow/FlexRow';
import { SimpleDropdownMenu } from 'components/Dropdown/Dropdown';
import { GENERES } from 'Assets/Genres';

const GlobalSearch = (props) => {
    
    const { currentTheme: { theme, colors } } = props;

    const history = useHistory();
    const [selectedFilter, setSelectedFilter] = useState(SEARCH_FILTERS.ARTWORKS);
    const [searchQuery, setSearchQuery] = useState("");
    const [activeId, setActiveId] = useState(1);
    const [page, setPage] = useState({ page: 0, perPage: 8 });
    const [count, setCount] = useState(0);
    const [usd, setUsd] = useState({ bitcoin: 0, eth: 0, matic: 0  });
    const [loading, setLoading] = useState(false);
    const [genre, setGenre] = useState(undefined);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        setGenre(undefined);
        goToSection('searchpage');
        enterSearch();
    }, [selectedFilter, page]);

    const enterSearch = () => {

        if(activeId > 0){
            let params = {};

            if(searchQuery){
                params["keyword"] = searchQuery;
            }
        
            if (selectedFilter) {
                params["filter"] = selectedFilter;
            }

            setLoading(true);
            handleSearch(params, page).then((res) => {
                
                console.log('res?.data -> ', res?.data);

                if (selectedFilter === SEARCH_FILTERS.ARTWORKS){
                    setFilteredData(res?.data?.data?.artwork);
                } else if (selectedFilter === SEARCH_FILTERS.VIDEOS || selectedFilter === SEARCH_FILTERS.AUDIO){
                    setFilteredData(res?.data?.data?.artwork);
                } else if (selectedFilter === SEARCH_FILTERS.COLLECTIONS){
                    setFilteredData(res?.data?.data?.collections);
                } else if (selectedFilter === SEARCH_FILTERS.USERS){
                    setFilteredData(res?.data?.data?.users);
                } else {
                    setFilteredData(res?.data?.data);
                }

                setCount(res?.data?.count);
                setLoading(false);
            }).catch((err) => {
                setFilteredData([]);
                setLoading(false);
            });
        } else {
            getArtworksByGenre(searchQuery);
        }
        
    };

    const setSearchObj = (index) => {
        const fil = (index <= 2) ? SEARCH_FILTERS.ARTWORKS : index === 3 ? SEARCH_FILTERS.VIDEOS : SEARCH_FILTERS.AUDIO ;

        setActiveId(index);
        setSelectedFilter(fil);
        setPage({ ...page, page: 0 });
    }

    const getArtworksByGenre = (value) => {
        setLoading(true);

        const obj = { genre: value, page: page.page, perPage: page.perPage };

        if(searchQuery){
            obj['keyword'] = searchQuery;
        }

        setActiveId(0);

        getArtworkByGenre(obj).then((res) => {
            setFilteredData(res.data?.artworks);
            setCount(res?.count);
            setLoading(false);
        }).catch((err) => {
            setFilteredData([]);
            setLoading(false);
        });
    };

    const textColor = theme === 'dark' ? 'text-white' : 'text-black';

    return(

        <DocumentTitle title="Search">

            <Wrapper colors={colors} id="searchpage" setUsd={(usd) => setUsd(usd)}>

                <Row className="pt-5 mt-5 padding-section" 
                style={{ background: theme === 'dark' ? colors?.darkBlack : colors?.white }}>
                    
                    <Col className="pt-5 pb-2 px-4" xs={12} sm={12} md={12} lg={12} xl={12}>
                        
                        <div className={`mx-auto search-bar position-relative mb-5`} 
                            style={{ background: theme === 'dark' ? colors?.lightWhite : colors?.white }}
                        >
                            <input
                                value={searchQuery} className="search-input"
                                placeholder="Search Here "
                                onKeyPress={(e) => e.key === "Enter" && enterSearch(e)}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <FiSearch className='search-bar-icon' onClick={(e) => enterSearch(e)} />
                        </div>

                        <h6 className={`d-block mb-4 font-23px ${ textColor }`}>Sort by:</h6>

                        <FlexRow className="align-items-center justify-content-start mb-3">
                            <CheckFilter 
                                arr={globalSearchFilters} 
                                onClick={(item) => setSearchObj(item?.id)} 
                                className={`justify-content-start w-auto text-capitalize mb-2 ${ textColor }`} 
                                activeId={activeId}
                            />
                            <SimpleDropdownMenu chooseOption={(obj) => {
                                setGenre(obj);
                                getArtworksByGenre(obj?.value);
                            }} 
                            menu={GENERES?.map((g, i) => { return { id: i, value: g } })} title="Choose Genre"
                            selected={genre} className={`font-23px mx-1 mb-2 ${textColor}`} minWidth />
                        </FlexRow>
                        
                        <Row className="mx-1">
                            <LoadData loading={loading} arr={filteredData} msg={`No data found.`} className={textColor}>
                                { (selectedFilter === SEARCH_FILTERS.USERS) ? filteredData?.map((item, i) => (
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} key={i} className="">
                                            <ListItem 
                                                title={`@${item?.userName}`} showBorder adjustment clickState={true} showAvatar={true}
                                                className={`my-3 cursor-pointer-sort ${theme === 'dark' ? 'text-white' : 'text-black'}`} 
                                                colorHeading="text-lightPurple" text={item?.email} key={i} type="image"
                                                onClick={(e) => history.push(`/${Routes.profile}/${item?.id}`)}
                                                showAvatarSrc={item?.profilePic}
                                            />
                                        </Col>
                                    )) 
                                    : filteredData.map((menu, i) => (
                                        <Col xs={12} sm={12} md={12} lg={4} xl={3} key={i} className="mb-3">
                                            <CombineListItem
                                                artwork_url={menu?.artwork_url} artwork_type={menu?.artwork_type} artworkName={menu?.name} 
                                                openForSale={menu?.openForSale} isAuctionOpen={menu?.isAuctionOpen} artName={menu?.name} 
                                                artPrice={menu?.price} endTime={menu?.auction?.endTime} numberOfLikes={menu?.numberOfLikes} 
                                                owner={menu?.owner} creater={menu?.creater} item={menu} usd={usd}
                                                history={history} artId={menu?.id} isDark={theme === 'dark'} 
                                            />
                                        </Col>
                                    )) }
                            </LoadData>
                        </Row>

                    </Col>

                    {
                        count > 8 &&
                        <Pagination textColor={textColor} isDark={theme === 'dark'} 
                            nextClick={() => setPage({ ...page, page: page.page + 1 })} total={count}
                            backClick={() => setPage({ ...page, page: page.page - 1 })} perPage={8} 
                            activeIndex={page.page} changePage={(i) => setPage({ ...page, page: i })} 
                        />
                    }

                </Row>

            </Wrapper>

        </DocumentTitle>    

    );

}
 
export default GlobalSearch;