import React, { useState, useEffect } from 'react';

import FlexColumn from 'components/FlexColumn/FlexColumn';
import { Row, Col } from 'react-bootstrap';
import CombineListItem from 'components/CombineListItem/CombineListItem';
import { useParams, useHistory } from 'react-router';
import { getCollectionDetail } from 'services';
import DocumentTitle from 'components/DocumentTitle/DocumentTitle';
import { getOpenArtWorkList } from 'services/artService';
import LoadData from 'components/LoadData/LoadData';
import { useSelector } from 'react-redux';
import RenderPage from 'components/RenderPage/RenderPage';
import { goToSection } from 'utilities/CommonMethods';

const CollectionDetail = (props) => {

    const { currentTheme: { theme } } = props;

    const { id } = useParams();
    const history = useHistory();
    const state = useSelector(state => state);

    const [collection, setCollection] = useState(undefined);
    const [artworks, setArtworks] = useState([]);
    const [title, setTitle] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        goToSection('collectiondetailpage');
        getACollection();
        getCollectionArtworks();
    }, []);

    const getACollection = () => {
        getCollectionDetail(id).then((res) => {
            setCollection(res.data.data);
            setTitle(res.data.data?.name);
            setLoading(false);
        }).catch((e) => {
            setTitle('');
            setCollection(undefined);
            setLoading(false);
        });
    };

    const getCollectionArtworks = () => {
        getOpenArtWorkList(id).then((res) => {
            setArtworks(res.data.data);
        }).catch((err) => {
            setArtworks([]);
        });
    };

    const textColor = theme === 'dark' ? 'text-white' : 'text-black';

    return(

        <DocumentTitle title={`${title} Detail`} condition={loading}>        

            <RenderPage title={`Collection (${title}) NFTs`} className={theme === 'dark' ? 'bg-darkBlack text-white' : 'bg-white text-black'} id="collectiondetailpage">
                
                <FlexColumn className="padding-section m-0 pb-5">

                    <Row>
                        <LoadData loading={loading} msg="No Artworks exists against this collection" arr={artworks}>
                            { artworks?.map((item, i) => (
                                <Col key={i} xs={12} sm={12} md={6} lg={4} xl={3} key={i}>
                                    <CombineListItem 
                                        artId={item?._id} item={item}
                                        artwork_url={item?.artwork_url} artwork_type={item?.artwork_type} artworkName={item?.name} 
                                        openForSale={item?.openForSale} isAuctionOpen={item?.isAuctionOpen} artName={item?.name} 
                                        artPrice={item?.price} endTime={item?.auction?.endTime} numberOfLikes={item?.numberOfLikes} 
                                        owner={item?.owner} creater={item?.creater}
                                        history={history} isDark={theme === 'dark'} 
                                    />
                                </Col>
                            )) }
                        </LoadData>
                    </Row>
                </FlexColumn>
                
            </RenderPage>

        </DocumentTitle>

    );

}
 
export default CollectionDetail;