import { Images } from 'Assets/Images';
import React from 'react';

const ArtworkIcon = ({ src, type, height, width, name, className }) => {
    
    const index = (type?.toLowerCase() === "image" || type?.toLowerCase() === "art") ? 0 : 
    (type?.toLowerCase() === "video") ? 1 : (type?.toLowerCase() === "gif") ? 2 : 3;

    return (
        <img alt={name} src={src ? src : Images.icons[index]} width={width} height={height} className={className} />
    );

}
 
export default ArtworkIcon;