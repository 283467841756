import React, { useState } from "react";
import { Accordion, Card, useAccordionToggle } from "react-bootstrap";
import { dataListsUpdated } from './data';
import { FaPlus, FaMinus } from 'react-icons/fa';

function CustomToggle({ children, eventKey, handleClick }) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    handleClick();
  });

  return (
    <div className="py-3 px-4 app-flex-row align-items-center justify-content-between" type="button" onClick={decoratedOnClick}>
      {children}
    </div>
  );
}

const CustomAccordian = ({ color }) => {
  const [activeKey, setActiveKey] = useState(0);

  return (
    <Accordion defaultActiveKey={activeKey} activeKey={activeKey}>
    {dataListsUpdated.map((item, i) => (
        <Card key={item?.id} className="border-0 bg-transparent">
        <CustomToggle
            as={Card.Header}
            eventKey={item?.id}
            handleClick={() => {
            if (activeKey === item?.id) {
                setActiveKey(null);
            } else {
                setActiveKey(item?.id);
            }
            }}
        >
            <span>{item.title}</span>
            <span className="ml-auto font-15px">
                {activeKey === item?.id ? <FaMinus /> : <FaPlus />}
            </span>
        </CustomToggle>
        <Accordion.Collapse eventKey={item?.id}>
          <Card.Body className="mx-0 mt-0 mb-2 py-0 px-4 border-0">
            { item.text ? item.text : item.answers?.map((txt, i) => (
              <p key={i} className="ml-3 mb-2">
                { txt }
              </p>
            )) }
          </Card.Body>
        </Accordion.Collapse>

        <hr className="mx-4 w-100 rounded-pill my-1" style={{ background: color }} />

        {/* <div className="mx-4 w-100 rounded-pill mt-2" style={{ height: '1px', background: color }}></div> */}

        </Card>
    ))}
    </Accordion>
  );
};

export default CustomAccordian;