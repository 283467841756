import React, { useState, useEffect } from 'react';

import FlexRow from 'components/FlexRow/FlexRow';
import { Span } from 'components/Span/Span';
import { Routes } from 'Routes/Routes';
import Wrapper from 'hoc/Layout/Wrapper/Wrapper';
import DocumentTitle from 'components/DocumentTitle/DocumentTitle';
import { Row } from 'react-bootstrap';
import ArtworkOnAuction from './ArtworkOnAuction';
import FixArtwork from './FixArtwork';
import { getSingleArtService, increaseViewCountService, artHistoryService } from 'services/artService';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Redirect, useParams } from 'react-router-dom';
import { getRequiredCoinRates } from 'services/general.service';
import { MyLoader } from 'components/ContentLoader/ContentLoader';
import { goToSection } from 'utilities/CommonMethods';

const MainOfArtworks = (props) => {

    const state = useSelector(state => state);
    const textColor = state?.theme === 'dark' ? 'text-white bg-darkBlack' : 'text-black bg-white';
    const dispatch = useDispatch();
    const history = useHistory();

    const { id } = useParams();

    const [ random, setRandom ] = useState(false);
    const [historyArr, setHistoryArr] = useState([]);
    const [page, setPage] = useState({ page: 0, limit: 10 });
    const [usd, setUsd] = useState({ bitcoin: 0, eth: 0, matic: 0  });
    const [currentArtwork, setCurrentArtwork] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [contractSaleId, setContractSaleId] = useState('');

    useEffect(() => {
        goToSection('detail-wrapper');

        if(id){
            loadArtwork(id);
        }
    }, [id, random]);

    const loadArtwork = (id) => {
        increaseViewCountService(id).then((res) => {
            getSingleArtService(id)?.then((res1) => {
                setContractSaleId(res1?.data?.data?.sale?.contractSaleId);
                setData(res1.data.data);
            }).catch((err) => {
                setData(undefined);
            });
        }).catch((err) => {
            setData(undefined);
        });

        page['artwork'] = id;

        artHistoryService(page, (res) => {
            setHistoryArr(res?.data?.data);
        });

        getRequiredCoinRates((res) => {
            setUsd(res.data);
        });
    };

    const setData = (data) => {
        setCurrentArtwork(data);
        setLoading(false);
    };


    if(id){
        if(loading){
            return(

                <DocumentTitle title="Artwork" condition={currentArtwork}>
                    <Wrapper colors={props.colors} id="detail-wrapper">
                        <Row className="pt-5 padding-section mt-5">
                            <FlexRow className="py-3 w-full align-items-center justify-content-center px-5">
                                <MyLoader />
                            </FlexRow>
                        </Row>
                    </Wrapper>
                </DocumentTitle>
    
            );
        }

        if(!currentArtwork){
            return(

                <DocumentTitle title="Artwork" condition={currentArtwork}>
                    <Wrapper colors={props.colors} id="detail-wrapper" setUsd={(usd) => setUsd(usd)}>
                        <Row className={`pt-5 padding-section mt-5 ${textColor}`}>
                            <FlexRow className="py-3 w-full align-items-center justify-content-start">
                                <Span className="font-16px pr-2 pl-4">
                                    { currentArtwork === undefined ? 'Something went wrong. Check your connection' :
                                    'NFT unable to load, kindly refresh the page' }
                                </Span>
                            </FlexRow>
                        </Row>
                    </Wrapper>
                </DocumentTitle>
    
            );
        }

        if(currentArtwork){
            return (<div id="detail-wrapper">
                
                { 
                    currentArtwork?.isAuctionOpen ? 
                        <ArtworkOnAuction 
                            currentArtwork={currentArtwork} state={state} history={history} colors={props.colors}
                            dispatch={dispatch} usd={usd} historyArr={historyArr} urlId={id} textColor={textColor} isDark={state?.theme === 'dark'} 
                            random={random} refreshState={() => setRandom(!random)}
                        /> 
                        : 
                        <FixArtwork 
                            currentArtwork={currentArtwork} state={state} history={history} urlId={id} textColor={textColor} colors={props.colors}
                            dispatch={dispatch} usd={usd} historyArr={historyArr} contractSaleId={contractSaleId} isDark={state?.theme === 'dark'}
                            random={random} refreshState={() => setRandom(!random)}
                        />
                }
            </div>);
        }
    }

    else return <Redirect exact={true} to={Routes.main} />

}

export default MainOfArtworks;