import React, { useState, useEffect } from 'react';
import FlexColumn from 'components/FlexColumn/FlexColumn';
import ListItem from 'components/ListItem/ListItem';
import LoadData from 'components/LoadData/LoadData';
import { getAllNotification } from 'services/general.service';

const Notifications = () => {
    
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [page, setPage] = useState({ pageDet: 0, perPage: 15 });

    useEffect(() => {
        getNotifications();
    }, []);

    useEffect(() => {
        getNotifications();
    }, []);

    const getNotifications = () => {
        setLoading(true);

        getAllNotification(undefined).then((res) => {
            setNotifications(res?.data?.data);
            setLoading(false);
        }).catch((err) => {
            setNotifications([]);
            setLoading(false);
        });
    };

    return (

        <FlexColumn className="w-95 mx-auto">

            <FlexColumn className="mx-auto notifications mt-4" style={{ width: '90%' }}>
                <LoadData loading={loading} arr={notifications} msg={`No notifications found.`}>
                    { notifications?.map((item, i) => (
                        <ListItem 
                            title=""
                            className="py-3 mb-3 pr-1" key={i}
                            colorHeading="text-black" 
                            heading={item?.message} 
                            showBorder showAvatar={false} rightText={item?.createdAt?.substring(0, 10)}
                        />
                    )) }
                </LoadData>
            </FlexColumn>

        </FlexColumn>

    );

}
 
export default Notifications;