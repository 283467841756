import { getWeb3 } from "utilities/getWeb3";
import {
  MINT_ABI, MINT_FEE_ABI,
  AUCTION_ABI
} from "shared/abis";
import {
  ETH_CONTRACTS,
  POLYGON_CONTRACTS,
  networkChain,
} from "utilities/AppEndpoint";
import Web3 from "web3";

export const connectionCoinbaseWallet = async (key) => {
  if (typeof window.ethereum !== "undefined") {
    let provider = window.ethereum;

    if (window.ethereum.providers?.length) {
      window.ethereum.providers.forEach(async (p) => {
        if (p.isCoinbaseWallet) {
          provider = p;
        }
      });

      const address = await provider.request({
        method: "eth_requestAccounts",
        params: [],
      });
    
      return address[0];
    }
    else {
      if(provider.isCoinbaseWallet){
        const address = await provider.request({
          method: "eth_requestAccounts",
          params: [],
        });
      
        return address[0];
      }
      else {
        return undefined;
      }
    }

  } else {
    return undefined;
  }
};

export const connectionMetamaskWallet = async (key) => {
  if (typeof window.ethereum !== "undefined") {
    let provider = window.ethereum;

    if (window.ethereum.providers?.length) {
      window.ethereum.providers.forEach(async (p) => {
        if (p.isMetaMask) {
          provider = p;
        }
      });

      const address = await provider.request({
        method: "eth_requestAccounts",
        params: [],
      });
    
      return address[0];
    }
    else {
      if(provider.isMetaMask){
        const address = await provider.request({
          method: "eth_requestAccounts",
          params: [],
        });
      
        return address[0];
      }
      else {
        return undefined;
      }
    }
    
  } else {
    return undefined;
  }
};

export const getGasLimit = async () => {
  let web3Instance = await new Web3(window.ethereum);
  const block = await web3Instance.eth.getBlock("latest");
  const blockGasLimit = Number(block.gasLimit);
  const blockLength = block.transactions.length;
  const limit = blockGasLimit / blockLength;
  return parseInt(limit);
};

export const checkAccounts = async () => {
  if (window.ethereum) {
    const accounts = await window.ethereum.request({ method: "eth_accounts" });

    if (accounts?.length) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const getAccounts = async () => {
  if (window.ethereum) {
    const accounts = await window.ethereum.request({ method: "eth_accounts" });
    return accounts;
  } else {
    return [];
  }
};

export const getNetworkId = async () => {
  if (window.ethereum) {
    let web3Instance = await new Web3(window.ethereum);
    const id = await web3Instance.eth.net.getId();
    console.log("Network id === ", id);

    if (id === 1) {
      return true;
    } else {
      return false;
    }
  }
};

const getWeb3Provider = async (activeWallet) => {
  let provider = window.ethereum;
  if (activeWallet === "metamask") {
    if (window.ethereum.providers?.length) {
      window.ethereum.providers.forEach(async (p) => {
        if (p.isMetaMask) {
          provider = p;
        }
      });
    }
  } else if (activeWallet === "coinbase") {
    if (window.ethereum.providers?.length) {
      window.ethereum.providers.forEach(async (p) => {
        if (p.isCoinbaseWallet) {
          provider = p;
        }
      });
    }
  }
  return provider;
};

const returnRejectedPromise = () => {
  return new Promise(async (resolve, reject) => {
    reject({
      status: false,
      data: undefined,
    });
  });
}

const mintCollection = (parm) => {
  return new Promise(async (resolve, reject) => {
    const findId = await getNetworkId();
    if (window.ethereum && findId) {
      let obj = await getWeb3(MINT_ABI);
      if (obj?.mintContract) {
        let acc = await obj?.web3_instance.eth.getAccounts();

        await obj?.mintContract.methods
          .newCollectionMint(
            parm?.collectName,
            parm?.artName,
            parm?.artUrl,
            parm.tokenUrl
          )
          .send({ from: acc[0] })
          .then((res) => {
            resolve({
              status: true,
            });
          })
          .catch((err) => {
            reject({
              status: false,
            });
          });
      }
    } else {
      reject({
        status: false,
      });
    }
  });
};

const mintFee = async (params) => {

  const provider = await getWeb3Provider(params.activeWallet);

  let feeAddress = params.isMeta ? ETH_CONTRACTS.SIGNER_ADDRESS : POLYGON_CONTRACTS.SIGNER_ADDRESS;

  let requiredNetworkedId = params.isMeta ? networkChain.eth : networkChain.poly;
  const web3Instance = new Web3(provider);
  const netWorkId = await web3Instance.eth.net.getId();

  if (netWorkId === requiredNetworkedId) {
    return new Promise(async (resolve, reject) => {
      if (window.ethereum) {
        // const web3 = new Web3('http://localhost:3000');
        // const batch = new web3.eth.BatchRequest();

        let acc = await web3Instance.eth.getAccounts();
        // const contractFee = new web3Instance.eth.Contract(MINT_FEE_ABI, feeAddress);
        // contractFee.methods.mintFee(params.mintPrice,params.txIdentifier,params.priceSignature).send({ from: acc[0], value : params.mintPrice })
        
        // let transactionObj = { from: acc[0], to: ETH_CONTRACTS.ADMIN_ADDRESS, value: params.mintPrice };

        // if(!params.isMeta){
        //   transactionObj['gasPrice'] = 5000000000000;
        //   transactionObj['gasLimit'] = 200000;
        // }

        web3Instance.eth.sendTransaction({ from: acc[0], to: ETH_CONTRACTS.ADMIN_ADDRESS, value: params.mintPrice }).then((res1) => {  
          resolve({
            status: true,
            data: res1
          });
        })
        .catch((err) => {
          reject({
            status: false,
            data: undefined
          });
        });        

      } else {
        reject({
          status: false,
          data: undefined,
        });
      }
    });
  } else {
    document.getElementById('error-message').style.zIndex = 9999;
    document.getElementById('error-message').style.visibility = 'visible';
    return returnRejectedPromise();
  }
};

const mintArtwork = async (params) => {

  const provider = await getWeb3Provider(params.activeWallet);

  let address = params.isMeta
    ? ETH_CONTRACTS.MINT_NFT_CONTRACT_ADDRESS
    : POLYGON_CONTRACTS.MINT_NFT_CONTRACT_ADDRESS;

  let requiredNetworkedId = params.isMeta ? networkChain.eth : networkChain.poly;
  const web3Instance = new Web3(provider);
  const netWorkId = await web3Instance.eth.net.getId();

  if (netWorkId === requiredNetworkedId) {
    return new Promise(async (resolve, reject) => {
      if (window.ethereum) {

        let acc = await web3Instance.eth.getAccounts();
        const contract = new web3Instance.eth.Contract(MINT_ABI, address);

        contract.methods.mintTo(params.address,params.ipfsUrl).send({ from: acc[0] })
        .then((res) => {  
          resolve({
            status: true,
            data: res
          });
        })
        .catch((err) => {
          reject({
            status: false,
            data: undefined
          });
        });        

      } else {
        reject({
          status: false,
          data: undefined,
        });
      }
    });
  } else {
    document.getElementById('error-message').style.zIndex = 9999;
    document.getElementById('error-message').style.visibility = 'visible';
    return returnRejectedPromise();
  }
};

const createAuction = async (params) => {
  const provider = await getWeb3Provider(params.activeWallet);

  let address = params.isMeta
    ? ETH_CONTRACTS.AUCTION_CONTRACT_ADDRESS
    : POLYGON_CONTRACTS.AUCTION_CONTRACT_ADDRESS;

  let requiredNetworkdId = params.isMeta ? networkChain.eth : networkChain.poly;
  const web3Instance = new Web3(provider);
  const netWorkId = await web3Instance.eth.net.getId();

  if (netWorkId === requiredNetworkdId) {
    return new Promise(async (resolve, reject) => {
      if (window.ethereum) {
        let acc = await web3Instance.eth.getAccounts();
        let auctionContract = new web3Instance.eth.Contract(
          AUCTION_ABI,
          address
        );

        auctionContract.methods
          .NewAuction(
            params.collectionAddress,
            params.tokenId,
            params.startTime,
            params.endTime,
            params.startPrice
          )
          .send({ from: acc[0] })
          .then(() => {
            resolve({
              status: true,
            });
          })
          .catch((err) => {
            reject({
              status: false,
            });
          });
      } else {
        resolve({
          status: false,
        });
      }
    });
  } else {
    document.getElementById('error-message').style.zIndex = 9999;
    document.getElementById('error-message').style.visibility = 'visible';  
    return returnRejectedPromise();
  }
};

const placeBid = async (params) => {
  console.log(params);

  const provider = await getWeb3Provider(params.activeWallet);

  let address = params.isMeta
    ? ETH_CONTRACTS.AUCTION_CONTRACT_ADDRESS
    : POLYGON_CONTRACTS.AUCTION_CONTRACT_ADDRESS;

  let requiredNetworkdId = params.isMeta ? networkChain.eth : networkChain.poly;
  const web3Instance = new Web3(provider);
  const netWorkId = await web3Instance.eth.net.getId();

  if (netWorkId === requiredNetworkdId) {
    return new Promise(async (resolve, reject) => {
      try {
        if (window.ethereum) {
          let acc = await web3Instance.eth.getAccounts();

          let auctionContract = new web3Instance.eth.Contract(
            AUCTION_ABI,
            address
          );

          await auctionContract.methods
            .Bid(params.auctionId)
            .send({ from: acc[0], value: params.amount })
            .then((res) => {
              resolve({
                status: true,
              });
            })
            .catch((err) => {
              reject({
                status: false,
              });
            });
        } else {
          reject({
            status: false,
          });
        }
      } catch (e) {
        reject({
          status: false,
        });
      }
    });
  } else {
    document.getElementById('error-message').style.zIndex = 9999;
    document.getElementById('error-message').style.visibility = 'visible';    
    return returnRejectedPromise();
  }
};

const claimSaleFromContract = (params) => {
  return new Promise(async (resolve, reject) => {
    const findId = await getNetworkId();
    if (window.ethereum && findId) {
      let web3Instance = await new Web3(window.ethereum);
      let acc = await web3Instance.eth.getAccounts();
      let auctionContract = await new web3Instance.eth.Contract(
        AUCTION_ABI,
        ETH_CONTRACTS.AUCTION_CONTRACT_ADDRESS
      );

      await auctionContract.methods
        .collectETH(params.auctionId)
        .send({ from: acc[0] })
        .then((res) => {
          resolve({
            status: true,
          });
        })
        .catch((err) => {
          resolve({
            status: false,
          });
        });
    } else {
      reject({
        status: false,
      });
    }
  });
};

const claimBackFromContract = (params) => {
  return new Promise(async (resolve, reject) => {
    const findId = await getNetworkId();
    if (window.ethereum && findId) {
      let web3Instance = await new Web3(window.ethereum);
      let acc = await web3Instance.eth.getAccounts();
      let auctionContract = await new web3Instance.eth.Contract(
        AUCTION_ABI,
        ETH_CONTRACTS.AUCTION_CONTRACT_ADDRESS
      );

      await auctionContract.methods
        .cancelAuction(params.auctionId)
        .send({ from: acc[0] })
        .then((res) => {
          resolve({
            status: true,
          });
        })
        .catch((err) => {
          reject({
            status: false,
          });
        });
    } else {
      reject({
        status: false,
      });
    }
  });
};

const claimNft = async (params) => {
  console.log(params);

  const provider = await getWeb3Provider(params.activeWallet);

  let address = params.isMeta
    ? ETH_CONTRACTS.AUCTION_CONTRACT_ADDRESS
    : POLYGON_CONTRACTS.AUCTION_CONTRACT_ADDRESS;

  let requiredNetworkdId = params.isMeta ? networkChain.eth : networkChain.poly;
  const web3Instance = new Web3(provider);
  const netWorkId = await web3Instance.eth.net.getId();

  if (netWorkId === requiredNetworkdId) {
    return new Promise(async (resolve, reject) => {
      try {
        if (window.ethereum) {
          let acc = await web3Instance.eth.getAccounts();

          let auctionContract = new web3Instance.eth.Contract(
            AUCTION_ABI,
            address
          );
          console.log(auctionContract);

          await auctionContract.methods
            .claimAuction(parseInt(params.auctionId))
            .send({ from: acc[0] })
            .then((res) => {
              resolve({
                status: true,
              });
            })
            .catch((err) => {
              reject({
                status: false,
              });
            });
        } else {
          reject({
            status: false,
          });
        }
      } catch (e) {
        reject({
          status: false,
        });
      }
    });
  } else {
    document.getElementById('error-message').style.zIndex = 9999;
    document.getElementById('error-message').style.visibility = 'visible';    
    return returnRejectedPromise();
  }
};

const putNftOnSale = async (params) => {
  const provider = await getWeb3Provider(params.activeWallet);

  let address = params.isMeta
    ? ETH_CONTRACTS.AUCTION_CONTRACT_ADDRESS
    : POLYGON_CONTRACTS.AUCTION_CONTRACT_ADDRESS;

  let requiredNetworkdId = params.isMeta ? networkChain.eth : networkChain.poly;
  const web3Instance = new Web3(provider);
  const netWorkId = await web3Instance.eth.net.getId();
  if (netWorkId === requiredNetworkdId) {
    return new Promise(async (resolve, reject) => {
      try {
        if (window.ethereum) {
          let acc = await web3Instance.eth.getAccounts();
          let auctionContract = new web3Instance.eth.Contract(
            AUCTION_ABI,
            address
          );

          await auctionContract.methods
            .SaleNFT(params.collectionAdress, params.tokenId, params.price)
            .send({ from: acc[0] })
            .then((res) => {
              resolve({
                status: true,
              });
            })
            .catch((err) => {
              reject({
                status: false,
              });
            });
        } else {
          reject({
            status: false,
          });
        }
      } catch (e) {
        reject({
          status: false,
        });
      }
    });
  } else {
    document.getElementById('error-message').style.zIndex = 9999;
    document.getElementById('error-message').style.visibility = 'visible';    
    return returnRejectedPromise();
  }
};

const cancelNftSale = async (params) => {
  const provider = await getWeb3Provider(params.activeWallet);

  let address = params.isMeta
    ? ETH_CONTRACTS.AUCTION_CONTRACT_ADDRESS
    : POLYGON_CONTRACTS.AUCTION_CONTRACT_ADDRESS;

  let requiredNetworkdId = params.isMeta ? networkChain.eth : networkChain.poly;
  const web3Instance = new Web3(provider);
  const netWorkId = await web3Instance.eth.net.getId();

  if (netWorkId === requiredNetworkdId) {
    return new Promise(async (resolve, reject) => {
      try {
        if (window.ethereum) {
          let acc = await web3Instance.eth.getAccounts();
          let auctionContract = new web3Instance.eth.Contract(
            AUCTION_ABI,
            address
          );

          await auctionContract.methods
            .cancelSale(params.saleId)
            .send({ from: acc[0] })
            .then((res) => {
              resolve({
                status: true,
              });
            })
            .catch((err) => {
              reject({
                status: false,
              });
            });
        } else {
          reject({
            status: false,
          });
        }
      } catch (e) {
        reject({
          status: false,
        });
      }
    });
  } else {
    document.getElementById('error-message').style.zIndex = 9999;
    document.getElementById('error-message').style.visibility = 'visible';    
    return returnRejectedPromise();
  }
};

const buyNft = async (params) => {
  console.log(
    "🚀 ~ file: contract.service.js ~ line 536 ~ buyNft ~ params",
    params
  );
  const provider = await getWeb3Provider(params.activeWallet);

  let address = params.isMeta
    ? ETH_CONTRACTS.AUCTION_CONTRACT_ADDRESS
    : POLYGON_CONTRACTS.AUCTION_CONTRACT_ADDRESS;

  let requiredNetworkdId = params.isMeta ? networkChain.eth : networkChain.poly;
  const web3Instance = new Web3(provider);
  const netWorkId = await web3Instance.eth.net.getId();

  if (netWorkId === requiredNetworkdId) {
    return new Promise(async (resolve, reject) => {
      try {
        if (window.ethereum) {
          let acc = await web3Instance.eth.getAccounts();

          let auctionContract = new web3Instance.eth.Contract(
            AUCTION_ABI,
            address
          );

          console.log(params.saleId);

          await auctionContract.methods
            .buyNFTsale(params.saleId)
            .send({
              from: acc[0],
              value: params.amount
            })
            .then((res) => {
              resolve({
                status: true,
              });
            })
            .catch((err) => {
              reject({
                status: false,
              });
            });
        } else {
          reject({
            status: false,
          });
        }
      } catch (e) {
        reject({
          status: false,
        });
      }
    });
  } else {
    document.getElementById('error-message').style.zIndex = 9999;
    document.getElementById('error-message').style.visibility = 'visible';    
    return returnRejectedPromise();
  }
};

const setRoyalty = async (royality, params) => {

  const provider = await getWeb3Provider(params.activeWallet);

  let address = params.isMeta
    ? ETH_CONTRACTS.AUCTION_CONTRACT_ADDRESS
    : POLYGON_CONTRACTS.AUCTION_CONTRACT_ADDRESS;

  let requiredNetworkdId = params.isMeta ? networkChain.eth : networkChain.poly;
  const web3Instance = new Web3(provider);
  const netWorkId = await web3Instance.eth.net.getId();

  if (netWorkId === requiredNetworkdId) {
    return new Promise(async (resolve, reject) => {
      if (window.ethereum) {
        let acc = await web3Instance.eth.getAccounts();
        const contract = new web3Instance.eth.Contract(AUCTION_ABI, address);

        contract.methods.setRoyalty(royality)
        .send({ from: acc[0] })
        .then((res) => {
          resolve({
            status: true,
          });
        })
        .catch((err) => {
          reject({
            status: false,
          });
        });

      } else {
        reject({
          status: false,
          data: undefined,
        });
      }
    });
  } else {
    document.getElementById('error-message').style.zIndex = 9999;
    document.getElementById('error-message').style.visibility = 'visible';
    return returnRejectedPromise();
  }

};

const getRoyalty = async (userAddress, params) => {

  const provider = await getWeb3Provider(params.activeWallet);

  let address = params.isMeta
    ? ETH_CONTRACTS.AUCTION_CONTRACT_ADDRESS
    : POLYGON_CONTRACTS.AUCTION_CONTRACT_ADDRESS;

  let requiredNetworkdId = params.isMeta ? networkChain.eth : networkChain.poly;
  const web3Instance = new Web3(provider);
  const netWorkId = await web3Instance.eth.net.getId();

  if (netWorkId === requiredNetworkdId) {
    return new Promise(async (resolve, reject) => {
      if (window.ethereum) {
        let acc = await web3Instance.eth.getAccounts();
        const contract = new web3Instance.eth.Contract(AUCTION_ABI, address);

        contract.methods
        .getRoyalty(userAddress)
        .send({ from: acc[0] })
        .then((res) => {
          resolve({
            status: true,
          });
        })
        .catch((err) => {
          reject({
            status: false,
          });
        });

      } else {
        reject({
          status: false,
          data: undefined,
        });
      }
    });
  } else {
    document.getElementById('error-message').style.zIndex = 9999;
    document.getElementById('error-message').style.visibility = 'visible';    
    return returnRejectedPromise();
  }

};

const cancelNftAuction = async (params) => {
  const provider = await getWeb3Provider(params.activeWallet);

  let address = params.isMeta
    ? ETH_CONTRACTS.AUCTION_CONTRACT_ADDRESS
    : POLYGON_CONTRACTS.AUCTION_CONTRACT_ADDRESS;

  let requiredNetworkdId = params.isMeta ? networkChain.eth : networkChain.poly;
  const web3Instance = new Web3(provider);
  const netWorkId = await web3Instance.eth.net.getId();

  if (netWorkId === requiredNetworkdId) {
    return new Promise(async (resolve, reject) => {
      try {
        if (window.ethereum) {
          let acc = await web3Instance.eth.getAccounts();
          let auctionContract = new web3Instance.eth.Contract(
            AUCTION_ABI,
            address
          );

          await auctionContract.methods
            .cancelAuction(params.auctionId)
            .send({ from: acc[0] })
            .then((res) => {
              resolve({
                status: true,
              });
            })
            .catch((err) => {
              reject({
                status: false,
              });
            });
        } else {
          reject({
            status: false,
          });
        }
      } catch (e) {
        reject({
          status: false,
        });
      }
    });
  } else {
    document.getElementById('error-message').style.zIndex = 9999;
    document.getElementById('error-message').style.visibility = 'visible';    
    return returnRejectedPromise();
  }
};

const updatePlatformFee = async (fee, params) => {
  const provider = await getWeb3Provider(params.activeWallet);

  let address = params.isMeta
    ? ETH_CONTRACTS.AUCTION_CONTRACT_ADDRESS
    : POLYGON_CONTRACTS.AUCTION_CONTRACT_ADDRESS;

  let requiredNetworkdId = params.isMeta ? networkChain.eth : networkChain.poly;
  const web3Instance = new Web3(provider);
  const netWorkId = await web3Instance.eth.net.getId();

  if (netWorkId === requiredNetworkdId) {
    return new Promise(async (resolve, reject) => {
      try {
        if (window.ethereum) {
          let acc = await web3Instance.eth.getAccounts();
          let auctionContract = new web3Instance.eth.Contract(
            AUCTION_ABI,
            address
          );

          await auctionContract.methods
            .setPlatformFee(fee)
            .send({ from: acc[0] })
            .then((res) => {
              resolve({
                status: true,
              });
            })
            .catch((err) => {
              reject({
                status: false,
              });
            });
        } else {
          reject({
            status: false,
          });
        }
      } catch (e) {
        reject({
          status: false,
        });
      }
    });
  } else {
    document.getElementById('error-message').style.zIndex = 9999;
    document.getElementById('error-message').style.visibility = 'visible';  
    return returnRejectedPromise();
  }
};

export {
  mintCollection,
  mintFee,
  mintArtwork,
  createAuction,
  placeBid,
  claimNft,
  claimSaleFromContract,
  claimBackFromContract,
  putNftOnSale,
  cancelNftSale,
  buyNft,
  setRoyalty,
  updatePlatformFee,
  cancelNftAuction,
  getRoyalty,
};
