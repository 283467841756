import React, { useState, useEffect, useRef } from 'react';

import { Images } from 'Assets/Images';
import LayoutWrapper from 'hoc/Layout/LayoutWrapper/LayoutWrapper';
import { Span } from 'components/Span/Span';
import { Div } from 'components/Div/Div';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Routes } from 'Routes/Routes';
import { SpinnerLoader } from 'components/Spinner/Spinner';
import { handleSignIn, handleSignUp } from './network';
import AlertPopup from 'components/AlertPopup/AlertPopup';
import CheckFilter from '../../components/CheckFilter/CheckFilter';

const SignIn = (props) => {
    
    const { handleClose, defaultBehaviour, address } = props;

    // redux setups
    const dispatch = useDispatch();
    const fieldRef = useRef(null);
    const history = useHistory();

    const [loadingDetails, setLoadingDetails] = useState(true);
    const [isSignUp, setSignUp] = useState(false);

    const [values, setValues] = useState({ username: '', role: { value: 'artist' }, address: address });
    const [err, setErr] = useState({ show: false, type: '', message: '', title: '' });

    const [accepted, setAccepted] = useState(false);

    const fields = [
        { id: 1, placeholder: 'Profile Name*', key: 'username', type: 'text' }
    ];

    useEffect(() => {
        if(address){
            
            dispatch(handleSignIn({ address }, (res) => {
                console.log('user ->>> ', res);

                if(res.status && res.data){
                    handleClose(null, false);
                    history.push(`/${Routes.profile}`);
                } else {
                    if(fieldRef.current){
                        fieldRef.current.focus();
                    }
                }

                setLoadingDetails(false);
            }));

        }
    }, [address]);

    const onChange = (e) => {
        const { name, value } = e.target;

        setValues({ ...values, [name]: value, address: address });
    }

    const loginAuth = () => {

        if(!values.username){
            setErr({ show: true, message: '', type: "error", title: "Please fill all the fields" });
        } 
        else {

            if(accepted){
                const obj = { role: values?.role?.value, address: values?.address, userName: values?.username };
                setErr({ show: false, message: '', type: "", title: "" });
                setSignUp(true);
    
                dispatch(handleSignUp(obj, (res) => {
                    const { status, data } = res;
        
                    if(status && data){
                        handleClose(0, false);
                        history.push(`/${Routes.profile}`);
                    }
        
                    if(!status && !data){
                        setErr({ show: true, message: '', type: "error", 
                        title: "Please Sign In/Up To Your MarvmentNFT Account" });
                    }
        
                    setSignUp(false);
                }));
            }
        }
    }

    return(

        <Div className={`app-flex-column w-100 position-relative`}>
            
            <LayoutWrapper src={Images.loginImg} handleClose={() => handleClose(3, true)} twoColumn={true}>
                
                {
                    loadingDetails ? <div className='d-flex flex-column align-items-center justify-content-center column-adjustment font-clashRegular'>
                        <Span className="mb-3 font-16px">Fetching User Details ...</Span>
                        <SpinnerLoader size="md" color="white" animation="grow" />
                    </div> :
                    <Div className="px-4 text-center column-adjustment">
    
                        <Span className="font-28px w-100 mb-3">
                            Enter Your Profile
                        </Span>

                        {/* <Div className="p-3 rounded bg-pinkShade3 mt-4">
                            <Span className="font-14px text-uppercase mb-1 d-block text-left">
                                Address
                            </Span>
                            <Span className="font-14px d-block text-left text-wrap break-word">
                                { address }
                            </Span>
                        </Div> */}
    
                        {
                            fields?.map((field, index) => (
                                <input type={field.type} value={values[field.key]} onChange={(e) => onChange(e)}
                                    className="w-100 auth-input-fields bg-transparent" placeholder={field.placeholder} 
                                    key={index} name={field.key} id={field.key} autoComplete="off" 
                                    ref={index === 0 ? fieldRef : null}
                                />
                            ))
                        }

                        <CheckFilter 
                            arr={[{ id: 3, value: 'I agree to the' }]} onClick={(item) => setAccepted(!accepted)} small
                            className="adjustment-start mr-1 font-14px my-3 font-gilroy" activeId={0} flag={accepted}
                        >
                            <span className="text-skyblue cursor-pointer-sort" 
                                onClick={() => window.open(`https://www.marvmentnft.com/${Routes.terms}`, '_blank')}
                            >
                                terms and conditions
                            </span>
                        </CheckFilter>

                        <button className="bg-pink border-0 rounded text-white w-100 py-2" onClick={loginAuth} disabled={!accepted}>
                            { isSignUp ? <SpinnerLoader size="sm" /> : 
                                'Sign Up' }
                        </button>
    
                    </Div>
                
                }

            </LayoutWrapper>

            <AlertPopup 
                data={{ show: err?.show, title: err.title, message: err?.message, type: err?.type }}
                handleClose={() => {
                    setErr({ ...err, show: false });
                }}
            />

        </Div>

    );

}
 
export default SignIn;