import React from 'react';

import { Images } from 'Assets/Images';
import { Row, Col } from 'react-bootstrap';
import { Routes } from 'Routes/Routes';
import BorderButton from 'components/BorderButton/BorderButton';
import FlexRow from 'components/FlexRow/FlexRow';
import CombineListItem from 'components/CombineListItem/CombineListItem';
import LoadData from 'components/LoadData/LoadData';

const Featured = (props) => {
    
    const { history, currentTheme, usd } = props;

    return(

        <div className="py-5 w-100 app-flex-column text-black padding-section" 
            style={{
                backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
                backgroundImage: `url(${Images.featuredImage})`
            }}
        >
            
            <FlexRow className="mb-3 flex-nowrap w-100 align-items-center justify-content-between text-white px-3">
                <div>
                    <h6 className="font-36px mb-3 d-block font-weight-bold font-clashRegular text-capitalize">Latest NFTs</h6>
                    <span className="font-16px d-block font-clashRegular">
                        Browse through the latest NFTs happenings of this week
                    </span>
                </div>
                <div className="text-right font-clashRegular">
                    <BorderButton title="View All" className="text-white border-white explore-btn rounded" showArrowUpRight onClick={() => history?.push(`/${Routes.activity}`)} />
                </div>
            </FlexRow>

            <Row className="w-100 p-0 m-0">
                <LoadData loading={props.loading} arr={props.arts} msg="No arts available">
                    { props.arts?.slice(0, 4)?.map((item, i) => (
                        <Col xs={12} sm={12} md={6} lg={4} xl={3} key={i} className='mb-4'>
                            <CombineListItem
                                artwork_url={item?.artwork_url} artwork_type={item?.artwork_type} artworkName={item?.name} 
                                openForSale={item?.openForSale} isAuctionOpen={item?.isAuctionOpen} artName={item?.name} 
                                artPrice={item?.price} endTime={item?.auction?.endTime} numberOfLikes={item?.numberOfLikes} 
                                owner={item?.owner} creater={item?.creater} item={item} usd={usd}
                                history={history} artId={item?._id} isDark={currentTheme?.theme === 'dark'} 
                            />
                        </Col>
                    )) }
                </LoadData>
            </Row>

        </div>

    );

}
 
export default Featured;