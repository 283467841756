export const Routes = {
    // common
    empty: '',
    main: '/',
    signIn: 'sign-in',
    signUp: 'sign-up',
    connectedWallets: 'connected-wallets',
    activity: 'explore',
    submitNfts: 'submit-nfts',
    discover: 'discover',
    support: 'support',
    profile: 'profile',
    products: 'artworks',
    album: 'album',
    detail: 'detail',
    tour: 'tour',
    auctions: 'auctions',
    dashboard: 'dashboard',
    connect: 'connect',
    collections: 'collections',
    arts: 'arts',
    createCollection: 'collection/create',
    editCollection: 'edit-collection',
    details: 'details',
    search: 'search',
    about: 'about',

    faq: 'faq',
    policy: 'privacy-policy',
    terms: 'service-terms',
}