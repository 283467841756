import React, { useState, useEffect } from 'react';
import './Activity.css';

import { Row, Col } from 'react-bootstrap';
import ListItem from 'components/ListItem/ListItem';

import DocumentTitle from 'components/DocumentTitle/DocumentTitle';
import { getActivities } from 'services/general.service';
import LoadData from "components/LoadData/LoadData";
import AlertPopup from 'components/AlertPopup/AlertPopup';
import { Routes } from 'Routes/Routes';
import Pagination from 'components/Pagination/Pagination';
import RenderPage from 'components/RenderPage/RenderPage';
import { useHistory } from 'react-router-dom';
import CombineListItem from 'components/CombineListItem/CombineListItem';
import { getAllUsers } from 'services';
import { goToSection } from 'utilities/CommonMethods';

const Activity = (props) => {
    
    const { currentTheme: { theme } } = props;

    const history = useHistory();

    const [artists, setArtists] = useState([]);
    const [activities, setActivities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingUsers, setLoadingUsers] = useState(true);
    const [usd, setUsd] = useState({ bitcoin: 0, eth: 0, matic: 0  });
    const [activityCount, setActivityCount] = useState(0);
    const [page, setPage] = useState({ page: 0, perPage: 9 });
    const [err, setErr] = useState({ show: false, type: '', message: '', title: '' });

    useEffect(() => {
        goToSection('activitypage');
        loadActivities(page);
    }, [page]);

    useEffect(() => {
        goToSection('activitypage');
        loadArtists();

        return () => {
            setActivities([]);
            setArtists([]);
        }
    }, []);

    const loadActivities = (page) => {
        setLoading(true);

        getActivities(page).then((res) => {
            setActivityCount(res?.data?.data?.count);
            setActivities(res?.data?.data?.artworks);
            setLoading(false);
        }).catch((err) => {
            setActivities([]);
            setLoading(false);
        });

    };

    const loadArtists = () => {
        setLoadingUsers(true);
        getAllUsers().then((res) => {
            setArtists(res?.data?.data?.filter(f => f?.role?.toLowerCase() === 'artist'));
            setLoadingUsers(false);
        }).catch((err) => {
            setArtists([]);
            setLoadingUsers(false);
        });
    };

    const textColor = theme === 'dark' ? 'text-white' : 'text-black';

    return (

        <DocumentTitle title="Explores">
        
            <RenderPage title="Explore" setUsd={(usd) => setUsd(usd)}
            className={theme === 'dark' ? 'bg-darkBlack text-white' : 'bg-white text-black'} id="activitypage">
            
                <Row className="pt-4 bg-transparent padding-section">

                    <Col xs={12} sm={12} md={6} lg={4} xl={3} className="margin-adjustment overflow-auto mb-3 order-1" style={{ maxHeight: 420 }}>
                        
                        <span className="font-25px d-block mb-3 font-clashRegular font-weight-bold">Latest Artist</span>

                        <LoadData loading={loadingUsers} arr={artists} msg={`No Artist found.`}>                
                            {
                                artists?.map((item, i) => (
                                    <ListItem showAvatar={true} type="image"
                                        title={`@${item?.userName}`} showBorder className="my-3 cursor-pointer-sort" adjustment
                                        colorHeading="text-lightPurple" text="" key={i} showAvatarSrc={item?.profilePic}
                                        onClick={(e) => history.push(`/${Routes.profile}/${item?.id}`)} clickState={true}
                                    />
                                ))
                            }
                        </LoadData>

                    </Col>

                    <Col xs={12} sm={12} md={6} lg={8} xl={9} className="order-2">
                        <Row>
                            <LoadData loading={loading} arr={activities} msg={`No Activities found.`}>
                                { activities?.map((item, i) => (
                                    <Col xs={12} sm={12} md={12} lg={6} xl={4} key={i} className="mb-5">
                                        <CombineListItem 
                                            artId={item?._id} item={item}
                                            artwork_url={item?.artwork_url} artwork_type={item?.artwork_type} artworkName={item?.name} 
                                            openForSale={item?.openForSale} isAuctionOpen={item?.isAuctionOpen} artName={item?.name} 
                                            artPrice={item?.price} endTime={item?.auction?.endTime} numberOfLikes={item?.numberOfLikes} 
                                            owner={item?.owner} creater={item?.creater} usd={usd}
                                            history={history} isDark={theme === 'dark'}
                                        />
                                    </Col>
                                )) }
                            </LoadData>
                        </Row>

                        {
                            activityCount > 9 &&
                            <Pagination isDark={theme === 'dark'} 
                                nextClick={() => setPage({ ...page, page: page.page + 1 })} total={activityCount}
                                backClick={() => setPage({ ...page, page: page.page - 1 })} perPage={9} textColor={textColor}
                                activeIndex={page.page} changePage={(i) => setPage({ ...page, page: i })} 
                            />
                        }

                    </Col>

                </Row>

                <AlertPopup 
                    data={{ show: err?.show, title: err.title, message: err?.message, type: err?.type }}
                    handleClose={() => {
                        setErr({ ...err, show: false });
                    }}
                />

            </RenderPage>
        
        </DocumentTitle>
    );

}
 
export default Activity;